import { useEffect, useState } from "react"
import axios from "axios"
import { url } from "../globals"
import { v4 as uuidv4 } from 'uuid';
import { makeid } from "globals/publicData";
import moment from "moment";
const useFetchListingsByID = (Ids)=>{
const [listings, setlistings] = useState([])

     useEffect(()=>{

        axios.post(`${url}/listing/getListingsByIDs` , {
            ids : Ids

        }).then((res)=>{
            console.log(res.data)
            setlistings(res.data)
        }).catch((err)=>{
            console.log(err)
            setlistings([])
        })
    },[])

    return listings

}


export default useFetchListingsByID
