
import { verticalTabs } from 'globals/publicData'

import { Link, NavLink } from 'react-router-dom'
import venuLogo from 'images/logos/venuLogo.svg'
import { useHistory, useLocation } from 'react-router-dom'
import useAuthStore from 'globals/authStore'
import { LockClosedIcon } from '@heroicons/react/solid'
import { imagesAmazonS3URL, url } from '../../globals';
import axios from "axios"
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import useCPStore from 'globals/controlPanelStore'
import { useEffect } from 'react'

export default function DesktopSideBar({user})
{
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      const history = useHistory()
      var location = useLocation();
      location = location.pathname
      const logout = ()=>{
        localStorage.removeItem('CpUser5')
        history.push('/cp')
        window.location.reload()

      }

      const loggedUser = useAuthStore(state=>state.user)
      const setShownavBar = useCPStore(state=>state.setShowNavBar)
      //console.log(loggedUser)

      useEffect(()=>{
        setShownavBar(false)
      },[])

      
    return(
        <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 max-h-[100vh] border-r border-gray-200 shadow-md rounded-sm bg-gray-50">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className=" w-auto h-64 pb-4"
                  src={venuLogo}
                  alt="Workflow"
                />
              </div>
              <nav className="mt-5 flex-1" aria-label="Sidebar">
                <div className="px-2 space-y-1">
                  {verticalTabs.map((item) => {
                    if(loggedUser.rank!=="Admin"){
                      if(loggedUser.rank === "Owner")
                      {
                        return <>{!item.admin && (
                          <NavLink
                          key={item.name}
                          to={item.href}
                          onClick={()=>{axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Sidebar Tabs',url:location, extraInfoType:'Tab Name', extraInfo:item.name }).then((res)=>{});; }}
                          activeClassName = '!bg-primary-500 !border-purple-600 !text-white'
                          d
                          className={classNames(
                            item.current
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-primary-700' : 'text-primary-700 ',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                          {item.locked && loggedUser.subscribed==false &&    <LockClosedIcon
                            className={classNames(
                              item.current ? 'text-gray-400' : 'text-gray-400',
                              'ml-1 flex-shrink-0 h-4 w-4'
                            )}                        
                            aria-hidden="true"
                          />}
                        </NavLink>
                        )
                      }
                     </>
                      }
                      if(loggedUser.rank == "Blogger"){
                        return <>{item.blogger && (
                          <NavLink
                          key={item.name}
                          to={item.href}
                          onClick={()=>{axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Sidebar Tabs',url:location, extraInfoType:'Tab Name', extraInfo:item.name }).then((res)=>{});; }}
                          activeClassName = '!bg-primary-500 !border-purple-600 !text-white'
                          d
                          className={classNames(
                            item.current
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-primary-700' : 'text-primary-700 ',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                          {item.locked &&    <LockClosedIcon
                            className={classNames(
                              item.current ? 'text-gray-400' : 'text-gray-400',
                              'ml-1 flex-shrink-0 h-4 w-4'
                            )}                        
                            aria-hidden="true"
                          />}
                        </NavLink>
                        )
                      }
                     </>
                      }
                     
                    }
                    else{
                      if(loggedUser.superAdmin === false){
                        return <>{!item.superAdmin && !item.userOnly &&  (
                          <NavLink
                          key={item.name}
                          to={item.href}
                          onClick={()=>{axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Sidebar Tabs',url:location, extraInfoType:'Tab Name', extraInfo:item.name }).then((res)=>{});; }}
                          activeClassName = '!bg-primary-500 !border-purple-600 !text-white'
                          d
                          className={classNames(
                            item.current
                              ? 'bg-gray-200 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-primary-700' : 'text-primary-700 ',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                        )
                      }
                     </>
                      }
                      else{
                      return(
                        !item.userOnly && <NavLink
                        key={item.name}
                        to={item.href}
                        onClick={()=>{axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Sidebar Tabs',url:location, extraInfoType:'Tab Name', extraInfo:item.name }).then((res)=>{});; }}

                        activeClassName = '!bg-primary-500 !border-purple-600 !text-white'
                        className={classNames(
                          item.current
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-primary-700' : 'text-primary-700 ',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>

                      )
                    }
                    }

                    })}
                </div>
                <WhatsAppWidget
                  phoneNo="201120155661"
                  position="left"
                  widgetWidth="300px"
                  widgetWidthMobile="260px"
                  autoOpen={false}
                  autoOpenTimer={5000}
                  messageBox={false}
                  messageBoxTxt="Hi"
                  iconSize="50"
                  iconColor="white"
                  iconBgColor="green"
                  headerIcon={user.imageUrl}
                  headerIconColor="white"
                  headerTxtColor="black"
                  headerBgColor="white"
                  headerTitle="Venu Support"
                  headerCaption="Online"
                  bodyBgColor="#e5e7eb"
                  chatPersonName="Venu Support"
                  chatMessage={<>Hi there 👋, Need help?</>}
                  footerBgColor="#e5e7eb"
                  btnBgColor="white"
                  btnTxtColor="black"
                  btnTxt="Start Chat"
		              />
                <div className="flex-shrink-0 flex border-y border-gray-200 p-4 mt-10">
              <div className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div className='self-start'>
                    <img className="inline-block h-9 w-9 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3 flex flex-col">
                    <p className="text-sm font-medium text-gray-700">{loggedUser.name}</p>
                    <Link to={"/cp/change-password"} className="text-xs font-medium text-gray-500 hover:text-gray-700">Change Password</Link>
                    <a onClick={(e)=>{logout()}} className="text-xs font-medium text-gray-500 hover:text-gray-700">Logout</a>
                  </div>
                </div>
              </div>
            </div>
              </nav>
              
            </div>
       
          </div>
        </div>
      </div>

    )
}