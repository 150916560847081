import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
export default function SubHeroSection2({brandImg1 , brandImg2 , brandImg3 , 
    brandImg4 , brandImg5, brandImg6, brandImg7, title , text, brandPrimaryBG, brandSecondaryBG, CTAText, mainListingID, brandText}) {
    
  return (
    <>

            <div className="pt-16 hidden  md:pb-80 pb-12 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48 overflow-hidden">
              <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
                <div className="sm:max-w-lg">
                  <h1 className="text-4xl animate-pulse font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                    
                    {title ? title : "Brand title goes here"}
                  </h1>
                  <p className="mt-4 text-xl text-gray-500">
                    
                    {text ? text : "This year, our new summer collection will shelter you from the harsh elements of a world that doesn't care if you live or die."}
                  </p>
                </div>
                <div>
                  <div className="mt-10">
                    {/* Decorative image grid */}
                    <div
                      aria-hidden="true"
                      className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                    >
                      <div className="hidden sm:block absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                        <div className="flex items-center space-x-6 lg:space-x-8">
                          <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="md:w-48 md:h-64 w-full rounded-lg md:overflow-hidden sm:opacity-0 lg:opacity-100">
                            <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

                              <img
                                src={brandImg1? brandImg1: "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-01.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                            </AnimationOnScroll>

                            </div>

                            <div className="md:w-48 md:h-64 rounded-lg overflow-hidden">
                            <AnimationOnScroll  initiallyVisible={false}  animateOnce={true} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

                              <img
                                src={brandImg2? brandImg2 : "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-02.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                            </AnimationOnScroll>

                            </div>
                          </div>

                          <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="w-48 h-64 rounded-lg overflow-hidden">
                            <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInDown">

                              <img
                                src={brandImg3 ? brandImg3:  "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-03.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                          </AnimationOnScroll>

                            </div>

                            <div className="w-48 h-64 rounded-lg overflow-hidden">
                            <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInDown">

                              <img
                                src={brandImg4? brandImg4 : "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-04.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                            </AnimationOnScroll>

                            </div>

                            <div className="w-48 h-64 rounded-lg overflow-hidden">
                            <AnimationOnScroll  initiallyVisible={false} animateOnce={true} animatePreScroll={true} duration={1}  animateIn="animate__fadeInUp">

                              <img
                                src={brandImg5? brandImg5: "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-05.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                            </AnimationOnScroll >

                            </div>
                          </div>
                          <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">

                            <div className="w-48 h-64 rounded-lg overflow-hidden">
                            <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInDown">

                              <img
                                src={ brandImg6? brandImg6 : "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-06.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                            </AnimationOnScroll>

                            </div>
                            <div className="w-48 h-64 rounded-lg overflow-hidden">
                            <AnimationOnScroll  initiallyVisible={false} animateOnce={true} animatePreScroll={true} duration={1}  animateIn="animate__fadeInRight">

                              <img
                                src={brandImg7 ? brandImg7 : "https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-07.jpg"}
                                alt=""
                                className="w-full h-64 object-center object-cover"
                              />
                            </AnimationOnScroll>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    <a
                      href={`https://www.venu.ai/listing-stay-detail?id=${mainListingID}`}
                      target={"_blank"}
                      className={`inline-block text-center ${brandPrimaryBG} ${brandText} border border-transparent rounded-md py-3 px-8 font-medium text-white hover:${brandSecondaryBG}`}
                    >
                      {CTAText? CTAText : "Call to action"}
                    </a>
                  </div>
                </div>
              </div>
            </div>


            <div className="relative bg-gray-900">
        {/* Decorative image and overlay */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src={brandImg1? brandImg1 : "https://tailwindui.com/img/ecommerce-images/home-page-01-hero-full-width.jpg"}
            alt=""
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50" />



        <div className="relative max-w-3xl mx-auto py-32 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0">
        <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

          <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl animate-pulse z-50">{title ? title : "Brand title goes here"}</h1>
          </AnimationOnScroll>
          <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInRight">

          <p className="mt-4 text-xl  text-white z-50">
          {text ? text : "This year, our new summer collection will shelter you from the harsh elements of a world that doesn't care if you live or die."}

          </p>
          </AnimationOnScroll>
          <a href={`https://www.venu.ai/listing-stay-detail?id=${mainListingID}`} target={"_blank"}
           className={`mt-8 inline-block ${brandPrimaryBG} ${brandText} border border-transparent rounded-md py-3 px-8 text-base font-medium text-gray-900 hover:${brandSecondaryBG} `}
          >
            {CTAText? CTAText : "Call to action"}
          </a>
        </div>
      </div>
            </>
  )
}
