import React from 'react'
import { useState } from "react";
import { toast } from 'react-toastify'
import SecondaySideBar from "containers/ControlPanelLayout/SecondarySideBar";
import BlockLoader from "components/BlockLoader";
import MobileSecSideBarWrapper from "./MobileSecSideBarWrapper";
import MobileSideBarWrapper from "./MobileSideBarWrapper";
import DesktopSideBar from "./DesktopSideBar";
import ControlNav from "./ControlNav";
import { user } from "globals/publicData";
import { useEffect } from "react";
import useAuthStore from "globals/authStore";
import axios from "axios";
import { LockClosedIcon } from '@heroicons/react/solid'
import venuLogo from 'images/logoAdjusted.png'  
import { url } from '../../globals';
import { useLocation,useHistory } from "react-router-dom";
import AffiliateMobileSideBar from './AffiliateMobileSideBar';
import AgencyMobileSideBar from './AgencyMobileSideBar';
import AffiliateDesktopSideBar from './AffiliateDesktopSideBar';
import AgencyDesktopSideBar from './AgencyDesktopSideBar';

const CpChangePassword = () => {
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    let history = useHistory()
    var location = useLocation();
    location = location.pathname
    const loggedUser = useAuthStore(state=>state.user)
    useEffect(() => {
      axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'onLoad',details:'CP Change Password',url:location}).then((res)=>{
      })
    }, []);

    const changePassword = (e)=>{
        e.preventDefault();
        if(newPassword ==="" || oldPassword ==="" || confirmNewPassword ===""){
          toast.warning("Please fill all fields");
        }
        if(newPassword !== confirmNewPassword)
        {
          toast.warning("New passwords do not match");
        }
        else{
          if(newPassword.length < 6){
            toast.warning("New password is too short (min 6 characters)");
          }
          else{
          axios.post(`${url}/user/changePassword` , {oldPassword : oldPassword , newPassword : newPassword, id:loggedUser.id}).then((res)=>{
            toast.success("Password changed successfully")
            history.push('/cp/home')
            window.location.reload()
          })
          .catch((err)=>{
            if(err.status!==200){
              toast.error("Wrong current password")
            }
          })
        }
        }

      }

    return (
        <>
    
          <div className="h-full flex">
            {loggedUser.rank ==='Affiliate' ?<AffiliateMobileSideBar
              setSidebarOpen={setSidebarOpen}
              sidebarOpen={sidebarOpen}
              user={user}
            />:loggedUser.rank ==='Agency' ?
            <AgencyMobileSideBar
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            user={user}
            />:
            <MobileSideBarWrapper
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
            user={user}
          />}
    
    
    

    
            {loggedUser.rank ==='Affiliate' ? <AffiliateDesktopSideBar user={user} />:
            loggedUser.rank ==='Agency' ? <AgencyDesktopSideBar user={user} />:
            <DesktopSideBar user={user} />
            }

    
            <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
              <ControlNav setSidebarOpen={setSidebarOpen} />
              <div className="flex-1 relative z-5 flex overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">

                <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={venuLogo}
              alt="Venu Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Change your password</h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={changePassword} method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="password" className="sr-only">
                  Old Password
                </label>
                <input
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  placeholder="Current Password"
                  onChange={(e)=>{setOldPassword(e.target.value)}}

                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  New Password
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  placeholder="New Password"
                  onChange={(e)=>{setNewPassword(e.target.value)}}

                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Confirm New Password
                </label>
                <input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                  placeholder="Confirm New Password"
                  onChange={(e)=>{setConfirmNewPassword(e.target.value)}}

                />
              </div>
            </div>
            
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-700 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Update Password
              </button>
            </div>
          </form>
                </div>
                </div>
                </main>
              </div>
            </div>
          </div>
        </>
      );
}

export default CpChangePassword