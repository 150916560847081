import create from 'zustand'

const useCPStore = create((set) => ({
  bears: 0,
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  dateRangeValue:{startDate:null,endDate:null},
  setDateRangeValue:(input) => {set((state)=>({dateRangeValue:input}));},
  selectedListing : {},
  selectedPromoCode : {},
  selectedblogPost : {},
  showNavBar : true,
  allTOS : [],
  selectedLead: null,
  setSelectedListing: (inputListing)=>{set((state) =>({selectedListing :inputListing})); console.log(inputListing)},
  setSelectedPromoCode: (promoCode)=>{set((state) =>({selectedPromoCode :promoCode})); console.log(promoCode)},
  setSelectedblogPost : (blogPost) =>{set((state)=>({selectedblogPost : blogPost})) ; console.log(blogPost)},
  setShowNavBar : (bool)=>set({showNavBar:bool}),
  setAllTOS :(TOS)=>set({allTOS:TOS}),
  setSelectedLead : (lead)=>set({selectedLead:lead})
}));

export default useCPStore