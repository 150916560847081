import React from 'react'
import StayCard from 'components/StayCard/StayCard'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { makeid } from 'globals/publicData'
export default function ListingsGrid({listings =[], title = "Listings Grid" , primaryAction, primaryOnClk, secondaryAction, secondaryOnClk}) {
  return (
    <div className="sm:col-span-2">
    <dt className="text-xl w-full text-center mt-2 mb-2 font-medium bg-primary-700 text-white">{title}</dt>
    <dd className="mt-1 text-sm text-gray-900">
          <div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-3  lg:grid-cols-4 xl:grid-cols-5'>
              {
                   listings.map((listing , i)=>{
                      return <div className='flex flex-col '  key={i +makeid(1)}>
                          <StayCard className='' data={listing} key={i + makeid(1)}/>
                          {primaryAction &&  <ButtonPrimary clickInc={(e)=>{primaryOnClk && primaryOnClk(listing)}} className='mt-auto mb-0'>{primaryAction}</ButtonPrimary>}
                      </div>
                  })
              }
          </div>
    </dd>
  </div>
  )
}
