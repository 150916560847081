import axios from 'axios';
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import { url } from '../../globals';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Stats from './Stats';
import LoadingBar from 'react-top-loading-bar'
import LineLoader from 'components/LineLoader';

export default function BookingsReport() {
    const [dateRangeValue, setdateRangeValue] = useState({startDate : null , endDate : null})
    const [fieldFocused, setFieldFocused] = useState(null);
    const [s2Stats, sets2Stats] = useState([])
    const [s3Stats, sets3Stats] = useState([])
    const [s4Stats, sets4Stats] = useState([])
    const [s5Stats, sets5Stats] = useState([])
    const [loading, setloading] = useState(false)

    const stats = [
        { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
        { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
        { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
      ]

    
    useEffect(()=>{
      setloading(true)
      axios.post(`${url}/statistics/bookingsReport` , {dates : dateRangeValue}).then((res)=>{
        let obj = res.data;
        let tempS2Stats = []
        let tempS3Stats = []
        let tempS4Stats = []
        let tempS5Stats = []
        let messages = {
          name :"# of Website Messages",
          stat : obj.monthMsgs,
          previousStat : obj.lastMonthMsgs,
          change : obj.msgChangePerc + "%",
          changeType : obj.msgDiff
        }
        tempS2Stats.push(messages);

        let askHost = {
          name :"# of Ask Host Messages",
          stat : obj.monthAskHost,
          previousStat : obj.lastMonthAskHost,
          change : obj.askHostChangePerc + "%",
          changeType : obj.askHostDiff
        }
        tempS3Stats.push(askHost)

        let partials = {
          name :"# of Partial Bookings",
          stat : obj.monthPartials,
          previousStat : obj.lastMonthPartials,
          change : obj.partialsChangePerc + "%",
          changeType : obj.partialsDiff
        }
        tempS3Stats.push(partials)

        let leads = {
          name :"# of Self & Fresh Leads",
          stat : obj.monthLeads,
          previousStat : obj.lastMonthLeads,
          change : obj.leadsChangePerc + "%",
          changeType : obj.leadsDiff
        }
        tempS3Stats.push(leads)

        let bookings = {
          name :"# of Unpaid Bookings",
          stat : obj.monthBookings,
          previousStat : obj.lastMonthBookings,
          change : obj.bookingsChangePerc + "%",
          changeType : obj.bookingsDiff
        }
        tempS4Stats.push(bookings)

        let siteVisits = {
          name :"# of Site Visits",
          stat : obj.monthSiteVisits,
          previousStat : obj.lastMonthSiteVisits,
          change : obj.siteVisitsChangePerc + "%",
          changeType : obj.siteVisitsDiff
        }

        tempS4Stats.push(siteVisits);


        let revenue = {
          name :"# of Paid Bookings",
          stat : obj.monthRevenue,
          previousStat : obj.lastMonthRevenue,
          change : obj.revenueChangePerc + "%",
          changeType : obj.revenueDiff
        }
        tempS5Stats.push(revenue)

        sets2Stats(tempS2Stats);
        sets3Stats(tempS3Stats);
        sets4Stats(tempS4Stats)
        sets5Stats(tempS5Stats)

        setloading(false)




      })
      .catch((err)=>{
        setloading(false)
      })
    },[dateRangeValue])
  return (
    <div className='min-h-[90vh]'>
      {loading && <LineLoader/>}
      {!loading && <LineLoader done={true}/>}
    <h1 className="text-3xl  font-extrabold text-slate-900">Bookings Report</h1>
    <h2 className="text-xl mt-4 font-medium text-slate-900">Overview</h2>
      <p className="mt-1 text-sm text-slate-500">
        Summary about bookings, messages, leads, revenue and other funnels
      </p>

      <div className='mt-4 mb-2 w-full px-2 flex'>
      <RentalCarDatesRangeInput
                defaultDateValue={dateRangeValue}
                defaultFocus={
                  fieldFocused === "dropOffInput" ? null : fieldFocused
                }
                onFocusChange={(focus) => setFieldFocused(focus)}
                onChange={(data) => {
                  setdateRangeValue(data.stateDate);
                }}
              />

      </div>

      <div className ='mt-5'>
        {s2Stats.length>0 &&  <Stats title={"S2 Report"} stats={s2Stats}/>}
        {s3Stats.length>0 &&  <Stats title={"S3 Report"} stats={s3Stats}/>}
        {s4Stats.length>0 &&  <Stats title={"S4 Report"} stats={s4Stats}/>}
        {s5Stats.length>0 &&  <Stats title={"S5 Report"} stats={s5Stats}/>}
      </div>
    </div>
  )
}
