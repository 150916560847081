import { useState } from 'react'
import { Disclosure, RadioGroup, Tab, Transition } from '@headlessui/react'
import { ArrowDownIcon, ArrowUpIcon, StarIcon } from '@heroicons/react/solid'
import { HeartIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'

export default function Expandable({children , title}){
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    return (
<div className="mt-5   divide-y divide-gray-200">
  <Disclosure as="div">
    {({ open }) => (
      <>
        <h3>
        <div className='flex border md:px-10 border-gray-200 hover:bg-slate-50 transition-colors rounded-md shadow-md'>


          <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
            <span>
            <svg className="w-6 fill-gray-500  mr-2 md:mr-0 ml-2 md:ml-0" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M 16 4 L 15.625 4.15625 L 9.625 6.5625 L 9 6.8125 L 9 13.53125 L 3.59375 15.875 L 3 16.15625 L 3 24.21875 L 3.5 24.53125 L 9.5 27.875 L 9.96875 28.125 L 10.4375 27.90625 L 16 25.125 L 21.5625 27.90625 L 22.03125 28.125 L 22.5 27.875 L 28.5 24.53125 L 29 24.21875 L 29 16.15625 L 28.40625 15.875 L 23 13.53125 L 23 6.8125 L 22.375 6.5625 L 16.375 4.15625 Z M 16 6.1875 L 19.28125 7.46875 L 16 8.75 L 12.71875 7.46875 Z M 11 8.9375 L 15 10.46875 L 15 15.34375 L 11 13.5625 Z M 21 8.9375 L 21 13.5625 L 17 15.34375 L 17 10.46875 Z M 10 15.3125 L 13.625 16.90625 L 10 18.6875 L 6.375 16.875 Z M 22 15.3125 L 25.625 16.875 L 22 18.6875 L 18.375 16.90625 L 19.5 16.40625 Z M 5 18.40625 L 9 20.40625 L 9 25.3125 L 5 23.0625 Z M 27 18.40625 L 27 23.0625 L 23 25.3125 L 23 20.40625 Z M 15 18.46875 L 15 23.375 L 11 25.375 L 11 20.40625 Z M 17 18.46875 L 21 20.40625 L 21 25.375 L 17 23.375 Z" /></svg>

            </span>
            <span
              className={classNames(open ? 'text-primary-200' : 'text-gray-900', 'md:text-base text-sm font-medium')}
            >
              {title}
            </span>
            <span className="ml-6 flex items-center">
              {open ? (
                <ArrowUpIcon
                  className="block h-6 w-6 text-primary-400 group-hover:text-primary-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowDownIcon
                  className="block h-6 w-6 text-gray-400 group-hover:text-primary-200"
                  aria-hidden="true"
                />
              )}
            </span>
          </Disclosure.Button>
          </div>

        </h3>

        <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
        <Disclosure.Panel as="div" className="pb-6  prose-sm">
                {children}
        </Disclosure.Panel>

        </Transition>
      </>
    )}
  </Disclosure>

</div>
    );
}



