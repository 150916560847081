import React from 'react'
import { Listbox } from '@headlessui/react'
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { SelectorIcon, CheckIcon } from '@heroicons/react/outline'
export default function ListBoxCustomized({
    selectedValue,
    changeHandler,
    listToMap,
    id
}) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
  return (
    <div>
                                        <Listbox   value={selectedValue} onChange={(e)=>{changeHandler(e,id)}}>
                                  {({ open }) => (
                                    <>
                                      <div className=" relative">
                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                          <span className="block truncate">{selectedValue}</span>
                                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          enter = "transition ease-in duration-400"
                                          enterTo='opacity-100'
                                          enterFrom='opacity-0'
                                          leave="transition ease-in duration-400"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                          
                                        >
                                          <Listbox.Options  className="absolute  z-50 mt-1 mb-5 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {listToMap.map((item) => (
                                              <Listbox.Option
                                                key={item}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'text-white bg-primary-500' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                  )
                                                }
                                                value={item}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {item}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active ? 'text-white' : 'text-primary-500',
                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                      >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
    </div>
  )
}
