import Glide from "@glidejs/glide";
import React, { useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import ncNanoId from "utils/ncNanoId";
import { Link } from "react-router-dom";
import { useState,  } from "react";
const GallerySlider = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-5 aspect-h-5",
  uniqueID,
  id
}) => {
  const UNIQUE_CLASS = uniqueID 
  const [dotsReady, setdotsReady] = useState(false);
  useEffect(() => {
    new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      animationDuration:600,
      gap: 0,
      keyboard: false,
      rewind:false
      
    }).mount();
  }, [UNIQUE_CLASS, galleryImgs]);
  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i+_}
            data-glide-dir={`=${i+_}`}
          />
        ))}
      </div>
    );
  };
  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <Link target={"_blank"} to = {`/listing-stay-detail?id=${id}`} >
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs.map((item, index) => (
              <li key={index+item} className="glide__slide relative">
                <div className={ratioClass}>
                  <NcImage  src={item} />
                </div>
              </li>
            ))}
          </ul>
        </div>       
         </Link>


        {/* DOTS */}
        {/* <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div> */}
        {dotsReady&& renderDots()}

        {/* NAV */}
        <div className="absolute opacity-100 z-20 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  useEffect(()=>{
    setdotsReady(true)
  },[])
  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};
export default GallerySlider;
