import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import RegistrationDT from "./RegistrationDT";
import DynamicDT from "components/DataTablesComponents/DynamicDT";
const ManageRegistrations = () => {
  return (
    <div>
        <div className="space-y-6 space-x-6 sm:space-y-8">
          {/* HEADING */}
          <div className="">
            <br />
            <div className="">
              <RegistrationDT />
            </div>
          </div>
        </div>
    </div>
  );
};
export default ManageRegistrations;
