
import { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'

import { DotsHorizontalIcon, LinkIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { url } from '../../globals';
import { Popover } from '@headlessui/react'

import {

} from "@heroicons/react/outline";


import moment from 'moment';
import { toast } from "react-toastify"
import { toastOptions } from 'globals/publicData';
import { useSnackbar } from 'snackfy';


import avatarIcon from "../../images/icons/avatar.svg";
import bookingIcon from "../../images/icons/booking.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import venueIcon from "../../images/icons/venue.svg";
import eventIcon from "../../images/icons/ballon.svg";
import commentIcon from "../../images/icons/comment.svg";
import moneyIcon from "../../images/icons/money.svg";
import ballonIcon from "../../images/icons/ballon.svg";
import gpsIcon from "../../images/icons/gps.svg";
import contactIDIcon from "../../images/icons/contactID.svg";
import phoneIcon from "../../images/icons/phone.svg";
import { TrashIcon, UserAddIcon } from '@heroicons/react/solid';
import { v4 as uuidv4 } from 'uuid';
import useAuthStore from 'globals/authStore';

const TeamContactsTableItems = ({
    setcurrentReservation,
    getAllBzContacts,
    columns,
    data,

}) => {
    const { enqueueSnackbar } = useSnackbar()
    const [allReservations, setallReservations] = useState([])
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const loggedUser = useAuthStore(state=>state.user)

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()



    const openVenueLink = (p) => {
        console.log(p.id)
        let url = "/listing-stay-detail?id=" + p.venuID
        window.open(url, '_blank')
    }
    const deleteBzContact = async (p) => {
        console.log(p.id)
        axios
            .post(`${url}/contact/deleteBzContact`, {

                id: p.id,
            })
            .then((res3) => {
                toast.success("Deleted successfully", toastOptions)

                getAllBzContacts()
            });

    }

    const createLead = (p)=>{
    
        axios.post(`${url}/contact/createLead` , {id : p.id,
        user : loggedUser , tDate : moment() , uuid : uuidv4() }).then((res)=>{
          console.log(res.data);
          axios.post(`${url}/leads/getMatchedListings` , {id : res.data.id}).then((res2)=>{
            toast.success("Lead created successfully!")
          })
        })
    
      }
    




    const options = [
        {
            name: "Create Lead",
            onClick: createLead,
            icon: UserAddIcon,
          },

        {
            name: "Delete Message",
            onClick: deleteBzContact,
            icon: TrashIcon,
        },

    ];


    const createbuttons = (p) => {

        return (
            <div className=" ">
                <Popover className="relative ">
                    {({ open, close }) => (
                        <>
                            <Popover.Button
                                ref={setReferenceElement}
                                className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                                {/* <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" /> */}
                                <DotsHorizontalIcon className="h-6 w-6 inline-flex mr-1
                   items-center p-1 border border-transparent border-primary-50 bg-primary-400 rounded-full shadow-sm
                    text-white  hover:bg-primary-500 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"/>
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel ref={setPopperElement}
                                    className="absolute right-full  z-50 w-screen max-w-[260px] px-4 -mt-3 mr-auto  sm:right-0 sm:px-0">
                                    <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                                            {options.map((item, index) => (
                                                <a
                                                    onClick={() => {
                                                        item.onClick(p);
                                                        close()
                                                    }}
                                                    key={index}
                                                    className="flex cursor-pointer items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                                >
                                                    <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                                                        <item.icon aria-hidden="true" className="w-6 h-6" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <p className="text-sm font-medium ">{item.name}</p>
                                                    </div>
                                                </a>
                                            ))}
                                        </div>

                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div>
        );

    }
    return (

        //--------------------------------------------------------------------------------------
        <div className="flex flex-col md:h-[750px]">
            <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2">
                {data.map((space) => (
                    <div
                        key={space.id}
                        className="relative rounded-lg bg-white px-6 py-5 shadow-md  items-center space-x-3 hover:border-gray-400 "
                    >
                        <div className="grid gap-5 grid-cols-2">
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <p className="text-[11px] font-medium text-gray-900">Contact ID</p>
                                </div>
                                <p className="text-xs font-light text-gray-500">{space.id}</p>
                            </div>
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={avatarIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Booking Name</p>
                                </div>

                                <p className="text-sm font-light text-gray-500">{space.name}</p>
                            </div>
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={phoneIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Phone Number</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{space.phoneNumber}</p>
                            </div >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={calendarIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Date</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{moment(space.tDate).toDate().toLocaleString()}</p>
                            </div  >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={moneyIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Highest Price</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{space.priceRangeHigh}</p>
                            </div  >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={moneyIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Lowest Price</p>
                                </div>
                                <p className="text-sm font-light text-gray-500"> {space.priceRangeLow}</p>
                            </div >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={ballonIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Event Type</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{space.listingType}</p>
                            </div>
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={gpsIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Visited From Recomm.</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{space.listings}</p>
                            </div>
                            {space.message != "" ? <button class="block transition ease-in-out text-white bg-primary-50 duration-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" type="button" onClick={() => { document.getElementById("popup-modal").classList.toggle("hidden"); setMessage(space.message) }}>
                                Open Message
                            </button>
                                : ""}
                            <div id="popup-modal" tabindex="-1" class="hidden bg-black bg-opacity-25 transition ease-in-out overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full">
                                <div class="relative p-4  transition ease-in-out duration-500  w-full max-w-md h-full md:h-auto">
                                    <div class="relative top-64 transition ease-in-out duration-500 bg-white rounded-lg shadow dark:bg-gray-700">
                                        <button onClick={() => document.getElementById("popup-modal").classList.toggle("hidden")} type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        </button>
                                        <div class="p-6 text-center">
                                            <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className=" absolute right-3 top-3 text-indigo-600 hover:text-indigo-900">
                                {createbuttons(space)}
                            </div>

                        </div>
                    </div>
                ))}
            </div>
            <div className="-my-2 md:block hidden sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow-md sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {columns.map((column) => (

                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            <div className="flex items-center whitespace-nowrap gap-2">
                                                <img key={column.id} src={column.icon} className="w-5 h-5" />
                                                {column.name}
                                            </div>
                                        </th>))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data.map((space) => {

                                    return (
                                        <tr key={space.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{space.id}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex items-center">

                                                    <div className="ml-4">
                                                        <div className="text-sm font-medium text-gray-900">{space.name}</div>
                                                        <div className="text-sm text-gray-400">{space.phoneNumber}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">{moment(space.tDate).toDate().toLocaleString()}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">Highest: {space.priceRangeHigh}</div>
                                                <div className="text-xs text-gray-400"> Lowest: {space.priceRangeLow}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="text-sm text-gray-900">{space.listingType}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="text-sm text-gray-900">{space.listings}</div>
                                            </td>
                                            <td className="px-6 py-4 w-44 text-sm text-gray-500">
                                                {space.message != "" ? <button class="block transition ease-in-out text-white whitespace-nowrap bg-primary-50 duration-500 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" type="button" onClick={() => { document.getElementById("popup-modal2").classList.toggle("hidden"); setMessage(space.message) }}>
                                                    Open Message
                                                </button>
                                                    : ""}
                                                <div id="popup-modal2" tabindex="-1" class="hidden flex justify-center bg-black bg-opacity-25 transition ease-in-out overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full">
                                                    <div class="relative p-4 flex transition items-center ease-in-out duration-500  w-full max-w-md h-full md:h-auto">
                                                        <div class="relative transition justify-center min-w-[500px] ease-in-out duration-500 bg-white rounded-lg shadow dark:bg-gray-700">
                                                            <button onClick={() => document.getElementById("popup-modal2").classList.toggle("hidden")} type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
                                                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                            </button>
                                                            <div class="p-6 text-center">
                                                                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <button className="text-indigo-600 hover:text-indigo-900">
                                                    {createbuttons(space)}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default TeamContactsTableItems;



