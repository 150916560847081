import { Menu, Transition } from '@headlessui/react';
import { CheckCircleIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { url } from '../../globals';
import React, { Fragment, useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OfferHeader from 'components/OfferHeader';
import useLinkOpenTracker from 'hooks/useLinkOpenTracker';



const RequestOffers = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  let { id } = useParams();
  let imageSrc ='https://tailwindui.com/img/ecommerce-images/confirmation-page-05-01.jpg'
  let imageAlt = 'Glass bottle with black plastic pour top and mesh insert.'
  const [offers, setoffers] = useState([])
  const [listings, setlistings] = useState([])
  const [lead, setlead] = useState(null)
  const linktracker = useLinkOpenTracker();

  const getOffers = ()=>{
    axios.post(`${url}/offers/getOffersForLead` , { leadID : id}).then((res)=>{
      console.log(res.data)
      setoffers(res.data);
    })
  }

  const getListingsByIDs = async ()=>{
    let listingIDs = offers.map((offer)=>{
      return offer.listingID
    })
    await axios.post(`${url}/listing/getListingsByIDs`, { ids: listingIDs }).then((res2) => {
      setlistings(res2.data)
      console.log(res2.data)

  })

  }
  const getLead = ()=>{
    axios.post(`${url}/leads/getLeadByID` , {id:id}).then((res)=>{
      setlead(res.data);
    })
  }

  useEffect(()=>{
    getOffers()
    getLead()
  },[])

  useEffect(()=>{
    if(offers.length>0){
      getListingsByIDs()
    }
  },[offers])
  
  return (
    <div className="bg-white">
      <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 ">
        <div className="max-w-xl">
          <p className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">Your Offers</p>
          <dl className="mt-8 text-sm font-medium">
            <dt className="text-gray-900">Tracking Code</dt>
            <dd className="text-primary-700 mt-2">{id}</dd>
          </dl>
        </div>



        {
          offers.map((offer,i)=>{
            let listing = null
            for (let i = 0; i < listings.length; i++) {
              if(offer.listingID == listings[i].id){
                listing = listings[i]
              }
              
            }
            return(
              <div> 
                {lead && listing && offer && <OfferHeader getOffers={getOffers} listing={listing} offer={offer} lead ={lead}/>}
              </div>
              
            )
          })
        }
        {/* <div className="mt-5 mb-5 border-t border-gray-200 border shadow-sm px-4 py-5 rounded-md ">
          <h2 className="sr-only">Your order</h2>

          <h3 className="sr-only">Items</h3>
          <div className="flex items-center p-4 border-b border-gray-200 sm:p-6 sm:grid sm:grid-cols-4 sm:gap-x-6">
                    <dl className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                      <div>
                        <dt className="font-medium text-gray-900">Order number</dt>
                        <dd className="mt-1 text-gray-500">{12341234412}</dd>
                      </div>
                      <div className="hidden sm:block">
                        <dt className="font-medium text-gray-900">Date placed</dt>
                        <dd className="mt-1 text-gray-500">
                          <time dateTime={"date"}>{"date"}</time>
                        </dd>
                      </div>
                      <div>
                        <dt className="font-medium text-gray-900">Total amount</dt>
                        <dd className="mt-1 font-medium text-gray-900">{5123}</dd>
                      </div>
                    </dl>

                    <Menu as="div" className="relative flex justify-end lg:hidden">
                      <div className="flex items-center">
                        <Menu.Button className="-m-2 p-2 flex items-center text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Options for order {12351}</span>
                          <DotsVerticalIcon className="w-6 h-6" aria-hidden="true" />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-bottom-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={"#"}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  View
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={"#"}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Invoice
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                      <a
                        href={"#"}
                        className="flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span>View Order</span>
                      </a>
                      <a
                        href={"#"}
                        className="flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span>View Invoice</span>
                      </a>
                    </div>
                  </div>

                        <div className="flex items-center p-3 sm:items-start">
                          <div className="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                            <img
                              src={imageSrc}
                              alt={imageAlt}
                              className="w-full h-full object-center object-cover"
                            />
                          </div>
                          <div className="flex-1 ml-6 text-sm">
                            <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                              <h5>{"name"}</h5>
                              <p className="mt-2 sm:mt-0">{"price"}</p>
                            </div>
                            <p className="hidden text-gray-500 sm:block sm:mt-2">{"description"}</p>
                          </div>
                        </div>

                        <div className="mt-6 sm:flex sm:justify-between">
                          <div className="flex items-center">
                            <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" />
                            <p className="ml-2 text-sm font-medium text-gray-500">
                              Delivered on <time dateTime={"order.deliveredDatetime"}>{"order.deliveredDate"}</time>
                            </p>
                          </div>


                        </div>
        </div> */}


      </div>
    </div>
  )
}

export default RequestOffers