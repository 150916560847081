import React from "react";
import MyRouter from "./routers/index";
import {SnackbarProvider} from 'snackfy'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Animate from "react-smooth/lib/Animate";
function App() {
  return (
    <SnackbarProvider placement={{vertical:"top", horizontal :"right"}} >
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Animate to="1" from="0" attributeName="opacity">
      <MyRouter />

      </Animate>
    </div>
    <ToastContainer
  pauseOnFocusLoss
  pauseOnHover

/>
    </SnackbarProvider>

  );
}
export default App;
