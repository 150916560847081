import { useState } from 'react'
import { PlusCircleIcon, StarIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { RadioGroup } from '@headlessui/react'

export default function PackageDetails({sPackage}){
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    const [selectedOption, setSelectedOption] = useState()

    return (
    <div className=" lg:pb-6 lg:col-start-1 lg:col-span-2  ">
    {/* Description and details */}


    <div className="mt-2">
      <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

      <div className="mt-4">
        <ul role="list" className="pl-4 list-disc text-sm space-y-2">
          {sPackage.desc.map((highlight) => 
            {if(highlight!==""){
              return (<li key={highlight} className="text-gray-400">
              <span className="text-gray-600">{highlight}</span>
            </li>)
            }}
          )}
        </ul>
      </div>
    </div>

    {/* <div className="mt-10">
      <h2 className="text-sm font-medium text-gray-900">Details</h2>

      <div className="mt-4 space-y-6">
        <p className="text-sm text-gray-600">{addon.details}</p>
      </div>

    




  
    </div> */}
  </div>);
}