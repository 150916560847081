/* This example requires Tailwind CSS v2.0+ */
import { ExclamationIcon } from '@heroicons/react/solid'

export default function AlertWithDescription({desc,color,textColor }) {
  return (
    <div className={`rounded-md bg-yellow-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className={`h-5 w-5 text-yellow-400`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-800`}>Attention needed</h3>
          <div className={`mt-2 text-sm text-${color}-700`}>
            <p>
              {desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
