/*
  This SpaceFeatures requires Tailwind CSS v2.0+ 
  
  This SpaceFeatures requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { animationSteps } from "globals/publicData"
import rest2 from "images/Skas/rest2.jpg"
import rest3 from "images/Skas/rest3.jpg"
import rest4 from "images/Skas/rest4.jpg"
import rest5 from "images/Skas/rest5.jpg"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { Link } from "react-router-dom"
import Animate from "react-smooth/lib/Animate"
import "animate.css/animate.min.css";

const features = [
    {
      name: 'Outdoors Ready',
      description:
        'Amazing outdoor space for dining out, relaxing and catching up with your friends.',
      imageSrc: rest2,
      imageAlt: 'Printed photo of bag being tossed into the sky on top of grass.',
    },
    {
      name: 'Romantic Dates Ready',
      description:
        "Everything you need, to have the most memorable date with your partner, indoor or outdoor, evening or midnight, we've got you covered.",
      imageSrc: rest3,
      imageAlt: 'Double stitched black canvas hook loop.',
    },
    {
      name: 'Live Performances',
      description:
        'Never lose your entertainment sense with your live performances and events.',
      imageSrc: rest4,
      imageAlt: 'Black canvas body with chrome zipper and key ring.',
    },
  ]
  
  export default function SpaceFeatures() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 sm:py-32 sm:px-2 lg:px-4">
          <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
            <div className="max-w-3xl">
              <h2 className="font-semibold text-gray-500">Skas</h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Outings like never before!</p>
              <p className="mt-4 text-gray-500">
                We provide variety of spaces for dining out, picnics, dates, group reunions, live performances, weddings, engagements, birthdays, events and much more!
              </p>
            </div>
  
            <div className="space-y-16 pt-10 mt-10 border-t border-gray-200 sm:pt-16 sm:mt-16">
              {features.map((feature) => (
                <AnimationOnScroll initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInUp">
                <div
                  key={feature.name}
                  className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
                >
                  <div className="mt-6 lg:mt-0 lg:col-span-5 xl:col-span-4">
                    <h3 className="text-2xl font-medium text-gray-900">{feature.name}</h3>
                    <p className="mt-2 text-lg text-gray-500">{feature.description}</p>
                  </div>
                  <div className="flex-auto lg:col-span-7 xl:col-span-8">
                  <Link to={'listing-stay-details?id=445'}>
                    <div className="aspect-w-5 aspect-h-2 rounded-lg bg-gray-100 overflow-hidden">
                       
                        <img src={feature.imageSrc} alt={feature.imageAlt} className="object-center object-cover" />

                       
                    </div>
                    </Link>
                  </div>
                </div>
                </AnimationOnScroll>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  