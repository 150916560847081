import StayCard from "components/StayCard/StayCard"

const EntityCardWithCTA = ({imageLink , data})=>{
    return(
        <section className="text-gray-600 body-font px-28">
  <div className="container mx-auto flex px-5 py-4 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Your Best Barbeque And Picnic Lounge!
      </h1>
      <p className="mb-8 leading-relaxed">A restaurant space that contains a cosy indoor area with a wide open air outdoor garden. Perfect for your romantic dates, outings and friends gatherings. Offers a variety of packages and dining options.</p>
      <div className="flex justify-center">
        <button className="inline-flex text-white bg-primary-900 border-0 py-2 px-6 focus:outline-none hover:bg-primary-800 rounded text-lg">Button</button>
        <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
      </div>
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      {/* <img className="object-cover object-center rounded" alt="hero" src={imageLink}></img> */}
      {data && <StayCard data={data}/>}
    </div>
  </div>
</section>
    )
}
export default EntityCardWithCTA