
import { useState, } from 'react';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { toast } from "react-toastify";
import { BanIcon, CheckIcon, DotsHorizontalIcon, LinkIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { url } from '../../globals';
import { Popover } from '@headlessui/react'
import avatarIcon from "../../images/icons/avatar.svg";
import phoneIcon from "../../images/icons/phone.svg";
import bookingIcon from "../../images/icons/booking.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import venueIcon from "../../images/icons/venue.svg";
import loadingIcon from "../../images/icons/loading.svg";

const RegistrationTableItems = ({
  getAllRes,
  columns,
  setDetails,
  setDetailsModal,
  data,

}) => {


  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()

  const rejectApplication = async (p) => {
    await axios.post(`${url}/registeration/reject`, { id: p.id }).then((res) => {
      getAllRes()
      toast.success("Application rejected");
    })

  }
  const viewDetails = async (p) => {
    setDetails(p)
    setDetailsModal(true)
  }
  const createAccount = (p) => {
      axios.post(`${url}/user/create`, { email: p.email, name: p.name, password: p.password, phoneNumber: p.phone, type: p.type, register:true }).then((res) => {
        axios.post(`${url}/registeration/accept`, { id: p.id }).then((res) => {
          getAllRes()
          toast.success("Account created successfully");
        })
      }).catch((error)=>{
        toast.error(error.response.data.message)
      })
  }

  const options = [
    {
      name: "Details",
      onClick: viewDetails,
      icon: DotsHorizontalIcon,
    },
    {
      name: "Create Account",
      onClick: createAccount,
      icon: CheckIcon,
    },
    {
      name: "Reject",
      onClick: rejectApplication,
      icon: BanIcon,
    },
  ]
  const altOptions = [
    {
      name: "Details",
      onClick: viewDetails,
      icon: DotsHorizontalIcon,
    },
  ]

  const createbuttons = (p) => {

    return (
      <div className=" ">
        <Popover className="relative ">
          {({ open, close }) => (
            <>
              <Popover.Button
                ref={setReferenceElement}
                className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                {/* <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" /> */}
                <DotsHorizontalIcon className="h-6 w-6 inline-flex mr-1
                   items-center p-1 border border-transparent border-primary-50 bg-primary-400 rounded-full shadow-sm
                    text-white  hover:bg-primary-500 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"/>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel ref={setPopperElement}
                  className="absolute right-full  z-50 w-screen max-w-[260px] px-4 -mt-3 mr-auto  sm:right-0 sm:px-0">
                  <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                      {p.status !== 'pending' ? (altOptions.map((item, index) => (
                        <a
                          onClick={() => {
                            item.onClick(p);
                            close()
                          }}
                          key={index}
                          className="flex cursor-pointer items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <item.icon aria-hidden="true" className="w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">{item.name}</p>
                          </div>
                        </a>
                      ))):
                      (options.map((item, index) => (
                        <a
                          onClick={() => {
                            item.onClick(p);
                            close()
                          }}
                          key={index}
                          className="flex cursor-pointer items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <item.icon aria-hidden="true" className="w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">{item.name}</p>
                          </div>
                        </a>
                      ))
                      )}
                    </div>

                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );

  }


  return (

    //--------------------------------------------------------------------------------------
    <div className="flex flex-col md:h-[750px]">
      <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2">
        {data.map((space) => (
          <div
            key={space.id}
            className="relative rounded-lg bg-white px-6 py-5 shadow-md  items-center space-x-3 hover:border-gray-400"
          >
            <div className="grid gap-5 grid-cols-2">
              <div href="#" className="">
                <div className="flex items-center gap-2">
                  <img src={avatarIcon} className="w-3 h-3" />
                  <p className="text-[11px] font-medium text-gray-900">Name</p>
                </div>

                <p className="text-sm font-light text-gray-500">{space.name}</p>
              </div>
              <div href="#" className="">
                <div className="flex items-center gap-2">
                  <img src={phoneIcon} className="w-3 h-3" />
                  <p className="text-[11px] font-medium text-gray-900">Phone Number</p>
                </div>
                <p className="text-sm font-light text-gray-500">{space.phone}</p>
              </div >
              <div href="#" className="">
                <div className="flex items-center gap-2">
                  <p className="text-[11px] font-medium text-gray-900">Creation Date</p>
                </div>
                <p className="text-sm capitalize font-light text-gray-500">{space.tDate && space.tDate.slice(0,10)}</p>
              </div>
              <div href="#" className="">
                <div className="flex items-center gap-2">
                  <p className="text-[11px] font-medium text-gray-900">Type</p>
                </div>
                <p className="text-sm capitalize font-light text-gray-500">{space.type}</p>
              </div>
              <td className="">
              <div className="flex items-center gap-2">
                <img src={loadingIcon} className="w-3 h-3" />
                <p className="text-[11px] font-medium text-gray-900">Status</p>
              </div>
              {space.status === 'accepted' ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Accepted
                        </span> : space.status === 'rejected' ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Rejected
                        </span> : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                          Pending
                        </span>}
            </td>
              <div href="#" className="">
                <div className="flex items-center gap-2">
                  <p className="text-[11px] font-medium text-gray-900">Source</p>
                </div>
                <p className="text-xs capitalize font-light text-gray-500">{space.source}</p>
              </div>
              <div className=" absolute right-3 top-3 text-indigo-600 hover:text-indigo-900">
                {createbuttons(space)}
              </div>

            </div>
          </div>
        ))}
      </div>
      <div className="-my-2 md:block hidden sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-md sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div className="flex items-center gap-2">
                        {column.icon && <img key={column.id} src={column.icon} className="w-5 h-5" />}
                        {column.name}
                      </div>
                    </th>))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((space) => {

                  return (
                    <tr key={space.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">

                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{space.name}</div>
                            <div className="text-sm text-gray-400">{space.phone}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm capitalize text-gray-900">{space.tDate && space.tDate.slice(0,10)}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm capitalize text-gray-900">{space.type}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {space.status === 'accepted' ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Accepted
                        </span> : space.status === 'rejected' ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Rejected
                        </span> : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                          Pending
                        </span>}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="text-xs capitalize text-gray-900">{space.source}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button className="text-indigo-600 hover:text-indigo-900">
                          {createbuttons(space)}
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  )
}
export default RegistrationTableItems;



