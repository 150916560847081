const EntityCard = ({imageLink, reversed, title, highlight})=>{
    if(!title){
        title = "Acommodate your"
    }
    if(!highlight){
        highlight = "Event"
    }

    const imageHalf = ()=>{
        return(
            <div className="lg:w-1/2">
            <div className="h-64 bg-cover lg:rounded-lg lg:h-full" style={{"background-image":`url('${imageLink}')`}}></div>
        </div>
        )
    }

    const contentHalf = ()=>{
        return(
            <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">{title} <span className="text-primary-800 dark:text-blue-400">{highlight}!</span></h2>
            <p className="mt-4 text-gray-600 dark:text-gray-400">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem modi reprehenderit vitae exercitationem aliquid dolores ullam temporibus enim expedita aperiam mollitia iure consectetur dicta tenetur, porro consequuntur saepe accusantium consequatur.</p>

            <div className="mt-8">
                <a href="#" className="px-5 py-2 font-semibold text-gray-100 transition-colors duration-300 transform bg-primary-900 rounded-md hover:bg-primary-800">Start Now</a>
            </div>
        </div>
        )
    }
    if(reversed){

    }
    return(
        <section className="bg-gray-100 dark:bg-gray-900 lg:py-12 lg:flex lg:justify-center">
        <div className="bg-white dark:bg-gray-800 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">

        {reversed && contentHalf()}
        {reversed && imageHalf()}
        {!reversed && imageHalf()}
        {!reversed && contentHalf()}
      
    </div>
</section>
    )
}

export default EntityCard