import { useState } from "react";
import SecondaySideBar from "containers/ControlPanelLayout/SecondarySideBar";
import MainControlSection from "containers/ControlPanelLayout/MainControlSection";
import BlockLoader from "components/BlockLoader";
import MobileSecSideBarWrapper from "./MobileSecSideBarWrapper";
import MobileSideBarWrapper from "./MobileSideBarWrapper";
import DesktopSideBar from "./DesktopSideBar";
import ControlNav from "./ControlNav";
import DirectoryBreadcrumb from "./DirectoryBreadcrumb";
import { user } from "globals/publicData";
import ManageBzTeamContacts from "./ManageBzTeamContacts";
import ManageRegistrations from "./ManageRegistrations";


export default function CpRegistrations() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [secondBarOpen, setSecondBarOpen] = useState(false);

  return (
    <>
      {/*
        This CpBzTeamContacts requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="h-full flex">
        <MobileSideBarWrapper
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          user={user}
        />

        {/* <MobileSecSideBarWrapper
          secondBarOpen={secondBarOpen}
          setSecondBarOpen={setSecondBarOpen}
          SecondaryBar = {SecondaySideBar}
        /> */}

        {/* Static sidebar for desktop */}

        <DesktopSideBar user={user} />

        <div className="flex flex-col min-w-0 flex-1 ">
          <ControlNav setSidebarOpen={setSidebarOpen} />
          <div className="flex-1 relative z-5 flex ">
            <main className="flex-1 relative z-0 overflow-scroll focus:outline-none xl:order-last">
              {/* Breadcrumb */}
              {/* <DirectoryBreadcrumb setSecondBarOpen={setSecondBarOpen} /> */}

              {/* <BlockLoader/> */}
              <div className="p-3 sm:p-8">
              <div className="mt-5 text-4xl text-center font-semibold text-gray-900 lg:hidden">
                Registrations
              </div>
                <ManageRegistrations/>
              </div>

            </main>
            {/* <aside className="hidden order-first xl:order-first xl:flex xl:flex-col flex-shrink-0 lg:w-96 border-r border-gray-200">
              <SecondaySideBar setSecondBarOpen={setSecondBarOpen} />
            </aside> */}
          </div>
        </div>
      </div>
    </>
  );
}
