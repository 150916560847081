import { animationSteps } from 'globals/publicData'
import React from 'react'
import Animate from 'react-smooth/lib/Animate'

export default function BlockLoader2() {
  return (
    <div>
             <Animate steps={animationSteps}>
        <div className="absolute  z-10 top-0 h-full w-full backdrop-blur-[3px] bg-white/30 flex flex-col justify-center items-center text-gray-400">
          <h2 className='text-3xl'>
            Loading
          </h2>
        </div>
      </Animate>
    </div>
  )
}
