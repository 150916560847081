import create from 'zustand'

const useAuthStore = create(set => ({
    user : null,
    setUser: (user) => set(state => ({user : user})),
    checkAuth : ()=>{
        if(localStorage.getItem('CpUser5') !=null){
            return true
        }
        else
        return false
    },
    tempLoginInfo : null,
    settempLoginInfo : (info)=> set({tempLoginInfo:info}),
    realOTP : "",
    setrealOTP : (realOTP) => set({realOTP:realOTP}),
    accessToken : "",
    setaccessToken : (token)=>set({accessToken:token})




}))

export default useAuthStore