import { Listbox, Transition } from '@headlessui/react';
import { CalendarIcon, CheckIcon, CurrencyDollarIcon, CursorClickIcon, EyeIcon, SelectorIcon } from '@heroicons/react/solid';
import useAuthStore from 'globals/authStore';
import React, { useState, useEffect } from 'react'
import { Fragment } from 'react';
import axios from 'axios';
import { imagesAmazonS3URL, url } from '../../globals';
import BlockLoader from 'components/BlockLoader';
import Select from 'react-select';
const LinkAnalytics = () => {
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [venues, setVenues] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [analyticsLoading, setAnalyticsLoading] = useState(false);
    const [venueOptions, setvenueOptions] = useState([]);
    const loggedUser = useAuthStore(state => state.user)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        axios.post(`${url}/ownersLink/fetchAll`, { id: loggedUser.id }).then((res) => {
            setAnalyticsLoading(true)
            setAnalytics(res.data)
            setAnalyticsLoading(false)

        });
    }, []);
    useEffect(() => {
        if(selectedVenue){
            setAnalyticsLoading(true)
        axios.post(`${url}/ownersLink/fetch`, { id: selectedVenue.value }).then((res) => {
            setAnalytics(res.data)
            setAnalyticsLoading(false)
            console.log(res.data)
        });
    }
    else{
        setAnalyticsLoading(true)
        axios.post(`${url}/ownersLink/fetchAll`, { id: loggedUser.id }).then((res) => {
            setAnalytics(res.data)
            setAnalyticsLoading(false)

        });
    }
    }, [selectedVenue]);

    useEffect(() => {
        setAnalyticsLoading(true)

        axios.post(`${url}/listing/read`, {user : loggedUser}).then((res) => {
            setVenues(res.data)
            let allOptions = [];
            res.data.forEach(venue => {
                let obj = {};
                obj.label = venue.id +'_' + venue.name;
                obj.value = venue.id;
                allOptions.push(obj)
                
            });
            setAnalyticsLoading(false)

            setvenueOptions(allOptions)
        });;

    }, []);

    return (
        <div className='pt-2 mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex flex-col justify-center items-center w-full'>
                <div className='mt-6 mb-12'>
            <h2 className="text-4xl text-center font-semibold text-gray-900">Link Analytics Overview</h2>
                <br />
                <p className="text-center text-sm text-gray-500">
                      Total analytics and performance overview of all links belonging to this account.
                    </p>
                    <p className="text-center text-sm text-gray-500">
                      Use the dropdown menu to view analytics of a specific venue.
                    </p>
                    </div>
                {(!analytics || !venues || analyticsLoading) ? <BlockLoader /> : <div className='w-full flex flex-col justify-center items-center'>
                    <div className='w-full md:w-1/4 '>
                        <Select
                        defaultValue={selectedVenue}
                        onChange={setSelectedVenue}
                        options={venueOptions}
                        isClearable = {true}
                        /> 

       
                    </div>
                    <div className="mt-8 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3 w-full">
                        <div className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <CurrencyDollarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">Sales</dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">{analytics.sales ? analytics.sales.toLocaleString() : 0} EGP</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-gray-50 px-5 py-3">
                                    <div className="text-sm flex items-center justify-center">
                                        <a className="font-medium text-gray-700 hover:text-primary-900 cursor-pointer mx-auto" onClick={() => { handleViewRecent('bookings'); handleActivity("View Recent", "Sales"); }} >
                                            View recent
                                        </a>
                                        <Link to={"/cp/bookings"} onClick={() => { handleActivity("View All", "Sales"); }} className="font-medium text-gray-700 hover:text-primary-900 mx-auto">
                                            View all
                                        </Link>
                                    </div>
                                </div> */}
                        </div>

                        <div className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <CurrencyDollarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">Revenue</dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">{analytics.revenue ? analytics.revenue.toLocaleString() : 0} EGP</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-gray-50 px-5 py-3">

                                    <div className="text-sm flex items-center justify-center">
                                        <a className="font-medium text-gray-700 hover:text-primary-900 cursor-pointer mx-auto" onClick={() => { handleViewRecent('revenue'); handleActivity("View Recent", "Revenue"); }} >
                                            View recent
                                        </a>
                                        <Link to={"/cp/revenue"} onClick={() => { handleActivity("View All", "Revenue"); }} className="font-medium text-gray-700 hover:text-primary-900 mx-auto">
                                            View all
                                        </Link>
                                    </div>

                                </div> */}
                        </div>

                        <div className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <CalendarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">Bookings</dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">{analytics.bookings ? analytics.bookings.toLocaleString() : 0}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-gray-50 px-5 py-3">
                                    <div className="text-sm flex items-center justify-center">
                                        <a className="font-medium text-gray-700 hover:text-primary-900 cursor-pointer mx-auto" onClick={() => { handleViewRecent('bookings'); handleActivity("View Recent", "Bookings"); }} >
                                            View recent
                                        </a>
                                        <Link to={"/cp/bookings"} onClick={() => { handleActivity("View All", "Bookings"); }} className="font-medium text-gray-700 hover:text-primary-900 mx-auto">
                                            View all
                                        </Link>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                    <div className='w-full mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2 " '>
                        <div className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <EyeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">Views</dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">{analytics.views ? analytics.views.toLocaleString() : 0}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-gray-50 px-5 py-3">
                         <div className="text-sm">
                           <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                             View all
                           </a>
                         </div>
                       </div> */}
                        </div>
                        <div className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <CursorClickIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">Book Now Clicks</dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">{analytics.bookNowClicks ? analytics.bookNowClicks.toLocaleString() : 0}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-gray-50 px-5 py-3">
                         <div className="text-sm">
                           <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                             View all
                           </a>
                         </div>
                       </div> */}
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default LinkAnalytics