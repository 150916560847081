import { useEffect, useState } from "react"
import axios from "axios"
import { url } from "../globals"
import { v4 as uuidv4 } from 'uuid';
import { makeid } from "globals/publicData";
import moment from "moment";
const useLinkOpenTracker = ()=>{


    useEffect(()=>{
        let browserUser = localStorage.getItem("browserUser")
        if (!browserUser) {
            browserUser = uuidv4() + makeid(4);
            localStorage.setItem("browserUser", browserUser);
          }
          let tDate = moment();
        let URL = window.location.href
        console.log(URL)
        axios.post(`${url}/link/linkOpenRate` , {
            browserID : browserUser,
            link : URL,
            date : tDate

        }).then((res)=>{
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
        return URL;
    },[])

}


export default useLinkOpenTracker
