import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { SaveAsIcon, SaveIcon } from "@heroicons/react/solid";
import { EditText, EditTextarea } from "react-edit-text";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import ListBoxCustomized from "./ListBoxCustomized";
import { useState } from "react";
import BlockLoader from "./BlockLoader";
import BlockLoader2 from "./BlockLoader2";
import axios from "axios";
import { url } from "../globals";
import useCPStore from "globals/controlPanelStore";
import { useEffect } from "react";
import { toast } from "react-toastify";
/* This TOSSection requires Tailwind CSS v2.0+ */
const people = [
    { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@TOSSection asd wadw adw adw adwadawd wad.com' },
    { name: 'mesmes nilo', title: 'Area Paradigm Technician', role: 'Secertaire', email: 'jane.cooper@TOSSection asd wadw adw adw adwadawd wad.com' },
    // More people...
  ]
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const items = ["Before" , "After" , "On"];
  const amountOptions = ["Percentage" , "Flat"]

  export default function TOSSection() {
    const [selectedItem, setselectedItem] = useState(items[0]);
    const [amountOption, setamountOption] = useState(amountOptions[0])
    const allTOS = useCPStore(state=>state.allTOS);
    const setallTOS = useCPStore(state=>state.setAllTOS)
    const [loading, setloading] = useState(false) 
    const currentListing = useCPStore(state=>state.selectedListing)
    const handler = (selected, id)=>{
      setloading(true);
      console.log(selected)
      console.log(id)
      axios.post(`${url}/listing/updateTOS`, {id : id , when : selected}).then((res)=>{
        let tempTOS = allTOS;
        for (let i = 0; i < tempTOS.length; i++) {
          if(tempTOS[i].id == res.data.id){
            tempTOS[i]= res.data;
          }
          
        }

        setallTOS(tempTOS);
        setloading(false)
      })
    }
    const amountOptionsHandler = (selected,id)=>{
      console.log(selected)
      console.log(id)
      setloading(true);
      console.log(selected)
      console.log(id)
      axios.post(`${url}/listing/updateTOS`, {id : id , type : selected}).then((res)=>{
        let tempTOS = allTOS;
        for (let i = 0; i < tempTOS.length; i++) {
          if(tempTOS[i].id == res.data.id){
            tempTOS[i]= res.data;
          }
          
        }

        setallTOS(tempTOS);
        setloading(false)
      })
    }

    const createNewTOS= ()=>{
      setloading(true)
      axios.post(`${url}/listing/createTOS` , {listingID :currentListing.id  }).then((res)=>{
        toast.success("A new payment term has been created");
        getAllTOS();
        setloading(false)
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      })
    }

    const getAllTOS = ()=>{
      axios.post(`${url}/listing/getAllTOS` , {listingID :currentListing.id }).then((res)=>{
        console.log(res.data)
        setallTOS(res.data);
      })
    }

    const updateTOS = (e,fieldName, id) =>{
      if(e.value != e.previousValue){
        let updateObj = {
          id :id
        }
        updateObj[fieldName] = e.value;
        axios.post(`${url}/listing/updateTOS`, updateObj).then((res)=>{
          let tempTOS = allTOS;
          for (let i = 0; i < tempTOS.length; i++) {
            if(tempTOS[i].id == res.data.id){
              tempTOS[i]= res.data;
            }
            
          }
  
          setallTOS(tempTOS);
          setloading(false)
        })
      }
    }

    const deleteTOS = (id)=>{
      setloading(true);
      axios.post(`${url}/listing/deleteTOS`, {id : id}).then((res)=>{
        setloading(false);
        toast.success("Payment term deleted successfully")
        getAllTOS()
      })
    }

    useEffect(()=>{
      getAllTOS()
    },[currentListing])


    return (
      <div className="flex flex-col ">
        <div className="-my-2 overflow-x-auto sm:overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow  border-b border-gray-200 sm:rounded-lg">

              <div className="shadow ">
                
                {<ul className="mt-2 divide-y w-full divide-gray-200  block  shadow ">

                  {allTOS &&  allTOS.map((tos) => (
                   
                    <li key={tos.id} className="bg-white hover:bg-gray-50">
                      <div className="flex w-full px-4 py-4  bg-white hover:bg-gray-50">
                        <span className="flex items-center space-x-4 w-full">
                          <span className="flex-1 flex space-x-2 ">
                            {/* <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                            <span className="flex flex-col w-full text-gray-500 text-sm ">


                              <span className="text-gray-900 grid grid-cols-2 md:grid-cols-3   w-full  font-medium">
                                <span className="mt-auto mb-auto">
                                  <Badge
                                    className=""
                                    color="purple"
                                    name={"Payment Name"}
                                  />
                                </span>
                                <span className="font-normal ">
                                  <EditText
                                  onSave={(e)=>{updateTOS(e, "name", tos.id)}}
                                    placeholder="Term of Payment Name"
                                    defaultValue={tos.name}
                                  />
                                </span>
                              </span>

                              <span className="text-gray-900 grid grid-cols-2 md:grid-cols-3   w-full  font-medium">
                                <span className="mt-auto mb-auto">
                                  <Badge
                                    className=""
                                    color="purple"
                                    name={"Payment When"}
                                  />
                                </span>
                                <span className="font-normal  ">
                                
                                <ListBoxCustomized id={tos.id} listToMap={items} changeHandler={handler} selectedValue={tos.when}/>
                                

                                </span>
                                <span className="font-normal ml-3 mt-auto mb-auto text-gray-400">
                                The Event Date
                                </span>
                              </span>

                              {tos.when !== "On" && <span className="text-gray-900 grid grid-cols-2 md:grid-cols-3   w-full  font-medium">
                                <span className="mt-auto mb-auto">
                                  <Badge
                                    className=""
                                    color="purple"
                                    name={"Number of days"}
                                  />
                                </span>
                                <span className="font-normal ">
                                  <EditText
                                   onSave={(e)=>{updateTOS(e, "days", tos.id)}}
                                  type="number"
                                    placeholder="Enter your days"
                                    defaultValue={tos.days}
                                  />
                                </span>
                              </span>}

                              <span className="text-gray-900 grid grid-cols-2 md:grid-cols-3   w-full  font-medium">
                                <span className="mt-auto mb-auto">
                                  <Badge
                                    className=""
                                    color="purple"
                                    name={"Amount Type"}
                                  />
                                </span>
                                <span className="font-normal ">
                                <ListBoxCustomized id={tos.id} listToMap={amountOptions} changeHandler={amountOptionsHandler} selectedValue={tos.type}/>
                                </span>
                              </span>

                              <span className="text-gray-900 grid grid-cols-2 md:grid-cols-3   w-full  font-medium">
                                <span className="mt-auto mb-auto">
                                  <Badge
                                    className=""
                                    color="purple"
                                    name={"Value in number"}
                                  />
                                </span>
                                <span className="font-normal ">
                                <EditText
                                 onSave={(e)=>{updateTOS(e, "value", tos.id)}}
                                  type="number"
                                    placeholder="Enter your Value "
                                    defaultValue={tos.value}
                                  />
                                </span>
                              </span>




                              <span className="mt-3">
                              <Badge
                                    className=""
                                    color="yellow"
                                    name={"Notes"}
                                  />
                              </span>
                              <span className="font-normal truncate w-full sm:w-2/3 ">
                                  <EditTextarea
                                   onSave={(e)=>{updateTOS(e, "notes", tos.id)}}
                                    placeholder="Enter your data"
                                    defaultValue={tos.notes}
                                  />
                                </span>
                            </span>
                          </span>
                          
                        </span>
                        <div className="">
                          <button
                            onClick={(e)=>{deleteTOS(tos.id)}}
                            className="relative inline-flex items-center px-2 py-2  border border-gray-300 text-sm font-medium rounded-md  transition-color bg-red-500 text-white hover:bg-red-700"
                            type="button"
                          >
                            <TrashIcon className="w-5 h-5 "/>
                          </button>
                        </div>
                      </div>

                    </li>
                   
                  ))}
                </ul>}

                <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className=" sm:block">
                         
                        </div>
                        {
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <button
                          onClick={()=>{createNewTOS(); }}
                              type="button"
                            className="relative inline-flex
                             items-center px-4 py-2 border
                              border-gray-300 text-sm font-medium
                               rounded-md text-gray-700 bg-green-400
                                hover:bg-green-500 hover:text-white transition-colors"
                          >
                             {<p className="text-sm ">
                            Add
                          </p>}
                          </button>
                        </div>
                        }
                      </nav>
                {
                  loading && <BlockLoader2/>
                }



              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  