import useAppStore from 'globals/appStore';
import React from 'react'
import { useEffect } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
export default function SubListingTypeSection({brandPrimaryBG , brandSecondaryBG , brandText , listings , titleOne , titleTwo , titleThree}) {
    const allTypes = useAppStore(state=>state.allTypes)
    console.log("inner listings")
    console.log(listings)
    const eventTypeSwitcher = (typeID)=>{
        for (let i = 0; i < allTypes.length; i++) {
         const type = allTypes[i];
         if(typeID == typeID){
           return {
             name : type.title,
             icon : type.icon
           }
         }
         
        }
     }

     const goToListing= (listingID)=>{
      window.open(`https://www.venu.ai/listing-stay-detail?id=${listingID}` , '_blank')
     }

     useEffect(()=>{
        if(listings && allTypes.length>0){
            listings.forEach(listing => {
                listing.listingType = eventTypeSwitcher(listing.listingType).name

            });
        }

     },[listings,allTypes])
  return (
            <section aria-labelledby="category-heading" className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-baseline sm:justify-between">
                <h2 id="category-heading" className="text-2xl font-extrabold tracking-tight text-gray-900">
                  Our Most Popular Spaces
                </h2>
                {/* <a href="#" className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
                  Browse all categories<span aria-hidden="true"> &rarr;</span>
                </a> */}
              </div>
  
              <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">


                <div onClick={()=>{goToListing(listings[0].id)}} className="cursor-pointer group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">

                  <img
                    src={listings[0]? listings[0].gallery[0] : "https://tailwindui.com/img/ecommerce-images/home-page-03-featured-category.jpg"}
                    alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                    className="object-center object-cover group-hover:opacity-75"
                  />

                  <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
                    <div className="p-6 flex items-end">

                    <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

                      <div>
                        <h3 className="font-semibold text-white animate-pulse">
                            <span className="absolute inset-0" />
                            {listings[0].title}
                        </h3>
                        <p aria-hidden="true" className="mt-1 text-sm text-white">
                          {listings[0].price} EGP/ {listings[0].rate}
                        </p>
                        <p aria-hidden="true" className="mt-1 text-sm text-white">
                          Book now!
                        </p>
                      </div>
                      </AnimationOnScroll>

                    </div>

                  </div>

             

                <div onClick={()=>{goToListing(listings[1].id)}} className="cursor-pointer group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative  sm:h-full">
                  <img
                    src={listings[1]? listings[1].gallery[0] : "https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg"}
                    alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                    className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                  />
                  <div
                    aria-hidden="true"
                    className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                  />
                  <div className="p-6 flex items-end sm:absolute sm:inset-0">

                  <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

                    <div>
                      <h3 className="font-semibold text-white animate-pulse">
                          <span className="absolute inset-0" />
                          {listings[1].title}
                      </h3>
                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        {listings[1].price} EGP/ {listings[1].rate}
                      </p>
                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        Book now!
                      </p>
                    </div>
                    </AnimationOnScroll>

                  </div>
                  </div>

                

                <div onClick={()=>{goToListing(listings[2].id)}} className="cursor-pointer group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative  sm:h-full">
                  <img
                    src={listings[2]? listings[2].gallery[0] : "https://tailwindui.com/img/ecommerce-images/home-page-03-category-02.jpg"}
                    alt="Walnut desk organizer set with white modular trays, next to porcelain mug on wooden desk."
                    className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                  />
                  <div
                    aria-hidden="true"
                    className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                  />
                  <div className="p-6 flex items-end sm:absolute sm:inset-0">
                  <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

                    <div>
                      <h3 className="font-semibold text-white animate-pulse">
                          <span className="absolute inset-0" />
                          {listings[2].title}
                      </h3>
                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        {listings[2].price} EGP/ {listings[2].rate}
                      </p>
                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        Book now!
                      </p>
                    </div>
                    </AnimationOnScroll>
                  </div>
                </div>

              </div>
  
              {/* <div className="mt-6 sm:hidden">
                <a href="#" className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                  Browse all categories<span aria-hidden="true"> &rarr;</span>
                </a>
              </div> */}
            </div>
          </section>

    )
}
