import React from "react";
import { useState } from "react";
import Button from "shared/Button/Button";
import CsvDownload from 'react-json-to-csv'
import { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import useAuthStore from "globals/authStore";
const ExportAsCSV = ({data, exportables,filename}) => {
  const [filteredData, setfilteredData] = useState([]);
  const [disabled, setdisabled] = useState(true);
  const loggedUser = useAuthStore(state => state.user)
  useEffect(()=>{
    if(data && exportables){
      const newData = []
      data.forEach(element => {
        let obj = {}
        exportables.forEach(exportable => {
          obj[exportable.name] =  element[exportable.selector]
        });
        newData.push(obj);
      });
      setfilteredData(newData)
    }

  },[exportables])
  
  useEffect(()=>{
    if(loggedUser.rank === "Admin"){
      setdisabled(false)
    }
  },[loggedUser])

  const exportData = ()=>{
    const exportButton = document.getElementById('exportDivs');
    console.log(exportButton)
    if(exportButton){
      const button = exportButton.getElementsByTagName('button')[0];
      if(button){
        button.click();
        toast.success('Downloading CSV Export Data...');

      }
    }

  }
  return (
    <div>
      <div className="hidden" id = "exportDivs">
      <CsvDownload  filename={filename + moment().format('yyyy MM DD dd ')+".csv"} data={filteredData}/>

      </div>
      <Button disabled={disabled} onClick={exportData} className="btn-white">
        Export
        <span className="text-primary-100 ml-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
          />
        </svg>
        </span>
      
      </Button>
    </div>
  );
};

export default ExportAsCSV