import React from 'react';
import { useState } from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate,selected }) => {
  const pageNumbers = [];
  const pageLimit = 5;
  

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  
const getPaginationGroup = () => {
  let start = Math.floor((selected-1) / pageLimit) * pageLimit;
  var array = new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  for( var i = 0; i < array.length;i++){
    if(array[i] > pageNumbers.length){
      array.splice(i); 
    }
  }
  return array;
};
var isValid
const next = ">"
const prev = "<"
const first = "<<"
const last = ">>"
  return (
    <nav className='rounded-full flex justify-center md:gap-2 md:p-10'>
      <button onClick={() => paginate(1)}  className={ `rounded-3xl md:w-14 w-9  shadow-md flex text-center  ${selected == 1? 'invisible':""} md:text-3xl text-lg  text-primary-50 p-2 justify-center active:bg-primary-50 active:text-white`}>
             {first}
            </button>
      <button onClick={() => paginate(selected-1)}  className={ `rounded-3xl md:w-14 w-9 shadow-md flex text-center  ${selected == 1? 'invisible':""} md:text-3xl text-lg text-primary-50 p-2 justify-center active:bg-primary-50 active:text-white`}>
              {prev}
            </button> 
      <ul className='rounded-full w-fit
       justify-content-start overflow-hidden shadow-md flex text-center md:text-3xl text-lg text-primary-50 p-2 justify-center px-2'>
        {getPaginationGroup().map((number) => (
          <div key={number} className=''>
            {number === selected ? isValid = true : isValid = false}
            
            <button id={number} onClick={() => {paginate(number);}}   className={ `md:w-14 w-9 rounded-full transition ease-in-out ${isValid? ' text-white bg-primary-50 ':""}`}>
              {number}
            </button>
          </div>
        )
        
        )}
      </ul>
      <button onClick={() => paginate(selected+1)}  className={ `rounded-3xl md:w-14 w-9  shadow-md flex text-center  ${selected == pageNumbers.length? 'invisible':""} md:text-3xl text-lg  text-primary-50 p-2 justify-center active:bg-primary-50 active:text-white`}>
             {next}
            </button>
            <button onClick={() => paginate(pageNumbers.length)}  className={ `rounded-3xl md:w-14 w-9  shadow-md flex text-center  ${selected == pageNumbers.length? 'invisible':""} md:text-3xl text-lg  text-primary-50 p-2 justify-center active:bg-primary-50 active:text-white`}>
             {last}
            </button>
    </nav>
  );
};

export default Pagination;