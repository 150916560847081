import React from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Animate from "react-smooth/lib/Animate";
import EntityBGSection from "./EntityBGSection";
import EntityCard from "./EntityCard";
import EntityCard2 from "./EntityCard2";
import EntityCardWithCTA from "./EntityCardWithCTA";
import useCPStore from "globals/controlPanelStore";
import { useEffect } from "react";
import pete1 from "images/pete1.PNG";
import pete3 from "images/pete3.jpg";
import NewNavBar from "shared/Header/NewNavBar";
import pete4 from "images/pete4.jpg";
import pete5 from "images/pete5.jpg"
import pete6 from "images/pete6.jpg"
import Heading from "components/Heading/Heading";
const PageRestaurant = ({ className = "" }) => {
    const setNavbar = useCPStore(state=>state.setShowNavBar);
    useEffect(()=>{
        setNavbar(false)
    },[])
  return (
    <div
      className={`nc-PageRestaurant overflow-hidden relative ${className}`}
      data-nc-id="PageRestaurant"
    >
      <Animate to="1" from="0" attributeName="opacity">
      <Helmet>
        <title>Pete's lounge</title>
        <link rel="icon" type="image/png" href="https://scontent-vie1-1.xx.fbcdn.net/v/t39.30808-1/256463084_121192493678601_1189833530085260907_n.jpg?stp=dst-jpg_p320x320&_nc_cat=104&ccb=1-7&_nc_sid=c6021c&_nc_eui2=AeELqzXQlR0uHVf5WNp9I5qvWhdKCEVxQfNaF0oIRXFB83kRmCVt4wjYF2eeY6jYXw0&_nc_ohc=R9XEmAo34bUAX9YWamu&_nc_ht=scontent-vie1-1.xx&oh=00_AT-PoaWUuHHEsgkJuEYn6q_Kh5S-4JmvaPdwujKzpBec0g&oe=635A344B" sizes="16x16" />

      </Helmet>

      {/* ======== BG GLASS ======== */}
      <NewNavBar/>
      <BgGlassmorphism />
      <EntityBGSection imageLink={pete1}/>
      <EntityCardWithCTA imageLink={pete3}/>
        <div className="grid grid-cols-2">
        <EntityCard title={"Enjoy our "} highlight={"Coziness"} imageLink={pete4}/>
        <EntityCard title={"Re-ignite your "} highlight={"Romance"} reversed={true} imageLink={pete5}/>

        </div>
        <div className="w-full  px-56 mb-3   ">
          <Heading className="mt-5 mb-5 " desc="Take a look as if you're already there! ">Take a virtual tour!</Heading>
          <iframe className="w-full h-screen shadow-lg rounded-md" src="https://360marketingsolution.info/_1_New/CleopatraSharm/Index.html">

          </iframe>
        </div>
      <EntityCard2 imageLink={pete6}/>
      </Animate> 
    </div>
  );
};
export default PageRestaurant;
