import { useState } from 'react'
import { Disclosure, RadioGroup, Tab, Transition } from '@headlessui/react'
import { ArrowDownIcon, StarIcon } from '@heroicons/react/solid'
import { ArrowUpIcon, HeartIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
import NcImage from 'shared/NcImage/NcImage'

export default function PackageExpandable({children , sPackage,input}){
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    return (
<div className="mt-5    ">
  <Disclosure as="div">
    {({ open }) => (
      <>
        <h3>
            <div className='flex border md:px-10 border-gray-200 hover:bg-slate-50 transition-colors rounded-md shadow-md'>
            <Disclosure.Button className="group  relative w-10/12 py-6 flex justify-between items-center text-left">
              <span>
              <svg className="w-5 fill-current mr-2 md:mr-0 ml-2 md:ml-0" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="M224,177.3V78.7a8.1,8.1,0,0,0-4.1-7l-88-49.5a7.8,7.8,0,0,0-7.8,0l-88,49.5a8.1,8.1,0,0,0-4.1,7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,7.8,0l88-49.5A8.1,8.1,0,0,0,224,177.3Z" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline fill="none" points="177 152.5 177 100.5 80 47" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline fill="none" points="222.9 74.6 128.9 128 33.1 74.6" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="128.9" x2="128" y1="128" y2="234.8" /></svg>

              </span>
            <span
              className={classNames(open ? 'text-primary-200' : 'text-gray-900', 'md:text-base text-sm font-medium')}
            >

              {sPackage.name} - {sPackage.price} EGP
            </span>
            <span className="ml-2 md:ml-6 text-sm flex items-center">
              {open ? (
                <ArrowUpIcon
                  className="block h-6 w-6 text-primary-400 group-hover:text-primary-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowDownIcon
                  className="block h-6 w-6 text-gray-400 group-hover:text-primary-200"
                  aria-hidden="true"
                />
              )}
            </span>
          </Disclosure.Button>

          {input}
            </div>
  
        </h3>
        <Transition
              enter="transition ease-out duration-500"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
        <Disclosure.Panel as="div" className="pb-6 ml-6  prose-sm">
                {children}
        </Disclosure.Panel>

        </Transition>
      </>
    )}
  </Disclosure>

</div>
    );
}



