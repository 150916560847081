import { Dialog, Menu, Transition } from '@headlessui/react'
import { CheckCircleIcon, DotsVerticalIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import axios from 'axios'
import { url } from '../globals'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import Badge from 'shared/Badge/Badge'
import Offer from './Offer'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import BlockLoader from './BlockLoader'

export default function OfferHeader({offer , lead, listing, getOffers}) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      const [open, setOpen] = useState(false)
      const [loading, setloading] = useState(false)
      const [loyaltyPromo, setloyaltyPromo] = useState("")
      const [promoApplied, setpromoApplied] = useState(false)
      const history = useHistory();

      const acceptOffer=()=>{
        setloading(true)
        axios.post(`${url}/offers/acceptOffer` , {id : offer.id , tDate : moment()}).then((res)=>{
            
            setloading(false);
            toast.success("Offer accepted!")
            toast.success("Booking Created!")
            history.push(`/bookingInfo/${res.data.id}`)

          })
      }

      const checkAndApplyLoyaltyPromo = ()=>{
        if(loyaltyPromo.length>0){
          if(loyaltyPromo == "AhlaMessa3amoura"){
            axios.post(`${url}/offers/applyLoyaltyPromo`, {offerID : offer.id}).then((res)=>{
              getOffers();
              toast.success("Promo code applied successfully! -2000 EGP")
              setpromoApplied(true)
            })
          }
        }
      }

  return (
    <div className="mt-5 mb-5 border-t border-gray-200 border shadow-sm px-4 py-5 rounded-md ">
    <h2 className="sr-only">Your order</h2>

    <h3 className="sr-only">Items</h3>
    <div className="flex items-center p-4 border-b border-gray-200 sm:p-6 sm:grid sm:grid-cols-4 sm:gap-x-6">
              <dl className="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                <div className='md:col-span-4'>
                  <dt className="font-medium text-gray-900">Offer#</dt>
                  <dd className="mt-1 text-sm text-gray-500">{offer.id}</dd>
                </div>
                <div className="md:col-span-3 hidden sm:block">
                  <dt className="font-medium text-gray-900">Date</dt>
                  <dd className="mt-1 text-gray-500">
                    <time dateTime={"date"}>{lead && lead.details && moment(lead.details.date).format('DD - MM - YYYY dddd')}</time>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">Total amount</dt>
                  <dd className="mt-1 font-medium text-gray-900">{offer.checkoutPrice} EGP</dd>
                </div>
              </dl>

              <Menu as="div" className="relative flex justify-end lg:hidden">
                <div className="flex items-center">
                  <Menu.Button className="-m-2 p-2 flex items-center text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Options for order {12351}</span>
                    <DotsVerticalIcon className="w-6 h-6" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-bottom-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                           onClick={(e)=>{setOpen(true)}}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            View
                          </a>
                        )}
                      </Menu.Item>

                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                <a
                 onClick={(e)=>{setOpen(true)}}
                  className="flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>View Offer</span>
                </a>

              </div>
            </div>

                  <div className="flex items-center p-3 sm:items-start">
                    <a href={`https://venu.ai/Venu/listing-stay-detail?id=${listing.id}`} target={"_blank"}>
                    <div className="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                      <img
                        src={listing && listing.gallery[0]}
                        // alt={""}
                        className="w-full h-full object-center object-cover"
                      />
                    </div>
                    </a>
                    <div className="flex-1 ml-6 text-sm">

                      <div className="font-medium  sm:flex sm:justify-between">
                      <a className='underline text-primary-200' href={`https://venu.ai/Venu/listing-stay-detail?id=${listing.id}`} target={"_blank"}>

                        <h5>{listing &&listing.title}</h5>
                        </a>

                        <p className="mt-2 sm:mt-0"><Badge name={offer.status} color={offer.status =="Pending"? "yellow" : (offer.status =="Accepted" ? "green" : "purple")}/></p>
                      </div>
                      <p className="hidden text-gray-500 sm:block sm:mt-2">{listing &&listing.desc}</p>
                      <p className="hidden text-gray-500 sm:block sm:mt-2">{listing &&listing.addonsText}</p>
                      <a href={`https://venu.ai/Venu/listing-stay-detail?id=${listing.id}`} target={"_blank"}
                 
                  className="flex items-center mt-2 justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>View Space </span>
                </a>
                    </div>
                  </div>

                  <div className="mt-6 sm:flex sm:justify-between">
                    <div className="flex items-center">
                      <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" />
                      <p className="ml-2 text-sm font-medium text-gray-500">
                        Offered on <time dateTime={"order.delivegreenDatetime"}>{moment(offer.tDate).format('DD - MM - YYYY dddd')}</time>
                      </p>
                    </div>


                  </div>




















                  <Transition.Root c show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">View Offer {offer && offer.id}</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {offer && listing && lead && <Offer
                        checkoutPrice={offer.checkoutPrice}
                        cpSide={false}
                        leadID={lead.id}
                        answers={offer.answers}
                        selectedAddons={offer.addons}
                        selectedPackage={offer.package}
                        paymentTerms={offer.paymentTerms}
                        listing={listing}
                        inOfferPage={false}
                        leadQuestions={lead.questions}

                        

                      />}
              <span className='text-lg md:w-1/2 mb-1 font-medium text-gray-900'>If you're a part of our loyalty program, please enter your promo code</span>

              <div className="flex w-full mb-5 justify-center space-x-4 mt-1">
                <input
                  value={loyaltyPromo}
                  onChange={(e)=>{setloyaltyPromo(e.target.value)}}
                  type="text"
                  id="discount-code"
                  name="discount-code"
                  className="block md:w-1/2 border-gray-300 rounded-md shadow-sm focus:ring-primary-700 focus:border-primary-700 sm:text-sm"
                />
                <button
                onClick={(e)=>{
                  checkAndApplyLoyaltyPromo()
                }}
                  type="button"
                  className="bg-gray-200 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-700"
                >
                  Apply
                </button>
              </div>

                      {loading && <BlockLoader/>}

                    {!loading && <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Accept Offer</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>Once you accept an offer, it will turn into a booking request. The owner will be notified and you will be sent a payment link in order to pay the first chunk mentioned in the payment terms.</p>
                            </div>
                            <div className="mt-5">
                            <button
                            onClick={(e)=>{acceptOffer()}}
                                type="button"
                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                            >
                                Accept Offer
                            </button>
                            </div>
                        </div>
                        </div>}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  </div>
  )
}
