/* This VenuesStackedList requires Tailwind CSS v2.0+ */
import { CalendarIcon, CheckCircleIcon, ChevronRightIcon, CurrencyDollarIcon, ExclamationCircleIcon, EyeIcon, LocationMarkerIcon, MailIcon, TagIcon } from '@heroicons/react/solid'
import useCPStore from 'globals/controlPanelStore'
import { useState } from 'react';
import useAuthStore from 'globals/authStore';
import axios from 'axios';
import { imagesAmazonS3URL, url } from '../globals';
import { useLocation } from 'react-router-dom';

export default function VenuesStackedList({venues , filterText, filteredVenues, setSecondBarOpen}) {

    const setSelectedListing = useCPStore((state)=> state.setSelectedListing);
    const [extraSelectClass, setextraSelectClass] = useState("");
    const selectedListing = useCPStore(state=>state.selectedListing);
    const loggedUser = useAuthStore(state=>state.user)
    var location = useLocation();
    location = location.pathname
    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }
    



  return (

    <div className="bg-white shadow overflow-hidden sm:rounded-md mt-2">
      <ul role="list" className="divide-y divide-gray-200">
        {filteredVenues.map((listing) => {
          let extraClass = "";
          if(selectedListing.id == listing.id){
            extraClass =" shadow-xl bg-slate-100 "
          }
          return <li key={listing.id}>
            <a onClick={()=>{
              setSelectedListing(listing);
              axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Venues List',url:location, extraInfoType:'Venu Name', extraInfo:listing.name }).then((res)=>{});; 
               setSecondBarOpen(false)
              }} className="block hover:bg-gray-50 hover:shadow-md shadow-sm transition-all">
              <div className={classNames("flex items-center px-4 cursor-pointer py-4 sm:px-6" , extraClass)}>
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    {
                      listing.gallery && 
                    <img className="h-12 w-12 rounded-full" src={listing.gallery[0]} alt="" />
                    }
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-blue-800 ">{listing.id}_{listing.name}</p>
                      <span>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {listing.rates[0] && <span className=" mr-2" >{listing.rates[0].week}</span>
}
                        <TagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className=" mr-2" >{listing.discountPercent}%</span>
                       
                        {/* <EyeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="mr-2">{listing.views}</span> */}
                        {/* <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="">{ listing.pressedOnBookNow}</span> */}
                      </p>
                      </span>
                     
                    </div>
                    <div className=" md:block">
                      <div>
                     
                        {
                          listing.active && (<p className="mt-2 flex items-center text-sm text-gray-500">
                          <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                          active
                        </p>)
                        }
                        {
                          !listing.active && (<p className="mt-2 flex items-center text-sm text-gray-500">
                          <ExclamationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                          inactive
                        </p>)
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />

                </div>
              </div>
            </a>
          </li>
})}
      </ul>
    </div>
  )
}
