
import { useState, } from 'react';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'

import { DotsHorizontalIcon, LinkIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { url } from '../../globals';
import { Popover } from '@headlessui/react'

import {

} from "@heroicons/react/outline";


import moment from 'moment';


import avatarIcon from "../../images/icons/avatar.svg";
import phoneIcon from "../../images/icons/phone.svg";
import bookingIcon from "../../images/icons/booking.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import venueIcon from "../../images/icons/venue.svg";

const RevenueTableItems = ({
    setcurrentReservation,
    columns,
    selectors,
    data,

}) => {
    const viewDetails = async (p) => {
        console.log(p.id)
        const res = await axios.post(`${url}/reservation/read`, { id: p.id })
        //console.log(res);
        //console.log(res.data);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setcurrentReservation(res.data)
        

    }
    const openVenueLink = (p) => {
        //console.log(p.id)
        let url = "/listing-stay-detail?id=" + p.venuID
        window.open(url, '_blank')
    }

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    const options = [
        {
            name: "Details",
            onClick: viewDetails,
            icon: DotsHorizontalIcon,
        },
        {
            name: "Venue Link",
            onClick: openVenueLink,
            icon: LinkIcon,
        },
        // {
        //   name: "Approve",
        //   onClick: approveReservation,
        //   icon: CheckIcon,
        // },
        // {
        //   name: "Reject",
        //   onClick: rejectReservation,
        //   icon: BanIcon,
        // },
    ];
    
    
    const createbuttons = (p) => {

        return (
            <div className=" ">
                <Popover className="relative ">
                    {({ open, close }) => (
                        <>
                            <Popover.Button
                                ref={setReferenceElement}
                                className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                                {/* <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" /> */}
                                <DotsHorizontalIcon className="h-6 w-6 inline-flex mr-1
                   items-center p-1 border border-transparent border-primary-50 bg-primary-400 rounded-full shadow-sm
                    text-white  hover:bg-primary-500 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"/>
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel ref={setPopperElement}
                                    className="absolute right-full  z-50 w-screen max-w-[260px] px-4 -mt-3 mr-auto  sm:right-0 sm:px-0">
                                    <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                                            {options.map((item, index) => (
                                                <a
                                                    onClick={() => {
                                                        item.onClick(p);
                                                        close()
                                                    }}
                                                    key={index}
                                                    className="flex cursor-pointer items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                                >
                                                    <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                                                        <item.icon aria-hidden="true" className="w-6 h-6" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <p className="text-sm font-medium ">{item.name}</p>
                                                    </div>
                                                </a>
                                            ))}
                                        </div>

                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div>
        );

    }
    return (

        //--------------------------------------------------------------------------------------
        <div className="flex flex-col md:h-[750px]">
            <div className="md:hidden grid grid-cols-1 gap-4 sm:grid-cols-2">
                {data.map((space) => (
                    <div
                        key={space.id}
                        className="relative rounded-lg bg-white px-6 py-5 shadow-md  items-center space-x-3 hover:border-gray-400"
                    >
                        <div className="grid gap-5 grid-cols-2">
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={avatarIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Booking Name</p>
                                </div>

                                <p className="text-sm font-light text-gray-500">{space.name}</p>
                            </div>
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={phoneIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Phone Number</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{space.phoneNumber}</p>
                            </div >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={calendarIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Event Date</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{moment(space.reservationDate).toDate().toLocaleString()}</p>
                            </div  >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={calendarIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Approval Date</p>
                                </div>
                                <p className="text-sm font-light text-gray-500"> {moment(space.approvalDate).toDate().toLocaleString()}</p>
                            </div >
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={venueIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Venu ID</p>
                                </div>
                                <p className="text-sm font-light text-gray-500">{space.venuID}</p>
                            </div>
                            <div href="#" className="">
                                <div className="flex items-center gap-2">
                                    <img src={bookingIcon} className="w-3 h-3" />
                                    <p className="text-[11px] font-medium text-gray-900">Booking Code</p>
                                </div>
                                <p className="text-xs font-light text-gray-500">{space.id}</p>
                            </div>
                            <div className=" absolute right-3 top-3 text-indigo-600 hover:text-indigo-900">
                                {createbuttons(space)}
                            </div>

                        </div>
                    </div>
                ))}
            </div>
            <div className="-my-2 md:block hidden sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow-md sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {columns.map((column) => (

                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            <div className="flex items-center gap-2">
                                                <img key={column.id} src={column.icon} className="w-5 h-5" />
                                                {column.name}
                                            </div>
                                        </th>))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data.map((space) => {

                                    return (
                                        <tr key={space.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex items-center">

                                                    <div className="ml-4">
                                                        <div className="text-sm font-medium text-gray-900">{space.name}</div>
                                                        <div className="text-sm text-gray-400">{space.phoneNumber}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">   Event: {moment(space.reservationDate).toDate().toLocaleString()}</div>
                                                <div className="text-sm text-gray-400">Approval: {moment(space.approvalDate).toDate().toLocaleString()}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{space.venuID}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="text-xs text-gray-900">{space.id}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <button className="text-indigo-600 hover:text-indigo-900">
                                                    {createbuttons(space)}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default RevenueTableItems;



