import StartRating from "components/StartRating/StartRating";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import { useState } from "react";
import BlockLoader from "components/BlockLoader";
import { url } from "../../globals";
import axios from "axios";
import moment from "moment";
import AlertWithDescription from "components/AlertWithDesc";
import Animate from "react-smooth/lib/Animate";
import AlertWithError from "components/AlertWithError";
import AlertWithSuccess from "components/AlertWithSuccess";
import { useEffect,useRef } from "react";
import NumberBadge from "containers/PageCheckOut/NumberBadge";
import { HomeIcon, TicketIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useAppStore from "globals/appStore";
const TrackRequests = ({ className = "" }) => {

  const [leads, setLeads] = useState(null);
  const [loading, setloading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState(false);
  let allTypes = useAppStore(state=>state.allTypes);
  let history = useHistory();
  const eventTypeSwitcher = (typeID)=>{
    for (let i = 0; i < allTypes.length; i++) {
     const type = allTypes[i];
     if(type.id == typeID){
       return type.title
     }
     
    }
 }

  useEffect(() => {
    let ls = localStorage.getItem("phoneNumber");
    if (ls && ls!= undefined && !isNaN(ls)) {
      console.log("got to ls")
      setPhoneNumber(ls);
      getLeads(ls);
    }

  }, []);
  async function getLeads(ls) {
    if (phoneNumber) {
      setloading(true)
      setLeads(null)
      try {
        const res = await axios.post(`${url}/leads/getLeads`, { phoneNumber: phoneNumber })
        console.log(res.data);
        if (res.data) {
          setLeads(res.data);
          localStorage.setItem("phoneNumber", phoneNumber)
          setloading(false)
          if(res.data.length === 0)
          {
            setError(true)
          }
          else{
            handleClick()
          }
        }
      }
      catch (err) {
        console.log(err)
        setloading(false)
      }
    }
    else if (ls) {
      setloading(true)
      setLeads(null)
      try {
        const res = await axios.post(`${url}/leads/getLeads`, { phoneNumber: ls })
        console.log(res.data);
        if (res.data) {
          setLeads(res.data);
          setloading(false)
          if(res.data.length === 0)
          {
            setError(true)
          }
          else{
            handleClick()
          }
        }
      }
      catch (err) {
        console.log(err)
        setloading(false)
      }
    }
    else {
      toast.warning("Please enter a valid phone number")
    }
  }
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <div className={`nc-BookingSearch ${className}`} data-nc-id="BookingSearch">
      <Animate to="1" from="0" attributeName="opacity">
        <main className="container mt-11 mb-24 lg:mb-32 ">
          {<div className="max-w-4xl mt-4 mx-auto">
            <div
              className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
              data-nc-id="SectionSubscribe2"
            >
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5" >
                <h2 className="font-bold tracking-tight text-4xl">Track Your Requests </h2>
                <h2 className="font-normal text-lg text-gray-500">Enter your phone number to view all your requests </h2>

                <form className="mt-6 relative max-w-sm" >
                  <Input
                    required
                    aria-required
                    placeholder="Enter your Phone Number"
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}

                  />
                  <ButtonCircle
                    type="button"
                    className="absolute transform top-1/2 -translate-y-1/2 right-1"
                    onClick={(e) => { getLeads(e) }}
                    loading={loading}
                  >
                    <i className="las la-arrow-right text-xl"></i>
                  </ButtonCircle>
                </form>
              </div>
              <div className="flex-grow">
                <NcImage src={rightImg} />
              </div>
              <div ref={ref} className="py-2 sm:py-0" aria-hidden="true" />
            </div>
          </div>}
          <div className="max-w-4xl mt-4 mx-auto">{loading && BlockLoader()}</div>
          {((leads && leads.length !== 0)) ? 
          <div className="max-w-5xl mt-4 mx-auto" >
            <div className="bg-white">
              <div className="max-w-5xl mx-auto px-4 py-2 sm:px-6 sm:py-4">
                <div className="max-w-xl">
                  <h1 id="your-orders-heading" className="text-4xl font-extrabold tracking-tight text-gray-900">
                    Your Requests
                  </h1>
                  <p className="mt-2 text-sm text-gray-500">
                    Check the status of your past requests.
                  </p>
                </div>
                <div className="my-8 space-y-16 sm:my-12">
                  {leads.map((lead) => (<div>
                    <div className="bg-gray-50 px-4 shadow-sm border border-gray-200 py-6 rounded-lg sm:p-6 md:flex md:items-center md:justify-between md:space-x-6 lg:space-x-8">
                      <dl className="divide-y divide-gray-200 space-y-4 text-sm text-gray-600 flex-auto md:divide-y-0 md:space-y-0 md:grid md:grid-cols-6 md:gap-x-6 lg:w-1/2 lg:flex-none lg:gap-x-8">
                        <div className="flex justify-between md:block">
                          <TicketIcon className="w-14 h-14 text-primary-500" />
                        </div>
                        <div className="flex justify-between pt-4 md:block md:pt-0 col-span-2">
                          <dt className="font-medium text-gray-900">Tracking Code</dt>
                          <dd className="md:mt-1">{lead.id}</dd>
                        </div>
                        <div className="flex justify-between pt-4 md:block md:pt-0">
                          <dt className="font-medium text-gray-900">Name</dt>
                          <dd className="md:mt-1">
                            <p>{lead.name}</p>
                          </dd>
                        </div>
                        <div className="flex justify-between pt-4 md:block md:pt-0 col-span-2">
                          <dt className="font-medium text-gray-900">Date placed</dt>
                          <dd className="md:mt-1">
                            <p>{lead.tDate && lead.tDate.slice(0, 10)}</p>
                          </dd>
                        </div>

                      </dl>
                      <div className="space-y-4 mt-6 sm:flex sm:space-x-4 sm:space-y-0 md:mt-0">
                      <button
                      onClick={()=> {history.push("/myrequests/"+lead.id)}}
                          type="button"
                          className="w-full flex items-center justify-center bg-primary-500 py-2 px-2.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-full"
                        >
                          Offers
                        </button>
                      <button
                          onClick={()=> {history.push("/myrequests/"+lead.id+"/matched-listings")}}
                          type="button"
                          className="w-full flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-full"                        >
                          Matched Listings
                        </button>
                      <button
                          onClick={()=> {history.push("/myrequests/"+lead.id+"/pinned-listings")}}
                          type="button"
                          className="w-full flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-full"                        
                        >
                          Pinned Listings
                        </button>

                      </div>
                    </div>
                    <div className="flex justify-center shadow-sm border border-gray-200 items-center bg-gray-50 rounded-lg w-full">
                      <dl className="grid sm:grid-cols-5 grid-cols-2 grid-rows-2 gap-y-6 gap-x-6 text-sm py-10 w-full px-4">
                        <div className="flex flex-col justify-center items-center">
                          <dt className="font-medium text-gray-900">Event Type</dt>
                          <dd className="mt-2 text-gray-700">
                            <p>{lead.details && eventTypeSwitcher(lead.listingType)}</p>
                          </dd>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <dt className="font-medium text-gray-900">Location</dt>
                          <dd className="mt-2 text-gray-700">
                            <p>{lead.details && lead.details.location}</p>
                          </dd>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <dt className="font-medium text-gray-900">Event Date</dt>
                          <dd className="mt-2 text-gray-700">
                          <p>{lead.details && lead.details.date && lead.details.date.slice(0,10)}</p>
                          </dd>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                        <dt className="font-medium text-gray-900">Guests</dt>
                          <dd className="mt-2 text-gray-700">
                          <p>{lead.details && lead.details.guests}</p>
                          </dd>
                        </div>
                        <div className="flex flex-col justify-center items-center col-span-2 sm:col-span-1">
                          <dt className="font-medium text-gray-900">Price Range</dt>
                          <dd className="mt-2 text-gray-700">
                          <p>{lead.details && lead.details.priceRangeLow} EGP  - {lead.details && lead.details.priceRangeHigh} EGP</p>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </div>:
          <div className="max-w-4xl mt-4 mx-auto">{
            error && <AlertWithDescription color={"yellow"} desc={"Couldnt find requests for to this phone number."} />}          </div>
          }
        </main>
      </Animate>
    </div>
  );
};
export default TrackRequests;
