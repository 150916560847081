import { Tab } from "@headlessui/react";
import StayCard from "components/StayCard/StayCard";
import axios from "axios";
import { useEffect } from "react";
import { imagesAmazonS3URL, url } from "../../globals";
import useAppStore from "globals/appStore";
import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

const RequestPinnedListings = () => {
    const [listings, setListings] = useState([]);
    const [lead, setLead] = useState(null);
    let { id } = useParams();
    useEffect(() => {
        if(id)
        {
            console.log(id)
            axios.post(`${url}/leads/getLeads`, { id: id}).then((res) => {
                setLead(res.data)
                console.log(res.data)
                axios.post(`${url}/listing/getListingsByIDs`, { ids: res.data[0].shortListed }).then((res2) => {
                    setListings(res2.data)
                    console.log(res2.data)
                })
            })
        }
    }, [id]);

    return (
        <div>
            <div className="pt-10 sm:pt-12 pb-24 lg:pb-32">
                <div className="space-y-6 sm:space-y-8">
                    <div className="flex flex-col justify-center items-center">
                        <h2 className="text-4xl font-semibold">Pinned Listings</h2>
                        <dl className="mt-4 flex flex-col justify-center items-center text-sm font-medium">
                        <dt className="text-gray-900">Tracking Code</dt>
                            <dd className="text-primary-700 mt-2">{id}</dd>
                        </dl>
                    </div>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-4 sm:px-36 py-4">
                        {listings.map((stay) => (
                            <StayCard key={stay.id} data={stay} leadID={id} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RequestPinnedListings;
