import { StarIcon } from "@heroicons/react/solid";
import React from "react";
const StartRating = ({ className = "", stars, reviewCount }) => {
  return (
    <div
      className={`nc-StartRating flex items-center space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      <StarIcon className="w-5 h-5 text-red-500" />
      <span className="font-medium ">{stars}</span>
      <span className="text-neutral-500 dark:text-neutral-400">
        ({reviewCount})
      </span>
    </div>
  );
};
export default StartRating;
