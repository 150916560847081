import React from "react";
import ReactDOM from "react-dom";
import DataTable , {createTheme} from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { columns, data } from "./data";
import Button from "shared/Button/Button";
import ButtonThird from "shared/Button/ButtonThird";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";


createTheme('solarized', {
  // text: {
  //   primary: '#268bd2',
  //   secondary: '#2aa198',
  // },
  // background: {
  //   default: '#002b36',
  // },
  context: {
    background: '#351c53',
    text: '#FFFFFF',
  },
  // divider: {
  //   default: '#073642',
  // },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
  selected: {
		default: '#957bb8',
		text: '#FFFFFF',
	},
}, 'light');

function DynamicDT({title = "Basic Title" , onRowSelect , columns, data, actionButtons, className, defaultSortColumn  }) {
  const tableData = {
    columns,
    data
  };

  if(!actionButtons)
   actionButtons = 
      <div className="flex">
        <button className="flex rounded-md bg-primary-100 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
          Edit 
          <PencilAltIcon className="w-5 h-5"/>
        </button>
        <button className="flex rounded-md bg-red-400 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
          Delete 
          <TrashIcon className="w-5 h-5"/>
        </button>
        <button className="flex rounded-md bg-red-400 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
          Delete 
          <TrashIcon className="w-5 h-5"/>
        </button>
        <button className="flex rounded-md bg-red-400 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
          Delete 
          <TrashIcon className="w-5 h-5"/>
        </button>
      </div>
    
  

  return (
    <div className="main">
      <DataTableExtensions exportHeaders  {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          selectableRows
          onRowClicked={(e)=>{console.log(e)}}
          selectableRowsHighlight
          selectableRowsSingle
          responsive
          defaultSortAsc={false}
          title={title}
          contextActions={actionButtons}
          className={className}
          defaultSortFieldId={defaultSortColumn? defaultSortColumn : 1}
      onSelectedRowsChange={(e)=>{console.log(e); onRowSelect(e)}}
      theme="solarized"
        />
      </DataTableExtensions>
    </div>
  );
}


export default DynamicDT
