import axios from "axios";
import { url } from "../../globals";
import { useParams } from "react-router-dom"
import { Fragment, useState } from "react";
import { useEffect } from "react";
import BlockLoader from "components/BlockLoader";
import PackageExpandable from "containers/ListingDetailPage/PackageExpandable";
import PackageDetails from "containers/ListingDetailPage/PackageDetails";
import Expandable from "containers/ListingDetailPage/Expandable";
import AddonsDetails from "containers/ListingDetailPage/AddonsDetails";
import Offer from "components/Offer";
import moment from "moment";
import SimpleInfoModal from "components/SimpleInfoModal";
import LeadInfo from "./LeadInfo";
import useAppStore from "globals/appStore";
import Button from "shared/Button/Button";
import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import useAuthStore from "globals/authStore";
import { useHistory } from "react-router-dom";

/* This CreateOffer requires Tailwind CSS v2.0+ */
const products = [
    {
      id: 1,
      name: 'Basic Tee',
      href: '#',
      price: '$36.00',
      color: 'Charcoal',
      size: 'L',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-06-product-01.jpg',
      imageAlt: "Model wearing men's charcoal basic tee in large.",
    },
    // More products...
  ]
  
  export default function CreateOffer() {
    const {leadID , listingID} = useParams();
    const [lead, setlead] = useState(null);
    const [listing, setlisting] = useState(null);
    const [mainLoading, setmainLoading] = useState(false)
    const [fetchedPackages, setfetchedPackages] = useState([])
    const [selectedPackage, setselectedPackage] = useState("default")
    const [fetchedAddons, setfetchedAddons] = useState([])
    const [selectedAddons, setselectedAddons] = useState([])
    const [hourlyBasis, sethourlyBasis] = useState(false)
    const [paymentTerms, setpaymentTerms] = useState([])
    const [selectedDate, setselectedDate] = useState(moment())
    const [selectedHours, setselectedHours] = useState([])
    const [priceWithoutAds, setpriceWithoutAds] = useState(0)
    const [checkoutPrice, setcheckoutPrice] = useState(0)
    const [openInfoModal, setopenInfoModal] = useState(false)
    const [PackagesModalOpen, setPackagesModalOpen] = useState(false)
    const [packageBulletPoints, setpackageBulletPoints] = useState([])
    const [packageDetails, setpackageDetails] = useState({})
    const [newBP, setnewBP] = useState("")
    const [AddonsModal, setAddonsModal] = useState(false)
    const [addonDetails, setaddonDetails] = useState({})
    const [answers, setanswers] = useState([])
    let allTypes = useAppStore(state=>state.allTypes);
    const loggedUser = useAuthStore(state=>state.user)
    const history = useHistory()

    const removeAddon = (addonInput)=>{
        let tempArr = selectedAddons.filter((addon)=>{
            return(addon.id !== addonInput.id)
        })
        setselectedAddons(tempArr)
    }
    const eventTypeSwitcher = (typeID)=>{
      for (let i = 0; i < allTypes.length; i++) {
       const type = allTypes[i];
       if(type.id == typeID){
         return {
           name : type.title,
           icon : type.icon
         }
       }
       
      }
   }
    const fetchLead = ()=>{
        axios.post(`${url}/leads/getLeadByID` , {id : leadID}).then((res)=>{
            console.log(res.data)
            setlead(res.data);
            setmainLoading(false)
            setselectedDate(moment(res.data.details.date).startOf('day'));

        }) 
    }

    const fetchlisting = ()=>{
        axios.post(`${url}/listing/read` , {id:listingID}).then((res)=>{
            console.log(res.data)
            if(res.data.rate == "hour" || res.data.rate =="Hour"){
                sethourlyBasis(true)
            }
            setlisting(res.data);
            setmainLoading(false)
        })

        axios.post(`${url}/package/read`,{venu : listingID}).then((res)=>{
            setfetchedPackages(res.data);
            axios.post(`${url}/package/read`,{leadVenuID : leadID+"~"+listingID}).then((res2)=>{
              let tempArr = res.data
              tempArr = tempArr.concat(res2.data)
              setfetchedPackages(tempArr)
          })
        })
        axios.post(`${url}/addons/read`, { venu: listingID }).then((res3) => {
            setfetchedAddons(res3.data);
            axios.post(`${url}/addons/read`, { leadVenuID: leadID+"~"+listingID }).then((res2) => {
              let tempAddons = res3.data.concat(res2.data);
              setfetchedAddons(tempAddons)
        
            })
          })

          axios.post(`${url}/listing/getAllTOS` , {listingID:listingID}).then((res)=>{
            console.log(res.data);
            setpaymentTerms(res.data);
          }).catch((err)=>{
            console.log(err)
          })
    }

    const createOffer = ()=>{
        let offer = {
            selectedAddons,
            selectedPackage,
            paymentTerms,
            checkoutPrice,
            answers,
            leadID,
            id : uuidv4(),
            listingID : listingID,
            tDate : moment(),
            accepted:false,
            status : "Pending",

        }
        axios.post(`${url}/offers/create`, offer).then((res)=>{
          console.log(res)
          toast.success('Offer has been created successfully');
          if(loggedUser.rank=="Admin"){
            history.push('/cp/qualified-leads')
          }
          if(loggedUser.rank=="Owner"){
            history.push('/cp/qualified-leads-owners')
          }
        })

    }

    const calculateTotalPrice = ()=>{
        let priceSoFar = 0;
        if(listing){
          let weekPrice = listing.rates[0].week
          let weekendPrice = listing.rates[0].weekend
          
          if(selectedDate){
            if(selectedDate.day()=== 5 || selectedDate.day()=== 6){
              //its a weekend date
              priceSoFar+= parseFloat(weekendPrice)
            }
            else{
              priceSoFar+= parseFloat(weekPrice)
            }
          }
          setpriceWithoutAds(priceSoFar)

          //check if the package selected is the default
          if(selectedPackage==="default"){
            if(hourlyBasis){

              priceSoFar= priceSoFar* selectedHours.length
            }
            if(listing.discountPercent!=0){
              priceSoFar = parseFloat(priceSoFar - (parseFloat(priceSoFar*listing.discountPercent / 100)));
            }
            
            setpriceWithoutAds(priceSoFar)
          }
          else{
            priceSoFar = parseFloat(selectedPackage.price);

          }


          selectedAddons.forEach(addon => {
            priceSoFar+= parseInt(addon.calculatedPrice)
          });



          setcheckoutPrice(priceSoFar);
          console.log(priceSoFar);
        }
        else{
          console.log("listing is still null")
        }
    }

    const createInstantPackage = ()=>{
      let packDetailsC = packageDetails;
      packDetailsC.desc = packageBulletPoints;
      packDetailsC.leadVenuID = leadID + "~"+  listing.id  
      packDetailsC.tDate = moment();
      axios.post(`${url}/package/create` , packDetailsC).then((res)=>{
        toast.success("Temporary package created for this request");
        let tempFetchedPackages = fetchedPackages.filter((r)=>{return true});
        tempFetchedPackages.push(res.data);
        setfetchedPackages(tempFetchedPackages);
        setpackageDetails({})
      })
    }

    const createInstantAddon = ()=>{
      let addonC = addonDetails;
      addonC.tDate = moment();
      addonC.leadVenuID = leadID +"~"+listingID
      axios.post(`${url}/addons/create`, addonC).then((res)=>{
        toast.success("Temporary Addon created for this request");
        let tempFetchedAddons = fetchedAddons.filter((r)=>{return true});
        tempFetchedAddons.push(res.data);
        setfetchedAddons(tempFetchedAddons)
        setaddonDetails({})
      })
    }

    useEffect(()=>{
        setmainLoading(true);
        fetchLead();
        fetchlisting();
    },[leadID,listingID])

    // useEffect(()=>{
    //   console.log(answers)
    // },[answers])


    useEffect(()=>{
        if(listing)
        calculateTotalPrice()
    },[selectedAddons,selectedPackage,listing])
    return (
      <>
        {/*
          This CreateOffer requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
        {!mainLoading && listing && lead && <main className="relative lg:min-h-full">
          <div className="h-full  overflow-auto lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-12">
            <div className="w-full">
                <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                  Create an offer
                </p>
                <p className="mt-2 text-base text-gray-500">
                  Use your packages, addons to create an offer to your customer, or create a custom invoice
                </p>
                <div className="mt-2 border-b border-gray-200 py-6 text-left">
                    <div onClick={()=>{setopenInfoModal(true)}}  className="text-sm cursor-pointer font-medium text-primary-500 hover:text-primary-700">
                      View Request Details<span aria-hidden="true"> &rarr;</span>
                      </div>
                    <div onClick={()=>{setPackagesModalOpen(true)}}  className="text-sm cursor-pointer font-medium text-primary-500 hover:text-primary-700">
                      Create Instant Package<span aria-hidden="true"> &rarr;</span>
                      </div>
                    <div onClick={()=>{setAddonsModal(true)}}  className="text-sm cursor-pointer font-medium text-primary-500 hover:text-primary-700">
                      Create Instant Addon<span aria-hidden="true"> &rarr;</span>
                      </div>
                </div>
                <div className="divide-y divide-gray-200">


                  {
                    fetchedPackages.map((sPackage) => {
                      return <PackageExpandable key={sPackage.id} sPackage={sPackage} input={
                        <div className="w-2/12 ml-2 text-center py-6 block items-center h-5">
                          <input
                            id={`sPackage-${sPackage.id}`}
                            aria-describedby={`sPackage-${sPackage.id}-description`}
                            name="sPackage"
                            type="radio"
                            checked={selectedPackage.name === sPackage.name}
                            onClick={(e) => { setselectedPackage(sPackage); }}
                            className="focus:ring-primary-500 h-4 w-4 text-primary-700 border-gray-300"
                          />
                        </div>
                      }>

                        <PackageDetails sPackage={sPackage} />

                      </PackageExpandable>


                    })
                  }


                        <div className="text-sm mt-10 sm:text-base text-neutral-6000 dark:text-neutral-300">

                        {
                        fetchedAddons.map((addon) => {
                            return (
                            <Expandable key={addon.id} title={addon.name + ' . ' + addon.price + " EGP"}>
                                <AddonsDetails setSelectedAddons={setselectedAddons} selectedAddons={selectedAddons} addon={addon} />

                            </Expandable>)
                        })
                        }



                        </div>

                </div>

            </div>
          </div>
  
          <div>
            <Offer  cpSide={true} leadID={leadID} listing={listing}
            removeAddon={removeAddon}
              selectedPackage={selectedPackage}
              paymentTerms={paymentTerms}
            setselectedPackage={setselectedPackage}
             selectedAddons={selectedAddons}
             checkoutPrice={checkoutPrice}
             normalPrice={priceWithoutAds}
             leadQuestions={lead.questions}
             answers = {answers}
             setanswers = {setanswers}
             createOffer={createOffer}
             
             />
             

          </div>
          <SimpleInfoModal setOpen={setopenInfoModal} open={openInfoModal}>
            <LeadInfo
             leadName={lead.name}
             leadID={leadID}
             comment={lead.details.comment}
             leadDetails={lead.details}
             leadPhone={lead.phoneNumber}
             listingType={eventTypeSwitcher(lead.listingType)}
             hideOfferButton
            
            />
          </SimpleInfoModal>







          <Transition.Root show={PackagesModalOpen} >
              <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-hidden"
                onClose={(b)=>{setPackagesModalOpen(b); }}
              >
                <div className="absolute inset-0 z-20 overflow-hidden">
                  <Dialog.Overlay className="absolute inset-0" />

                  <div className="fixed inset-y-0 z-20 right-0 pl-10 max-w-full flex sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                    
                      { <div className="w-screen z-20 max-w-2xl">
                        <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="px-4 py-6 bg-gray-50 sm:px-6">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-lg font-medium text-gray-900">
                                    New Package
                                  </Dialog.Title>
                                  <p className="text-sm text-gray-500">
                                    Get started by filling in the information
                                    below to create your new Package.
                                  </p>
                                </div>
                                <div className="h-7 flex items-center">
                                  <button
                                    type="button"
                                    className="text-gray-400 hover:text-gray-500"
                                    onClick={() => {setPackagesModalOpen(false);}}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Divider container */}
                            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              {/* Project name */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Package name
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input

                                    type="text"
                                    onChange={(e) => {
                                       setpackageDetails({...packageDetails , name : e.target.value} );
                                    }}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* Package pricing */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Package Pricing
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    onChange={(e) => {
                                      setpackageDetails({...packageDetails , price : e.target.value} );
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* Project description */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Description Points
                                  </label>
                                </div>
                                <div className="sm:col-span-2">

                                </div>
                              </div>
                              
                              {packageBulletPoints &&
                  
                                packageBulletPoints.map((bulletPoint, i) => {
                                  return (
                                    <div key={i} className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                      <div>
                                  
                                        <label
                                          htmlFor="project-name"
                                          className="flex justify-between text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                                <ButtonClose key={i} className='inline-flex' onClick={(e)=>{
                                                    e.preventDefault()
                                                      let packageBps = packageBulletPoints.filter((bp)=>{return true})
                                                      packageBps.splice(i,1);
                                                      console.log(packageBps)
                                                      setpackageBulletPoints(packageBps)

          
               

                                          }}></ButtonClose>
                                           #{i+1}
                                 
                                    
                                        </label>
                              
                                      </div>
                                      <div className="sm:col-span-2">
                                        <input
                                          type="text"
                                          defaultValue={bulletPoint}
                                          key={i}
                                          onChange={(e) => {
                                              let tempBPs = packageBulletPoints.filter((e)=>{return true});
                                              tempBPs[i] = e.target.value;
                                              setpackageBulletPoints(tempBPs)
                                          }}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                        />
                                                
                                      </div>
                                    </div>
                                  );
                                })}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Package Point
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    onChange={(e) => {
                                      setnewBP(e.target.value);
                                    }}
                                    value={newBP}
                                    type="text"
                                    className="ml-3 w-full  shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              <div className="flex justify-end">
                              <button
                                    type="button"
                                    sizeClass=""
                                    onClick={() => {
                                      let packageTempPoints = packageBulletPoints.filter((r)=>{return true});
                                      packageTempPoints.push(newBP);
                                      setnewBP("")
                                      setpackageBulletPoints(packageTempPoints)
                                    }}
                                    className="shadow-sm inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                                  >
                                    Add
                                    <PlusCircleIcon className="h-4 w-4 ml-2 mr-2" />
                                  </button>
                              </div>


                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                            <div className="space-x-3 flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                onClick={() => {setPackagesModalOpen(false); }}
                              >
                                Cancel
                              </button>

                              <Button
                                type="button"
                                sizeClass=""
                                onClick={() => {
                                  createInstantPackage()
                                  setPackagesModalOpen(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Create New
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>}
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>



            <Transition.Root show={AddonsModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-hidden"
                onClose={(b)=>{setAddonsModal(b);}}
              >
                <div className="absolute inset-0 z-20 overflow-hidden">
                  <Dialog.Overlay className="absolute inset-0" />

                  <div className="fixed inset-y-0 z-20 right-0 pl-10 max-w-full flex sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <div className="w-screen z-20 max-w-2xl">
                        <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="px-4 py-6 bg-gray-50 sm:px-6">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-lg font-medium text-gray-900">
                                    New Addon
                                  </Dialog.Title>
                                  <p className="text-sm text-gray-500">
                                    Get started by filling in the information
                                    below to create your new Addon.
                                  </p>
                                </div>
                                <div className="h-7 flex items-center">
                                  <button
                                    type="button"
                                    className="text-gray-400 hover:text-gray-500"
                                    onClick={() => setAddonsModal(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Divider container */}
                            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              {/* Project name */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon name
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    
                                    type="text"
                                    onChange={(e) => {
                                      setaddonDetails({...addonDetails , name : e.target.value})
                                    }}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* addon pricing */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon Pricing
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    onChange={(e) => {
                                      setaddonDetails({...addonDetails , price : e.target.value})
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* addon description */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon Description
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <textarea
                                    onChange={(e) => {
                                      setaddonDetails({...addonDetails , desc : e.target.value})
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                    rows={3}
                                  />
                                </div>
                              </div>

                              {/* addon notes */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon Notes
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <textarea
                                    onChange={(e) => {
                                      setaddonDetails({...addonDetails , notes : e.target.value})
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                    rows={3}
                                  />
                                </div>
                              </div>

                              {/* addon fixed */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Fixed Addon?
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    onChange={(e) => {
                                      setaddonDetails({...addonDetails , fixedAmount : e.target.checked})
                                    }}
                                    type="checkbox"
                                    className="block text-primary-200 shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                            <div className="space-x-3 flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                onClick={() => setAddonsModal(false)}
                              >
                                Cancel
                              </button>

                              <Button
                                type="button"
                                sizeClass=""
                                onClick={() => {
                                   createInstantAddon();
                                  setAddonsModal(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Create New
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

        </main>}
        {
            mainLoading && <BlockLoader/>
        }
      </>
    )
  }
  