import { useState } from 'react'
import { PlusCircleIcon, StarIcon, CheckCircleIcon, ShoppingCartIcon } from '@heroicons/react/solid'
import { RadioGroup } from '@headlessui/react'
import Button from 'shared/Button/Button';
import { useSnackbar } from 'snackfy';
import { toast } from "react-toastify";
import { toastOptions } from "globals/publicData";
export default function AddonsDetails({addon, selectedAddons, setSelectedAddons}){
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }


      let snackbarOptions = {
        message : "Listing Updated Successfully",
        placement : {vertical:"top"},
        options : {
          customStyle :{ info : {'background-color' : "#957bb8"},},
           
            variant : 'info'
        },
        
    }
      let snackbarOptions2 = {
        message : "Listing Updated Successfully",
        placement : {vertical:"top"},
        options : {
          customStyle :{ info : {'background-color' : "#957bb8"},},
           
            variant : 'warning'
        },
        
    }
    const {enqueueSnackbar} = useSnackbar({placement : {vertical:"top"}})

    const [selectedOption, setSelectedOption] = useState()
    const [amount, setamount] = useState(0);


    const handleFixedSelection = (inputAddon)=>{
      inputAddon = {...inputAddon , new:true}
      let tempAddonArr = selectedAddons.filter((addon)=>{return true});
      let foundAddon = false;
      for (let i = 0; i < tempAddonArr.length; i++) {
        const addonElement = tempAddonArr[i];
        if(addonElement.id === inputAddon.id)
        {
          toast.warning("Addon is already added to booking")
          foundAddon = true
        }
        
      }
      if(!foundAddon)
      {
        inputAddon.calculatedPrice = addon.price
        tempAddonArr.push(inputAddon)
        toast.success("Addon added to booking, check price update")
        setSelectedAddons(tempAddonArr)

      }

      setSelectedAddons(tempAddonArr)


    }
    const handleNonFixedSelection = (inputAddon)=>{
      inputAddon = {...inputAddon , new:true}

      let tempAddonArr = selectedAddons.filter((addon)=>{return true});
      let foundAddon = false;
      for (let i = 0; i < tempAddonArr.length; i++) {
        const addonElement = tempAddonArr[i];
        if(addonElement.id === inputAddon.id)
        {
          inputAddon.calculatedPrice = parseInt (addon.price * amount)
          inputAddon.amount = amount;
          tempAddonArr[i] = inputAddon;
          toast.info("Addon modified in booking, check price update")
          console.log(tempAddonArr)
          setSelectedAddons(tempAddonArr);

          return;
        }
        
      }
      if(!foundAddon)
      {
        inputAddon.calculatedPrice = parseInt (addon.price * amount)
        inputAddon.amount = amount;
        tempAddonArr.push(inputAddon)
        toast.info("Addon modified in booking, check price update")
        //setSelectedAddons(inputAddon);

      }

      setSelectedAddons(tempAddonArr)

      console.log(tempAddonArr)

    }

    return (
      <div className=" lg:pb-6 md:px-6  lg:col-start-1 lg:col-span-2  ">
        {/* Description and details */}
        <div>
          <h3 className="sr-only">Description</h3>

          <div className="space-y-6">
            <p className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 ">
              {addon.desc}
            </p>
          </div>
        </div>

        <div className="mt-10 w-full">
          {addon.notes && (
            <div>
              <h2 className="text-sm font-medium text-gray-900">Notes</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{addon.notes}</p>
              </div>
            </div>
          )}

          {addon.fixedAmount && (
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="sm:flex sm:items-start sm:justify-between">
                  <div>
                    <h3 className="text-lg mt-2 leading-6 font-medium text-gray-900">
                      Add to booking
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      <p>
                        Please note that this addon type is of{" "}
                        <span className="font-bold"> Fixed Number</span> type.
                        This means the addon comes as a whole package that you
                        can not customize. The total fee of the addon is{" "}
                        {addon.price} EGP.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                  

                    <span
                                            onClick={(e)=>{handleFixedSelection(addon)}}
                                             className="text-sm  underline flex cursor-pointer hover:text-primary-700 text-primary-200 transition-colors">
                                               <ShoppingCartIcon className="w-5  ml-2"/> 
                                               <span>Add ({addon.price} EGP) To Cart</span>
                                               </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!addon.fixedAmount && (
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="sm:flex sm:items-start sm:justify-between">
                  <div>
                    <h3 className="text-lg mt-2 leading-6 font-medium text-gray-900">
                      Add to booking
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      <p>
                        Add a custom amount of this addon.   Note that the unit price is  <span className='font-bold'>{addon.price}</span>  EGP
                      </p>

                    </div>
                  </div>
              
                </div>
                <form className="mt-5 sm:flex sm:items-center">
                    <div className=" sm:max-w-xs">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        type="number"
                        name="email"
                        id="email"
                        onChange={(e)=>{setamount(e.target.value)}}
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block sm:text-sm w-4/12 sm:w-auto border-gray-300 rounded-md"
                        placeholder="Ex: 20"
                      />
                    </div>
                

                    <span
                      onClick={(e)=>{handleNonFixedSelection(addon)}}
                      className="text-sm  underline flex cursor-pointer hover:text-primary-700 text-primary-200 transition-colors">
                                               <ShoppingCartIcon className="w-5  ml-2"/> 
                                               <span>Add To Cart</span>
                                               </span>
                  </form>
              </div>
            </div>
          )}

          {/* <div className="mt-10">
            <RadioGroup value={selectedOption} onChange={setSelectedOption}>
              <RadioGroup.Label className="text-base font-medium text-gray-900">
                Select an addon package
              </RadioGroup.Label>

              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {addon.options.map((option) => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? "border-transparent" : "border-gray-300",
                        active ? "ring-2 ring-primary-500" : "",
                        "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <div className="flex-1 flex">
                          <div className="flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {option.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className="mt-1 flex items-center text-sm text-gray-500"
                            >
                              {option.description}
                            </RadioGroup.Description>
                            <RadioGroup.Description
                              as="span"
                              className="mt-6 text-sm font-medium text-gray-900"
                            >
                              EGP {option.price}
                            </RadioGroup.Description>
                          </div>
                        </div>
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? "invisible" : "",
                            "h-5 w-5 text-primary-200"
                          )}
                          aria-hidden="true"
                        />
                        <div
                          className={classNames(
                            active ? "border" : "border-2",
                            checked
                              ? "border-primary-500"
                              : "border-transparent",
                            "absolute -inset-px rounded-lg pointer-events-none"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div> */}
        </div>
      </div>
    );
}