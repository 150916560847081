import { LockClosedIcon } from '@heroicons/react/solid'
import venuLogo from 'images/logoAdjusted.png'
import { useState } from 'react'
import axios from 'axios'
import { url } from '../../globals'
import { toast } from 'react-toastify'
import { toastOptions } from 'globals/publicData'
import { parseJwt } from 'globals/publicData'
import { Link, useHistory } from 'react-router-dom'
import useAuthStore from 'globals/authStore'
import { useEffect } from 'react'
import AlertWithDescription from 'components/AlertWithDesc'


const CpForgotPassword = () => {

  const [email, setemail] = useState(null)
  const [error, seterror] = useState(false);
  const [phoneNumber, setphoneNumber] = useState(null)

  const change = (e) => {
    e.preventDefault();
    if (!email && !phoneNumber) {
      toast.warning("Please enter a valid email or phone number");
    }
    else {
      axios.post(`${url}/user/changePassword`, { email: email, phoneNumber: phoneNumber }).then((res) => {
        const newurl =
          "https://smssmartegypt.com/sms/api/?username=mohadsamir@hotmail.com&password=F707C1*sR&sendername=Venu Egypt&mobiles=+2" +
          res.data[0].phoneNumber +
          `&message=Your new password is: `+ res.data[1] + " Do not share it with anyone."


        let headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "en-US",
        };

        // if (!window.location.href.includes("localhost")) {

          axios.get(newurl, { headers: headers }).then((res) => {
            console.log(res);
          }).catch((err) => {
            console.log(err)
          })

        // }
        toast.success("Your new password is being sent as an SMS")
      })
        .catch((err) => {
          if (err.status !== 200) {
            toast.error("Wrong email or phone number")
            seterror(true)
          }
        })
    }

  }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">

      <div className="max-w-md w-full space-y-8">
        {error && <AlertWithDescription color={"yellow"} desc={"We couldn't find a phone number associated with this account. Please contact our support team for assistance in changing your password +201120155661"} />}        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={venuLogo}
            alt="Venu Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Password?</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={change} method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => { setemail(e.target.value) }}
              />
            </div>
            <div className='flex justify-center items-center py-2'>
              <p className='font-medium'>Or</p>
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">
                Phone Number
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                autoComplete="phoneNumber"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                placeholder="Phone Number"
                onChange={(e) => { setphoneNumber(e.target.value) }}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-700 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-primary-500 group-hover:text-primary-400" aria-hidden="true" />
              </span>
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  )

}

export default CpForgotPassword