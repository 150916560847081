import DropdownTravelers from 'components/Header/DropdownTravelers';
import { useState, useEffect } from 'react';



const SortRevs = (
    props) => {

    const DropdownChangeHandler = (event) => {
        props.Dropdown(event.target.value)


    }


    return (
        <div >
            <select className=" text-gray-500 h-11 text-sm text-center rounded-2xl border-0 shadow-[0px_1px_4px] shadow-primary-50 " onChange={DropdownChangeHandler} >
            <option value="" disabled selected>Sort by</option>
                {props.options.map((option) => (
                    <option value={option.name.replace(/\s/g, '')}>{option.name}</option>
                ))}
            </select>


        </div>
    )




}
export default SortRevs;