import React from "react";
const BackgroundSection = ({
  className = "",
  children,
  bgcolor
}) => {
  if(className==""){
    className = "bg-neutral-100 dark:bg-black dark:bg-opacity-20 "
  }
  return (
    <div
      className={` ${bgcolor} nc-BackgroundSection absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 ${className}`}
      data-nc-id="BackgroundSection"
    >
      {children}
    </div>
  );
};
export default BackgroundSection;
