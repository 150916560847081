import DataTable from 'react-data-table-component';
import RevenueTableItems from 'components/TableItems/RevenueTableItems';
import SortRevs from 'components/SortByDropDown/SortRevs';
import Pagination from 'components/Pagination/Pagination';
import { useState, useEffect, useMemo } from 'react';
import { Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import useAuthStore from 'globals/authStore';
import { BanIcon, CheckIcon, DotsHorizontalIcon, LinkIcon, PaperClipIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { url } from '../../globals';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { toast } from "react-toastify";
import { toastOptions } from "globals/publicData";
import {

} from "@heroicons/react/outline";

import Input from 'shared/Input/Input';
import moment from 'moment';
import { useSnackbar } from 'snackfy';
import BlockLoader from 'components/BlockLoader';
import useCPStore from 'globals/controlPanelStore';
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import { LockClosedIcon } from '@heroicons/react/solid';

import avatarIcon from "../../images/icons/avatar.svg";
import bookingIcon from "../../images/icons/booking.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import venueIcon from "../../images/icons/venue.svg";
import loadingIcon from "../../images/icons/loading.svg";

import React from 'react'
import RegistrationTableItems from 'components/TableItems/RegistrationTableItems';
import ExportAsCSV from 'components/ExportAsCSV';


const RegistrationDT = () => {
  const [allReservations, setallReservations] = useState([])
  const [loading, setLoading] = useState(true);
  const loggedUser = useAuthStore(state => state.user)
  const [fieldFocused, setFieldFocused] = useState(null);
  const dateRangeValue = useCPStore(state => state.dateRangeValue)
  const setDateRangeValue = useCPStore(state => state.setDateRangeValue)
  const [types, setTypes] = useState()
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [detailsModal, setDetailsModal] = useState(false);
  const [details, setDetails] = useState(null);

  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const sortArray = (type, array) => {
    const typess = {
      Name: 'name',
      Phone: 'phone',
      Type: 'type',
      Source: 'source',
      CreationDate: 'tDate',
    };
    let sorted
    const sortProperty = typess[type];
    if (sortProperty === 'name') {
      sorted = [...array].sort((a, b) => a.name.localeCompare(b.name));
      return (sorted);
    }
    else if (sortProperty === 'tDate'){
      sorted = [...array].sort(function (a, b) { return new Date(a.tDate) - new Date(b.tDate) });
      return (sorted);
    }
    else if (sortProperty === 'phone') {
      sorted = [...array].sort((a, b) => a[sortProperty] - b[sortProperty]);
      return (sorted);
    }
    else if (sortProperty === 'type') {
      sorted = [...array].sort((a, b) => a.type.localeCompare(b.type));
      return (sorted);
    }
    else if (sortProperty === 'source') {
      sorted = [...array].sort((a, b) => a.source.localeCompare(b.source));
      return (sorted);
    }
    else {
      sorted = array.reverse()
      return (sorted);
    }


  };

  const exportables = [
    {
      name:"ID",
      selector : "id"
    },
    {
      name:"Creation Date",
      selector : "tDate"
    },
    {
      name:"Name",
      selector : "name",
    },
    {
      name:"Phone Number",
      selector : "phone",
    },
    {
      name : "E-mail",
      selector : "email",
    },
    {
      name:"Age",
      selector : "age"
    },
    {
      name : "Gender",
      selector : "gender"
    },
    {
      name:"Registration Type",
      selector : "type"
    },
    {
      name:"Status",
      selector : "status"
    },
    {
      name:"Source",
      selector:"source"
    },
    {
      name:"National ID",
      selector : "nationalID"
    },


]

  const filteredItems = sortArray(types, allReservations.filter(
    item => item.name && (item.name + "").toLowerCase().includes(filterText.toLowerCase()),
  ));

  const typesChangeHandler = (selectedType) => {
    setTypes(selectedType);
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };




    return (
      <Input placeHolder={"Search by name"} className={"shadow-[0px_1px_4px] shadow-primary-50"} onChange={e => { setCurrentPage(1); setFilterText(e.target.value); }} onClear={handleClear} filterText={filterText} />
    )
  }, [filterText, resetPaginationToggle]);

  const getAllRes = async () => {

    const res = await axios.post(`${url}/registeration/read`);
    console.log(res.data)
    setallReservations(res.data)
    toast.info("Fetched latest database update")
    setLoading(false);

  }
  useEffect(getAllRes, [])

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredItems.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const columns = [
    {
      name: 'Name',
      selector: "name",
      icon: avatarIcon,
      sortable: true,
      grow: 2


    },
    {
      name: 'Creation Date',
      selector: "tDate",
      sortable: true,
      grow: 2
    },
    {
      name: 'Type',
      selector: "type",
      sortable: true,
      grow: 2
    },
    {
      name: 'Status',
      icon: loadingIcon,
      selector: "status",
      sortable: true

    },

    {
      name: 'Source',
      selector: "source",
      sortable: true,
      grow: 2
    },
    {

    },
  ]


  const sortingOptions = [
    {
      name: "Name"

    },
    {
      name: "Phone"

    },
    {
      name: "Type"

    },
    {
      name: "Source"

    },
    {
      name: "Creation Date"

    },
  ]


  return (
    <>
      <div className='md:w-full p-3 w-full grid grid-cols-1 gap-2 md:flex justify-center md:justify-around'>
        {subHeaderComponentMemo}
        <SortRevs
          options={sortingOptions}
          Dropdown={typesChangeHandler}

        />
        <ExportAsCSV data={allReservations} exportables={exportables} filename={`registrations`}/>
      </div>
      {loading ? <BlockLoader /> :
        <RegistrationTableItems
          data={currentPosts}
          columns={columns}
          selectors={columns.map((col) => col.selector)}
          getAllRes={getAllRes}
          setDetails={setDetails}
          setDetailsModal={setDetailsModal}
        />
      }

      {details && (<Transition.Root show={detailsModal} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setDetailsModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="bg-white">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl text-center leading-6 font-medium text-gray-900">Details</h3>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Creation Date</dt>
                        <dd className="mt-1 text-sm  text-gray-900 sm:mt-0 sm:col-span-2">{details.tDate && details.tDate.slice(0,10)}</dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Full name</dt>
                        <dd className="mt-1 text-sm  text-gray-900 sm:mt-0 sm:col-span-2">{details.name}</dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Age</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{details.age}</dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Gender</dt>
                        <dd className="mt-1 text-sm capitalize text-gray-900 sm:mt-0 sm:col-span-2">{details.gender}</dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">National ID</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{details.nationalID}</dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Email address</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{details.email}</dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Phone number</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{details.phone}</dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Type</dt>
                        <dd className="mt-1 text-sm capitalize text-gray-900 sm:mt-0 sm:col-span-2">{details.type}</dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Source</dt>
                        <dd className="mt-1 text-sm capitalize text-gray-900 sm:mt-0 sm:col-span-2">{details.source}</dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{details.status}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="mt-3 w-full flex justify-end">
                  <button
                    type="button"
                    className="mt-3 w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                    onClick={() => { setDetailsModal(false); }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>)}


      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={filteredItems.length}
        paginate={paginate}
        selected={currentPage}
      />
    </>
  )
}
export default RegistrationDT;
