import { useState } from "react";
import MobileSideBarWrapper from "./MobileSideBarWrapper";
import DesktopSideBar from "./DesktopSideBar";
import ControlNav from "./ControlNav";
import { user } from "globals/publicData";
import { useEffect } from "react";
import useAuthStore from "globals/authStore";
import axios from "axios";
import { url } from '../../globals';
import { useLocation } from "react-router-dom";
import LinkAnalytics from "./LinkAnalytics";

export default function CpLinks() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [secondBarOpen, setSecondBarOpen] = useState(false);
  var location = useLocation();
  location = location.pathname
  const loggedUser = useAuthStore(state=>state.user)
  useEffect(() => {
    axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'onLoad',details:'Links',url:location}).then((res)=>{
    })
  }, []);
  return (
    <>
      {/*
        This CpPartialBookings requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="h-full flex">
        <MobileSideBarWrapper
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
          user={user}
        />

        {/* <MobileSecSideBarWrapper
          secondBarOpen={secondBarOpen}
          setSecondBarOpen={setSecondBarOpen}
          SecondaryBar = {SecondaySideBar}
        /> */}

        {/* Static sidebar for desktop */}

        <DesktopSideBar user={user} />

        <div className="flex flex-col min-w-0 flex-1 overflow-y-visible">
          <ControlNav setSidebarOpen={setSidebarOpen} />
          <div className="flex-1 relative z-5 flex overflow-y-visible">
            <main className="flex-1 relative z-0 overflow-y-visible focus:outline-none xl:order-last">
              {/* Breadcrumb */}
              {/* <DirectoryBreadcrumb setSecondBarOpen={setSecondBarOpen} /> */}

              {/* <BlockLoader/> */}
         
              <div className="p-3 sm:p-8">
                <LinkAnalytics/>
              </div>

            </main>
            {/* <aside className="hidden order-first xl:order-first xl:flex xl:flex-col flex-shrink-0 lg:w-96 border-r border-gray-200">
              <SecondaySideBar setSecondBarOpen={setSecondBarOpen} />
            </aside> */}
          </div>
        </div>
      </div>
    </>
  );
}
