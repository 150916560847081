import React from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Animate from "react-smooth/lib/Animate";
import EntityBGSection from "./EntityBGSection";
import EntityCard from "./EntityCard";
import EntityCard2 from "./EntityCard2";
import EntityCardWithCTA from "./EntityCardWithCTA";
import useCPStore from "globals/controlPanelStore";
import { useEffect } from "react";
import skas1 from "images/Skas/Skasthumbnail.jpg";
import pete3 from "images/pete3.jpg";
import NewNavBar from "shared/Header/NewNavBar";
import pete4 from "images/pete4.jpg";
import pete5 from "images/pete5.jpg"
import rest1 from "images/Skas/rest1.jpg"
import rest5 from "images/Skas/rest5.jpg"
import picnic1 from "images/Skas/picnic1.jpg"
import picnic2 from "images/Skas/picnic2.jpg"
import { useState } from "react";
import axios from "axios";
import { url } from "../../globals";
import SpaceFeatures from "./SpaceFeatures";
import SpaceCard2 from "./SpaceCard2";
import { animationSteps } from "globals/publicData";
import { AnimationOnScroll } from "react-animation-on-scroll"
import { Link } from "react-router-dom"
import "animate.css/animate.min.css";
import Heading from "components/Heading/Heading";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
const SkasRestaurant = ({ className = "" }) => {
  const [ListingOne, setListingOne] = useState(null)
    const setNavbar = useCPStore(state=>state.setShowNavBar);
    useEffect(()=>{
        setNavbar(false);
      axios.post(`${url}/listing/read` , {id : 445}).then((res)=>{
        console.log(res.data)
        setListingOne(res.data);
      })

    },[])
  return (
    <div
      className={`nc-SkasRestaurant overflow-hidden relative ${className}`}
      data-nc-id="SkasRestaurant"
    >
      
      <Helmet>
        <title>SKAS</title>
        <link rel="icon" type="image/png"
         href="https://scontent-vie1-1.xx.fbcdn.net/v/t39.30808-1/256463084_121192493678601_1189833530085260907_n.jpg?stp=dst-jpg_p320x320&_nc_cat=104&ccb=1-7&_nc_sid=c6021c&_nc_eui2=AeELqzXQlR0uHVf5WNp9I5qvWhdKCEVxQfNaF0oIRXFB83kRmCVt4wjYF2eeY6jYXw0&_nc_ohc=R9XEmAo34bUAX9YWamu&_nc_ht=scontent-vie1-1.xx&oh=00_AT-PoaWUuHHEsgkJuEYn6q_Kh5S-4JmvaPdwujKzpBec0g&oe=635A344B" sizes="16x16" />

      </Helmet>

      {/* ======== BG GLASS ======== */}
      <NewNavBar/>
      <BgGlassmorphism />
      <Animate steps={animationSteps}>
        <AnimationOnScroll  initiallyVisible={false} animateOnce={true} animatePreScroll={true} duration={3}  animateIn="animate__fadeIn" >
        <EntityBGSection imageLink={skas1}/>

        </AnimationOnScroll>

      </Animate>
      <div className="">
      {/* {ListingOne && <EntityCardWithCTA data={ListingOne} imageLink={pete3}/>} */}

      <SpaceFeatures/>
        {/* <div className="grid grid-cols-2">
        <EntityCard title={"Enjoy our "} highlight={"Coziness"} imageLink={pete4}/>
        <EntityCard title={"Re-ignite your "} highlight={"Romance"} reversed={true} imageLink={pete5}/>

        </div> */}
                <AnimationOnScroll  initiallyVisible={false} animateOnce={true} animatePreScroll={true} duration={3}  animateIn="animate__fadeIn" >
          <div className="w-full bg-slate-200 rounded-lg  px-56 py-10   ">
          <Heading className="mt-5 mb-5 " desc="Take a look as if you're already there! ">Take a virtual tour!</Heading>
          <iframe className="w-full h-screen shadow-lg rounded-md" src="https://360marketingsolution.info/_1_New/CleopatraSharm/Index.html">

          </iframe>
        </div>
        </AnimationOnScroll>
        <AnimationOnScroll initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1.5}  animateIn="animate__fadeInRight">
        {ListingOne && <EntityCard2 data={ListingOne} imageLink={rest1}/>}
        </AnimationOnScroll>

        <AnimationOnScroll initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1.5}  animateIn="animate__fadeInLeft">
        {ListingOne && <EntityCard2 data={ListingOne} imageLink={rest5}/>}
        </AnimationOnScroll>
        <AnimationOnScroll initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1.5}  animateIn="animate__fadeInRight">
        {ListingOne && <EntityCard2 data={ListingOne} imageLink={picnic1}/>}
        </AnimationOnScroll>
        <AnimationOnScroll initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1.5}  animateIn="animate__fadeInLeft">
        {ListingOne && <EntityCard2 data={ListingOne} imageLink={picnic2}/>}
        </AnimationOnScroll>
      </div>

      
    </div>
  );
};
export default SkasRestaurant;
