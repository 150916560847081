import {
   
    MailIcon,
    PhoneIcon,
    PlusCircleIcon,
    XIcon,
    
  } from '@heroicons/react/outline'
import { EditText, EditTextarea } from 'react-edit-text'
import { useState, useEffect } from 'react';
import { Fragment,  } from 'react'
import { Disclosure, Listbox, Menu, Transition, Dialog } from '@headlessui/react'
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClockIcon,
  CurrencyDollarIcon,
  EyeIcon,
  LinkIcon,
  LocationMarkerIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  SaveAsIcon,
  SaveIcon,
  SearchIcon,
  TagIcon,
  UserIcon,
  PlusSmIcon,
  SelectorIcon,
  CursorClickIcon,
  UsersIcon,
  CheckCircleIcon,
  RefreshIcon
  
} from '@heroicons/react/solid'
import axios from 'axios';
import { imagesAmazonS3URL,  url } from '../../globals';
import mediaURL from '../../globals/mediaURL'
import { Switch,  } from '@headlessui/react';
import React from "react";
import ImageUploading from "react-images-uploading";
import useAuthStore from 'globals/authStore';
import useCPStore from 'globals/controlPanelStore';
import selectConcept from 'images/logos/calendarVenues.svg'


import { useHistory, useLocation } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import BlockLoader from 'components/BlockLoader';
import Checkbox from 'shared/Checkbox/Checkbox';
import { useSnackbar } from 'snackfy';
import Button from 'shared/Button/Button';
import packagePNG from '../../images/packageIcon.png'
import moment from 'moment';
import addonIcon from "../../images/addonIcon.png"
import ModalImage from "react-modal-image"
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import { toast } from "react-toastify";
import { toastOptions } from "globals/publicData";
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import { DayPickerSingleDateController } from 'react-dates';
import useWindowSize from 'hooks/useWindowResize';
import { v4 as uuidv4 } from 'uuid';
import Animate from 'react-smooth/lib/Animate';
import Select from 'react-select';
import SimpleTable from 'components/TOSSection';
import TOSSection from 'components/TOSSection';
import { Container, Draggable } from 'react-smooth-dnd';

export default function MainControlSection()
{

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      var location = useLocation();
      location = location.pathname
      const [currentTab, setcurrentTab] = useState("General");
      const currentListing = useCPStore(state => state.selectedListing);
      const [Listing, setListing] = useState(null);
      const [loading, setloading] = useState(null);
      const [isAds, setisAds] = useState(false);
      const [updates, setupdates] = useState({});
      const [discountPerc, setdiscountPerc] = useState(0);
      const [weekRate, setweekRate] = useState(0);
      const [weekendRate, setweekendRate] = useState(0);
      const [calculatedWeekendRate, setcalculatedWeekendRate] = useState(0)
      const [calculatedWeekRate, setcalculatedWeekRate] = useState(0);
      const [fetchedAmenities, setfetchedAmenities] = useState([]);
      const [amenities, setamenities] = useState([])
      const {enqueueSnackbar} = useSnackbar()
      const [saveLoading, setsaveLoading] = useState(false);
      const [listingPackages, setlistingPackages] = useState([]);
      const [packageDeleteionLoading, setpackageDeleteionLoading] = useState(false)
      const [PackagesModalOpen, setPackagesModalOpen] = useState(false);
      const [SelectedPackage, setSelectedPackage] = useState("");
      const [fetchedPackage, setfetchedPackage] = useState(null)
      const [packageBulletPoints, setpackageBulletPoints] = useState([""]);
      const [updatedPackageBulletPoints, setupdatedPackageBulletPoints] = useState([""]);
      const [addingBulletPoint, setaddingBulletPoint] = useState(false)
      const [moreBulletPoints, setmoreBulletPoints] = useState(0);
      const [packageName, setpackageName] = useState("")
      const [packagePrice, setpackagePrice] = useState("");
      const [requestedToOpenModal, setrequestedToOpenModal] = useState(false)
      const [disableUpdate, setdisableUpdate] = useState(false)
      const [disableCreate, setdisableCreate] = useState(false);
      const [disableAddonUpdate, setdisableAddonUpdate] = useState(false)
      const [disableAddonCreate, setdisableAddonCreate] = useState(false)
      const [listingAddons, setlistingAddons] = useState([])
      const [selectedAddon, setselectedAddon] = useState("")
      const [AddonsModal, setAddonsModal] = useState(false)
      const [addonName, setaddonName] = useState("")
      const [addonPrice, setaddonPrice] = useState("")
      const [addonDesc, setaddonDesc] = useState("")
      const [addonNote, setaddonNote] = useState("")
      const [addonFixed, setaddonFixed] = useState(false)
      const [fetchedAddon, setfetchedAddon] = useState("");
      const [images, setImages] = React.useState([]);
      const FormDataa = require('form-data');
      const [uploading, setuploading] = useState(false)
      const [mediaLoading, setmediaLoading] = useState(false);
      const [amenitiesLoading, setamenitiesLoading] = useState(false);
      const [packagesLoading, setpackagesLoading] = useState(false);
      const [addonsLoading, setaddonsLoading] = useState(false);
      const [venuImages, setvenuImages] = useState([])
      const [locationNames, setlocationNames] = useState([]);
      const [sublocationNames, setsublocationNames] = useState([]);
      const maxNumber = 69;
      const [selectedLocation, setSelectedLocation] = useState("")
      const [selectedsubLocation, setSelectedsubLocation] = useState("")
      const [currentSubLocations, setcurrentSubLocations] = useState([])
      const [analytics, setAnalytics] = useState(null);
      const [analyticsLoading, setAnalyticsLoading] = useState(true)
      const [fieldFocused, setFieldFocused] = useState(null);
      const dateRangeValue = useCPStore(state=>state.dateRangeValue)
      const setDateRangeValue = useCPStore(state=>state.setDateRangeValue)
      const [allVenueReservations, setallVenueReservations] = useState([])
      const [bookedDates, setbookedDates] = useState([])
      const [externalBookingModal, setexternalBookingModal] = useState(false);
      const [extBookingName, setextBookingName] = useState("");
      const [extBookingNumber, setextBookingNumber] = useState("");
      const [extBookingPrice, setextBookingPrice] = useState(0);
      const [extBookingDate, setextBookingDate] = useState(moment());
      const [extBookingNotes, setextBookingNotes] = useState("");
      const [listingTypes, setlistingTypes] = useState([]);
      const loggedUser = useAuthStore(state=>state.user)
      const [packageModalLoading, setpackageModalLoading] = useState(false)
      const [oldDates, setOldDates] = useState([]);
      const [dates, setDates] = useState([]);
      const windowSize = useWindowSize();
      const [owners, setowners] = useState([])
      const [selectedOwner, setselectedOwner] = useState({})
      const [masterList, setmasterList] = useState([])
      const [imgEditMode, setimgEditMode] = useState(false)
      let serverURL = url
      let history = useHistory()
      const getDaySize = () => {
        if (windowSize.width <= 600) {
          return undefined;
        }
        return 56;
      };
      const [selectedListingTypes, setselectedListingTypes] = useState(null);
      const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);
      };

      let snackbarOptions = {
        message : "Listing Updated Successfully",
        options : {
          customStyle :{ info : {'background-color' : "#957bb8", 'z-index': 200},},
           
            variant : 'info'
        },
        
    }
      let snackbarOptions2 = {
        message : "Listing Updated Successfully",
        options : {
          customStyle :{ info : {'background-color' : "#957bb8"},},
           
            variant : 'success'
        },
        
    }
    const getVenueTypes = async ()=>{
      const res = await axios.post(`${url}/listingType/read`);
      setlistingTypes(res.data);
  
  
    }
    
    const handleListingTypeCheckbox = (e , type)=>{
      if(e){
        if(selectedListingTypes.includes(type.id)){
          //do nothing
        }
        else{
          let temp = selectedListingTypes
          temp.push(type.id)
          setselectedListingTypes(temp)
        }
      }
      if(!e)
      {
        if(selectedListingTypes.includes(type.id)){
          let temp = selectedListingTypes
          let index = temp.indexOf(type.id)
          temp.splice(index, 1)
          setselectedListingTypes(temp)
        }
      }
      setupdates({...updates,listingType:selectedListingTypes})

    }
  
    
    const fetchVenue =()=>{
      if(Object.keys(currentListing).length >=1)
      {
        let result;
        setloading(true);
        setmediaLoading(true);
        axios.post(`${url}/listing/read`, {id:currentListing.id, active:true}).then((res)=>{
          ////console.log(res);
          //console.log(res.data);
          setloading(false);
          setListing(res.data);
          console.log(currentListing.listingType)
          setamenities(res.data.amenities);
    
          setisAds(res.data.isAds);
          setupdates({id:currentListing.id});
          setdiscountPerc(res.data.discountPercent);
          setweekRate(res.data.rates[0].week);
          setweekendRate(res.data.rates[0].weekend);
         // //console.log(res.data.amenities)
          setselectedAddon("");
          setSelectedPackage("");
          setSelectedLocation(res.data.location)
          setSelectedsubLocation(res.data.subLocation)
          getLocationsNames(res.data.location)
          setmasterList(res.data.gallery);
          if(res.data.blockedDates){
            setDates(res.data.blockedDates);
            setOldDates(res.data.blockedDates)
          }
          else{
            setDates([])
          }
          setvenuImages(res.data.gallery);
        
          setmediaLoading(false)
       
        })
        .catch((err)=>{
          //console.log(err);
          setloading(false);
        })

   
          axios.post(`${url}/reservation/getVenueReservations`, {venuID:currentListing.id}).then((res2)=>{
            const reservationArr = res2.data;
            setallVenueReservations(reservationArr);
            
         let datesArr = []
         reservationArr.forEach(reservation => {
            let date = reservation.reservationDate;
            date = moment(date).startOf('day');
            datesArr.push(date)
            
          
    
      
      
           
         });
         //console.log("fetched dates array")
         //console.log(datesArr)
         setbookedDates(datesArr)
         })
       
    
   

     
  
        setpackagesLoading(true);
        axios.post(`${url}/package/read`, {venu:currentListing.id}).then((res3)=>{
          setlistingPackages(res3.data);
          setpackagesLoading(false);

        })

        setaddonsLoading(true);
        axios.post(`${url}/addons/read`, {venu:currentListing.id}).then((res4)=>{
          setlistingAddons(res4.data);
          setaddonsLoading(false);

        })

        // axios.get(`https://076irtsz81.execute-api.eu-west-3.amazonaws.com/dev/backend/presigned?request_type=download`
        // ,{headers : {venues : currentListing.id + '_'}}).then((res2)=>{


        // })
        setselectedListingTypes(currentListing.listingType)
        setImages([])



        axios.post(`${url}/user/fetchOwners`).then((res)=>{
          const ownersData = res.data.map((owner)=>{
            return {label :owner.id + "_" + owner.email , value : owner.id}
          })
          setowners(ownersData);
        }).catch((err)=>{
          console.log(err);
          toast.error(err.message)
        })

        fetchAnalytics();

      }

    }

    const fetchAnalytics = () => {
      if(!dateRangeValue.startDate && !dateRangeValue.endDate)
      {
        setAnalyticsLoading(true);
        axios.post(`${url}/statistics/overview` ,{id : loggedUser.id, listing: currentListing.id}).then((response)=>{
          // console.log(response.data);
          // console.log('no dates')
          setAnalytics(response.data);
        setAnalyticsLoading(false);
      })
      }
      if(dateRangeValue.startDate && dateRangeValue.endDate)
      {
        
        setAnalyticsLoading(true);
        axios.post(`${url}/statistics/overview` ,{id : loggedUser.id, listing: currentListing.id,sDate:moment(dateRangeValue.startDate).toDate(),eDate:moment(dateRangeValue.endDate).toDate()}).then((response)=>{
        //  console.log(response.data)
        //   console.log('dates');
          setAnalytics(response.data);
        setAnalyticsLoading(false);
      })
      }
    }

      useEffect(fetchVenue,[currentListing])
      useEffect(fetchAnalytics,[dateRangeValue,currentListing])
      useEffect(()=>{
        getVenueTypes();
      } , [])
      useEffect(() => {
        if(Listing){
        let temp = Listing.listingType.map((l)=>{return l.id})
        setselectedListingTypes(temp)}
      }, [Listing]);

      useEffect(() => {
        console.log(updates)
      }, [updates]);

      

    const tabs = [
        { name: 'General', href: '#', current: true },
        { name: 'Pricing', href: '#', current: false },
        { name: 'Amenities', href: '#', current: false },
        { name: 'Media', href: '#', current: false },
        { name: 'Packages', href: '#', current: false },
        { name: 'Addons', href: '#', current: false },
        { name: 'Analytics', href: '#', current: false },
        { name: 'Calendar', href: '#', current: false },
        { name: 'Admin', href: '#', current: false },
      ]



      const publishingOptions = [
        { name: 'Active', description: 'This job posting can be viewed by anyone who has the link.', current: true },
        { name: 'Inactive', description: 'This job posting will no longer be publicly accessible.', current: false },
      ]


      const calculateDiscount  = ()=>{
        let weekRateAfterDisc = weekRate;
        let weekendRateAfterDisc = weekendRate;

        if(discountPerc!==0){
          weekRateAfterDisc = weekRate - parseInt(discountPerc/100 * weekRate);
          weekendRateAfterDisc = weekendRate - parseInt(discountPerc/100 * weekendRate);
          //console.log(weekRateAfterDisc)


        }
        let arr = [];
        arr.push(weekRateAfterDisc);
        arr.push(weekendRateAfterDisc);

        setcalculatedWeekRate(weekRateAfterDisc);
        setcalculatedWeekendRate(weekendRateAfterDisc);

        return arr;
        
      }

      useEffect(()=>{
        calculateDiscount();
      }, [weekRate,weekendRate,discountPerc])

      

      useEffect(async () => {
        setamenitiesLoading(true);

        axios.post(`${url}/amenities/read`).then((res)=>{
          //(res);
          ////console.log(res.data);
          const amenitiesArr = res.data.filter((amenity)=>{
            return (amenity.name!=="Gym Equipped" && amenity.name!=="Yoga Mats" && amenity.name!=="Shower")
          })
          setfetchedAmenities(amenitiesArr);
          setamenitiesLoading(false)
          ////console.log(amenitiesArr)
        })
        .catch((err)=>{
          setamenitiesLoading(false)

        })
      

        
        },[Listing])


        const handleCheckbox = (e,item)=>{
          //(e,item)
          if(e && !checkIfAmenityIncluded(item, amenities))
          {
            let amenitiesArray  =[];
            amenitiesArray = amenities.filter(amenity => {return true});
            amenitiesArray.push(item)
            setamenities(amenitiesArray)
            ////console.log(amenities)
          
          }
          if(!e && checkIfAmenityIncluded(item, amenities))
          {
            let amenitiesArray = amenities;
            amenitiesArray = amenitiesArray.filter((amenity)=>{
              return amenity.id!== item.id
            })
        
            setamenities(amenitiesArray)
      
          }
        



         
        }

        useEffect(()=>{

          let amenitiesIndicees = [];
          for (let i = 0; i < amenities.length; i++) {
            const element = amenities[i];
            amenitiesIndicees.push(element.id);
            
          }
          setupdates({...updates,amenities:amenitiesIndicees});

        },[amenities])

        const checkIfAmenityIncluded = (amenity, arrayOfAmenities)=>{
          for (let i = 0; i < arrayOfAmenities.length; i++) {
            const element = arrayOfAmenities[i];
            if(element.id === amenity.id)
            return true;
            
          }
          return false
        }

        const renderAmenities = (data, handler) => {
          const list1 = data.filter((_, i) => i < data.length / 2);
          const list2 = data.filter((_, i) => i >= data.length / 2);
          return (
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col space-y-5">
                {list1.map((item) => (
                  <Checkbox
                    key={item.id}
                    name={item.name}
                    label={item.name}
                    defaultChecked= {checkIfAmenityIncluded(item, amenities)}
                    onChange={function (e) {handler(e,item)}}
                  />
                ))}
              </div>
              <div className="flex flex-col space-y-5">
                {list2.map((item) => (
                  <Checkbox
                    key={item.id}
                    name={item.name}
                    label={item.name}
                    defaultChecked= {checkIfAmenityIncluded(item, amenities)}
                    onChange={function (e) {handler(e,item)}}
                  />
                ))}
              </div>
            </div>
          );
        };

        const arraysEqual = (a, b) => {
          if (a.length !== b.length) {return false};                
          for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
          }
          return true;
        }


        const submitUpdates = async ()=>{
          setsaveLoading(true)
          handleActivity("Save Venu");
          if(updates.location && !updates.subLocation){
            toast.error("Need to supply sub location " , toastOptions)
            setsaveLoading(false)

          }
          else{
            if(loggedUser.rank!=="Admin" && !loggedUser.superAdmin){
              let keys = Object.keys(updates);
              if(keys.length==2 && keys.includes('id') && keys.includes('blockedDates') ){
                if(arraysEqual(oldDates.length,updates.blockedDates.length)){
                const res = await axios.post(`${url}/listing/update` , {blockedDates: updates.blockedDates, id:currentListing.id})
                toast.success("Venue calender updated successfully");
                }
                else{
                  toast.info("Nothing new to save");
                }
              }
              else{
                const res = await axios.post(`${url}/request/create` , {...updates,id:currentListing.id ,senderID:loggedUser.id,date:moment()});
                toast.success("Venue update request sent");
              }

            }
            else{
              const res = await axios.post(`${url}/listing/update` , {...updates, id:currentListing.id})
              const res2 = await axios.post(`${url}/request/create` , {...updates,id:currentListing.id ,senderID:loggedUser.id,archive:true,date:moment()});
              toast.success("Venue updated successfully");

            }
            setsaveLoading(false)
            fetchVenue();
          }


        }


        const deletePackage = async(packageID)=>{
          setpackageDeleteionLoading(true)
          const res = await axios.post(`${url}/package/delete` , {id:packageID});
          ////console.log(res.data);
          toast.success("Package deleted");
          setpackageDeleteionLoading(false)
          fetchVenue();

        }


        useEffect(async ()=>{
          if(SelectedPackage!==""){
            const res = await axios.post(`${url}/package/read`, {id:SelectedPackage});
            setfetchedPackage(res.data);
            ////console.log("package")
            ////console.log(res.data);
            setpackageBulletPoints(res.data.desc);
            setupdatedPackageBulletPoints(res.data.desc);
            setpackageName(res.data.name);
            setpackagePrice(res.data.price);
            setPackagesModalOpen(true);
          }
         

          
  
        },[SelectedPackage])

        useEffect(async ()=>{
          if(selectedAddon!=="")
          {
              const res = await axios.post(`${url}/addons/read`, {id:selectedAddon});
          setfetchedAddon(res.data);
          ////console.log("addon")
          ////console.log(res.data);
          setaddonName(res.data.name);
          setaddonPrice(res.data.price);
          setaddonDesc(res.data.desc);
          setaddonNote(res.data.notes);
          setaddonFixed(res.data.fixedAmount);
          setAddonsModal(true);

          }
        

          
  
        },[selectedAddon])


        const handlePackageBulletPoints = (bulletPointText , index)=>{
          let tempArr = updatedPackageBulletPoints;
          tempArr[index] = bulletPointText;

          setupdatedPackageBulletPoints(tempArr);
          ////console.log(updatedPackageBulletPoints);

        }

        const expandBulletPoints = ()=>{
          let tempArr1 = [];
          if(packageBulletPoints!==[] || !packageBulletPoints)
           tempArr1= packageBulletPoints.filter((pack)=>{return true});
           else
           tempArr1.push("")
          for(let i = 0 ; i<moreBulletPoints ; i++)
          {
            tempArr1.push("");
          }
          setpackageBulletPoints(tempArr1);
          // for(let i = 0 ; i<moreBulletPoints ; i++)
          // {
          //   tempArr2.push("");
          // }
          setmoreBulletPoints(0);

        }

        const updatePackage = async() =>{
          const packageID = SelectedPackage;
          let packageObj = {};
          packageObj.name = packageName;
          packageObj.price = packagePrice;
          packageObj.desc = updatedPackageBulletPoints;
          packageObj.id = packageID;
          packageObj.venu = currentListing.id;
          packageObj.tDate = moment();
      
        
          const res = await axios.post(`${url}/package/update`, packageObj );
          ////console.log(res.data);
            toast.success("Package updated");
          fetchVenue();




        }


        const prepareForNewPackage = ()=>{
          setSelectedPackage(true);
          setpackageName("");
          setpackagePrice("");
          setpackageBulletPoints([]);
          setupdatedPackageBulletPoints([]);
          setTimeout(() => {
            setPackagesModalOpen(true);
          }, 500);
        }


        const createPackage = async()=>{
          let packageObj = {};
          packageObj.name = packageName;
          packageObj.price = packagePrice;
          packageObj.desc = updatedPackageBulletPoints;
          packageObj.venu = currentListing.id;
          packageObj.tDate = moment();
          const res = await axios.post(`${url}/package/create`, packageObj );
          ////console.log(res.data);
          toast.success("Package created");
          fetchVenue();
          setmoreBulletPoints(0);

        }


        const activateVenue = async()=>{
          setsaveLoading(true);
          if(loggedUser.rank !=="Admin" && !loggedUser.superAdmin){
            // console.log(loggedUser.rank)
            const res = await axios.post(`${url}/request/create` , {id:currentListing.id ,senderID:loggedUser.id, type:'Activation',date:moment()});
            toast.success("Activation request sent");
          }
          else{
            const res = await axios.post(`${url}/listing/update` , {id:currentListing.id , active : true});
            const res2 = await axios.post(`${url}/request/create` , {id:currentListing.id ,senderID:loggedUser.id, type:'Activation',archive:true,date:moment()});
            toast.success("Venue activated successfully");

          }
          // console.log(res);
          setsaveLoading(false)
          fetchVenue();
        }

        const deactivateVenue = async()=>{
          if(loggedUser.rank !== "Admin" && !loggedUser.superAdmin){
          const res = await axios.post(`${url}/request/create` , {id:currentListing.id ,senderID:loggedUser.id, type:"Deactivation",date:moment()});
          // console.log(res);
          toast.success("Deactivation request sent");
          }
          else {
            const res =  await axios.post(`${url}/listing/update` , {id :currentListing.id, active : false});
            const res2 = await axios.post(`${url}/request/create` , {id:currentListing.id ,senderID:loggedUser.id, type:"Deactivation",archive:true,date:moment()});
            toast.success("Deactivated venue successfully");
          }
          fetchVenue();
        }


        const prepareForNewAddon = ()=>{
          setaddonName("");
          setaddonDesc("");
          setaddonPrice("");
          setaddonName("");
          setaddonNote("");
          setaddonFixed("");
          setTimeout(() => {
            setAddonsModal(true);
          }, 500);

        }

        const deleteAddon = async(addonid)=>{
          const res = await axios.post(`${url}/addons/delete` , {id:addonid});
          //console.log(res.data);
          toast.success("Addon deleted");
          setselectedAddon("");
          fetchVenue();

        }




        const updateAddon = async()=>{
          const addonID = selectedAddon;
          let addonObj = {};
          addonObj.name = addonName;
          addonObj.price = addonPrice;
          addonObj.desc = addonDesc;
          addonObj.notes = addonNote;
          addonObj.id = addonID;
          addonObj.venu = currentListing.id;
          addonObj.fixedAmount = addonFixed;
      
        
          const res = await axios.post(`${url}/addons/update`, addonObj );
          //console.log(res.data);
          toast.success("Addon updated");
          setselectedAddon("");
          fetchVenue();

        }

        const createAddon = async()=>{
          let addonObj = {};
          addonObj.name = addonName;
          addonObj.price = addonPrice;
          addonObj.desc = addonDesc;
          addonObj.venu = currentListing.id;
          addonObj.notes = addonNote;
          addonObj.fixedAmount = addonFixed;


          const res = await axios.post(`${url}/addons/create`, addonObj );
          //console.log(res.data);
          toast.success("Addon created");
          fetchVenue();

        }


        const getSignedURL = async(imageName)=>{
          const res = await axios.get(`https://076irtsz81.execute-api.eu-west-3.amazonaws.com/dev/backend/presigned/${Listing.id}_?request_type=upload` , {headers : {filenames : {imageName}}});
          //console.log(res.data)
          return res.data;

        }

        const uploadPhotos = async ()=>{
          setuploading(true);
          images.forEach(async image => {
            //console.log(image);
            let fileName = image.file.name;
            let fileData = image.data_url;
            console.log(`${mediaURL}/${currentListing.id}_`)
            console.log(fileName)
            let config = {

            }
            const res2 = await axios.post(`${mediaURL}/${currentListing.id}_` , {} , {headers:{filenames: fileName,}} );
            let data =  res2.data[0]
            //console.log(data);
            let url = data.Data.url;
            //console.log(url)
            let fields = data.Data.fields;
            let formdata = new FormDataa();
            formdata.append("data" , fields);
            formdata.append("file" , image.data_url)
            formdata.append("test" , "hey")
            let payload = {data : fields,
              file : image.file}; 
              //console.log(payload)
              //console.log("formdata")
              //console.log(formdata)
              // try {
              //   const res = await axios.post(url , payload )
              //   //console.log(res)
              // } catch (error) {
              //   //console.log(error)
              // }
              let blobdata = new Blob([new Uint8Array(image.data_url)] , {type : 'image/jpeg'})
              // try {
              //   //console.log(payload)

                
              //   const result = await fetch( url,{
              //     method : 'POST',
              //     body : payload,
              //     headers: {
              //       'Content-Type': 'multipart/form-data'
              //                       }

                  
              //   })
                
              // } catch (error) {
                
              // }


              // create a form obj
                const formData = new FormData();

                // append the fields in presignedPostData in formData            
                Object.keys(fields).forEach(key => {
                              formData.append(key, fields[key]);
                            });           

                // append the file
                formData.append("file", image.file);

                // post the data on the s3 url
                axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                }              
                }).then(async function (response) {
                  console.log(response);
                  setuploading(false);
                  const res = await axios.post(`${serverURL}/listing/cacheImagesNewCDN`)
                  console.log(res)
                  toast.success("Image uploaded successfully, will be reflected in 1-2 minutes")
                  fetchVenue();
                  })
                  .catch(function (error) {
                    console.log(error);
                    setuploading(false)

                });            
                    

            
          });
        }
        



        const cacheImages =async ()=>{
          setsaveLoading(true)
          const res = await axios.post(`${url}/listing/cache`);
          ////console.log(res.data);
          toast.success("Images cached");
          setsaveLoading(false)
          fetchVenue();
        }



        const getLocationsNames = async(locationSuppled)=>{
           axios.post(`${url}/location/read`).then((res)=>{
            let locationNamesArr = []
            let sublocationNamesArr = [];
            let sublocations2D = [[]];
            let j = 0;
            res.data.forEach(location => {
              locationNamesArr.push(location.name);
              for(let i = 0 ; i<location.cities.length ; i++)
              {
                sublocationNamesArr.push(location.cities[i]);
              }
              sublocations2D[j] = sublocationNamesArr;
              sublocationNamesArr = []
              if(location.name === locationSuppled){
                setcurrentSubLocations(sublocations2D[j])
              }
              j++

            });
            setlocationNames(locationNamesArr);
            setsublocationNames(sublocations2D);
        
            console.log(sublocations2D);



           })




      
      
        }


      const getLocationIndex = (locationName)=>{
        for (let i = 0; i < locationNames.length; i++) {
          const location = locationNames[i];
          if(location === locationName) return i;
          
        }
      }

      const handleActivity = (name,extra) =>{
        let vID = Listing
        if(name === 'Venu Tabs')
        {
          axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Venu Tabs',url:location, extraInfoType:'Tab Name', extraInfo:extra}).then((res)=>{
          });
        }
        if(name === 'Refresh Venu')
        {
          axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Refresh Venu',url:location, extraInfoType:'Venu ID', extraInfo:vID }).then((res)=>{
          });
        }
        if(name === 'View Venu')
        {
          axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'View Venu',url:location, extraInfoType:'Venu ID', extraInfo:vID}).then((res)=>{
          });
        }
        if(name === 'Save Venu')
        {
          axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Save Venu',url:location, extraInfoType:'Venu ID', extraInfo:vID}).then((res)=>{
          })
        }
      }
        const handleLocationChange = (location)=>{
          setSelectedLocation(location);
          setupdates({...updates , location : location})
          console.log(updates)
          getLocationsNames(location)
          setSelectedsubLocation("")

        }

        const handleSubLocationChange = (subLocation) =>{
          setSelectedsubLocation(subLocation);
          setupdates({...updates , subLocation : subLocation})
          console.log(updates)
        }


        function handleDateChange(date) {
          date = moment(date)
          const wasPreviouslyPicked = dates.some((d) => moment(d).isSame(date,'day'));
          if (wasPreviouslyPicked) {
            setDates((previousDates) => previousDates.filter((d) => !moment(d).isSame(date,'day')));
          } else {
            setDates((previousDates) => [...previousDates, date]);
          }
      
        }


        useEffect(()=>{
          setupdates({...updates , blockedDates : dates});

        },[dates])



        const createExtBooking = ()=>{
          setsaveLoading(true);
          let dateConflict = false;
          bookedDates.forEach(date => {
            if(moment(date).isSame(moment(extBookingDate) , 'day')){
              dateConflict =true;
            }

            
          });
          if(dateConflict){
            toast.warning("Cannot create an external booking on a date where a booking already exist");
            setsaveLoading(false);
            return;
          }
          let resInfo = {
            id:'EXT-'+ uuidv4(),
            venuID : currentListing.id,
            reservationDate : extBookingDate,
            expectedPaymentAmount: extBookingPrice,
            message : extBookingNotes,
            name : extBookingName,
            phoneNumber : extBookingNumber,
            source : "External",
            approved : true

          }

          axios.post(`${url}/reservation/create`, resInfo).then((res)=>{
            const reservation = res.data;
            console.log(res.data)
            //setobtainedReservation(res.data);
            //setbookingSuccess(true)
            //setbookingLoading(false)
            setextBookingNotes("")
            setextBookingName("")
            setextBookingNumber("")
            setextBookingDate(moment());
            setextBookingPrice(0);

            setsaveLoading(false);
            fetchVenue()
            toast.success("External Booking added successfully! thanks for booking =) ");

            axios.post(`${url}/statistics/bookingsInc` , {id : res.data.id , listing : currentListing.id}).then((res2)=>{
              console.log("submitted A Booking");
            })

            axios.post(`${url}/statistics/revenueInc` , {listing : currentListing.id , id : res.data.id}).then((res2)=>{
              console.log("added an approved analytics");
            })
            .catch((err)=>{
              console.log(err)
            })
            

  
     


            
          })
          setsaveLoading(false);

        }



        const clonePackage = async(packageToBeCloned)=>{
          let packageObj = {};
          packageObj.name = packageToBeCloned.name + " Copy";
          packageObj.price = packageToBeCloned.price;
          packageObj.desc = packageToBeCloned.desc;
          packageObj.venu = packageToBeCloned.venu;
          packageObj.tDate = moment();
          const res = await axios.post(`${url}/package/create`, packageObj );
          ////console.log(res.data);
          toast.success("Package Cloned Successfully");
          fetchVenue();
          setmoreBulletPoints(0);
        }

        const assignListingToOwner = ()=>{
          axios.post(`${url}/user/assignListingToOwner` , {user:selectedOwner.value , listing : currentListing.id}).then((res)=>{
            toast.success('Listing assigned to user');
          })
          .catch((err)=>{
            toast.error(err.message);
          })
        }
        



        const applyDrag = (arr, dragResult) => {
          const { removedIndex, addedIndex, payload } = dragResult;
          if (removedIndex === null && addedIndex === null) return arr;
        
          const result = [...arr];
          let itemToAdd = payload;
        
          if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
          }
        
          if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
          }
        
          return result;
        };


        const dropImgs = (e)=>{
          let imgsArr = applyDrag(venuImages, e)
          setvenuImages(imgsArr);
          setupdates({...updates , gallery : imgsArr})
        }

        
    
    return (
      <div>
        {!Listing && (
          <div className="max-w-3xl justify-center mt-1/2 mx-auto sm:px-6 lg:px-8 ">
            <NcImage className="w-auto md:my-16 lg:h-[640px]" src={selectConcept} />
          </div>
        )}
        {loading && <BlockLoader />}
        {!loading && Listing && (
          <Animate to="1" from="0" attributeName="opacity">

          <article className='max-h-screen overflow-y-scroll '>
            {/* Profile header */}
            <div>
              <div>
                {venuImages && <img
                  className="h-32 w-full object-cover lg:h-32"
                  src={venuImages[0]}
                  alt=""
                />}
              </div>
              <header className="bg-gray-50 py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                  <div className="flex-1 min-w-0">
                    <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      {Listing.id}_{Listing.name}
                    </h1>
                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                      {/* <div className="mt-2 flex items-center text-sm text-gray-500">
                        <EyeIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {Listing.views} Views
                      </div> */}
                      {/* <div className="mt-2 flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {Listing.pressedOnBookNow} Book Now
                      </div> */}
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <LocationMarkerIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {Listing.subLocation} . {Listing.location}
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <TagIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {Listing.discountPercent}%
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyDollarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {Listing.price} EGP
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <ClockIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        /{Listing.rate}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex xl:mt-0 xl:ml-4">
                    <span className=" sm:block">
                      <Button
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm
                 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
                 focus:ring-offset-gray-50 focus:ring-primary-500 rounded-md"
                        type={"button"}
                        loading={saveLoading}
                        onClick={submitUpdates}
                        sizeClass=""
                      >
                        <SaveAsIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"  
                        />
                        Save
                      </Button>
                    </span>

                    <span className=" sm:block">
                      <Button
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm
                 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
                 focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 rounded-md"
                        type={"button"}
                        loading={saveLoading}
                        onClick={()=>{fetchVenue();handleActivity("Refresh Venu");}}
                        sizeClass=""
                      >
                        <RefreshIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"  
                        />
                        Refresh
                      </Button>
                    </span>

                    <span className=" sm:block ml-3">
                      <a
                        type="button"
                        href={`/Venu/listing-stay-detail?id=${Listing.id}`}
                        target="_blank"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                        onClick={()=>{handleActivity("View Venu");}}

                      >
                        <LinkIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        View
                      </a>
                    </span>




                  </div>
                </div>
              </header>
              <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1"></div>
              </div>
            </div>

            {/* Tabs */}
            <div className="mt-6 sm:mt-2 2xl:mt-5 overflow-x-auto">
              <div className="border-b border-gray-200">
                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <button
                        key={tab.name}
                        className={classNames(
                          currentTab === tab.name
                            ? "border-primary-500 text-gray-900"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                        onClick={() => {
                          setcurrentTab(tab.name);
                          tab.current = true;
                          handleActivity('Venu Tabs',tab.name);
                        }}
                      >
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>

            {/* Description list */}

            <div className={currentTab === "General" ? "" : "hidden"}>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-10 divide-y divide-gray-200 min-h-screen">
                  <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      General Venue Profile
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      This information will be displayed publicly so be careful
                      what you share. Keep it simple and structured to help
                      customers book easily.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Venue Name
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Enter the name of your Venue'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.name}
                              onSave={(e) => {
                                setupdates({ ...updates, name: e.value });
                              }}
                            />
                          </span>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Venue Title{" "}
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow ">
                            <EditTextarea
                              placeholder='The title of the venue that appears on the website'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.title}
                              onSave={(e) => {
                                setupdates({ ...updates, title: e.value });
                              }}
                            />
                          </span>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Venue Description{" "}
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow ">
                            <EditTextarea
                              placeholder='The venue description , you can explain how it looks, what to expect and the best suited use for it'
                              className=" border-primary-100 overflow-y-scroll rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.desc}
                              onSave={(e) => {
                                setupdates({ ...updates, desc: e.value });
                              }}
                            />
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Owner's Phone Number
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Your Phone number to contact you in case of bookings or notifications'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.phoneNumber}
                              onSave={(e) => {
                                setupdates({ ...updates, phoneNumber: e.value });
                              }}
                            />
                          </span>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          <UserIcon
                            className="ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Capacity
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Enter a number for capacity, Ex: 200'
                              type="number"
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.capacity}
                              onSave={(e) => {
                                setupdates({ ...updates, capacity: e.value });
                              }}
                            />
                          </span>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Photos
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow flex truncate ">
                            {venuImages.map((image) => {
                              return (
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={image}
                                  alt=""
                                />
                              );
                            })}
                          </span>
                          {/* <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                            <button
                              type="button"
                              className="bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                              Update
                            </button>
                            <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                            <button
                              type="button"
                              className="bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                              Remove
                            </button>
                          </span> */}
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Location
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">
                      

                            <Listbox   value={selectedLocation} onChange={handleLocationChange}>
                                  {({ open }) => (
                                    <>
                                      <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>
                                      <div className="mt-1 relative">
                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                          <span className="block truncate">{selectedLocation}</span>
                                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          enter = "transition ease-in duration-400"
                                          enterTo='opacity-100'
                                          enterFrom='opacity-0'
                                          leave="transition ease-in duration-400"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                          
                                        >
                                          <Listbox.Options  className="absolute bottom-0 z-50 mt-1 mb-5 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-visible focus:outline-none sm:text-sm">
                                            {locationNames.map((location) => (
                                              <Listbox.Option
                                                key={location}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'text-white bg-primary-500' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                  )
                                                }
                                                value={location}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {location}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active ? 'text-white' : 'text-primary-500',
                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                      >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
                          </span>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          SubLocation
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow">

                          <Listbox   value={selectedsubLocation} onChange={handleSubLocationChange}>
                                  {({ open }) => (
                                    <>
                                      <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>
                                      <div className="mt-1 relative">
                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                          <span className="block truncate">{selectedsubLocation}</span>
                                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          enter = "transition ease-in duration-400"
                                          enterTo='opacity-100'
                                          enterFrom='opacity-0'
                                          leave="transition ease-in duration-400"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                          
                                        >
                                          <Listbox.Options  className="absolute bottom-0 z-50 mt-1 mb-5 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {currentSubLocations.map((location) => (
                                              <Listbox.Option
                                                key={location}
                                                className={({ active }) =>
                                                  classNames(
                                                    active ? 'text-white bg-primary-500' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                  )
                                                }
                                                value={location}
                                              >
                                                {({ selected, active }) => (
                                                  <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {location}
                                                    </span>

                                                    {selected ? (
                                                      <span
                                                        className={classNames(
                                                          active ? 'text-white' : 'text-primary-500',
                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                      >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                      </span>
                                                    ) : null}
                                                  </>
                                                )}
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
                          </span>
                        </dd>
                      </div>



                      {loggedUser.superAdmin==true && 
                      <>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Is Advertised
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="ml-4 flex-shrink-0">
                            <Switch
                            checked={Listing.isAds}
                              onChange={(e) => {
                                setupdates({...updates, isAds: e});
                                setListing({...Listing , isAds : e})
                              }}
                              className={classNames(
                                Listing.isAds ? "bg-primary-500" : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                className={classNames(
                                  Listing.isAds ? "translate-x-5" : "translate-x-0",
                                  "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                )}
                              >
                                <span
                                  className={classNames(
                                    Listing.isAds
                                      ? "opacity-0 ease-out duration-100"
                                      : "opacity-100 ease-in duration-200",
                                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 12 12"
                                  >
                                    <path
                                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span
                                  className={classNames(
                                    Listing.isAds
                                      ? "opacity-100 ease-in duration-200"
                                      : "opacity-0 ease-out duration-100",
                                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-primary-600"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                  >
                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                  </svg>
                                </span>
                              </span>
                            </Switch>
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Most Popular?
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="ml-4 flex-shrink-0">
                            <Switch
                            checked={Listing.isPopular}
                              onChange={(e) => {
                                setupdates({...updates, isPopular: e});
                                setListing({...Listing , isPopular : e})
                              }}
                              className={classNames(
                                Listing.isPopular ? "bg-primary-500" : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                className={classNames(
                                  Listing.isPopular ? "translate-x-5" : "translate-x-0",
                                  "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                )}
                              >
                                <span
                                  className={classNames(
                                    Listing.isPopular
                                      ? "opacity-0 ease-out duration-100"
                                      : "opacity-100 ease-in duration-200",
                                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 12 12"
                                  >
                                    <path
                                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span
                                  className={classNames(
                                    Listing.isPopular
                                      ? "opacity-100 ease-in duration-200"
                                      : "opacity-0 ease-out duration-100",
                                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-primary-600"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                  >
                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                  </svg>
                                </span>
                              </span>
                            </Switch>
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <dt className="text-sm font-medium text-gray-500">
                          Featured?
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="ml-4 flex-shrink-0">
                            <Switch
                            checked={Listing.isFeatured}
                              onChange={(e) => {
                                setupdates({...updates, isFeatured: e});
                                setListing({...Listing , isFeatured : e})
                              }}
                              className={classNames(
                                Listing.isFeatured ? "bg-primary-500" : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                className={classNames(
                                  Listing.isFeatured ? "translate-x-5" : "translate-x-0",
                                  "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                )}
                              >
                                <span
                                  className={classNames(
                                    Listing.isFeatured
                                      ? "opacity-0 ease-out duration-100"
                                      : "opacity-100 ease-in duration-200",
                                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 12 12"
                                  >
                                    <path
                                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span
                                  className={classNames(
                                    Listing.isFeatured
                                      ? "opacity-100 ease-in duration-200"
                                      : "opacity-0 ease-out duration-100",
                                    "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-primary-600"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                  >
                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                  </svg>
                                </span>
                              </span>
                            </Switch>
                          </span>
                        </dd>
                      </div>
                      </>
                      }



                    </dl>
                    
                  </div>
                  <fieldset className="py-4 sm:py-5">
                    <p className="text-lg font-medium text-gray-500">Venue Type(s)</p>
                    <div className="mt-4 w-4/5 border-t border-b border-gray-200 divide-y divide-gray-200">
                      {listingTypes.map((type, typeIndex) => (
                        <div key={typeIndex} className="relative flex items-start py-4">
                          <div className="min-w-0 flex-1 text-sm ">
                            <label htmlFor={`type-${type.id}`} className="font-medium text-gray-700 select-none">
                              {type.name}
                            </label>
                          </div>
                          <div className="ml-3 flex items-center h-5">
                          <Checkbox
                            key={`type-${type.id}`}
                            name={`type-${type.id}`}
                            defaultChecked= {selectedListingTypes.includes(type.id)}
                            onChange={(e)=>{handleListingTypeCheckbox(e, type)}}
                          />
                          </div>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
              </div>
            
            </div>


            <div className={currentTab === "Pricing" ? "" : "hidden"}>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-10 divide-y divide-gray-200 min-h-screen">
                  <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Rates And Pricing
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      This Tab controls the pricing of your venue booking. Be
                      careful while editing the numbers. Make sure you enter
                      valid numbers. For decimals use fullstop instead of ','
                      Example: 1200.50
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                      {/* week and normal price rate */}
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          <CurrencyDollarIcon
                            className="ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Price
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Your regular weekday price'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.price}
                              onSave={(e) => {
                                setupdates({ ...updates, price: e.value });
                                setweekRate(e.value);
                              }}
                            />
                          </span>
                          <span
                            className="ml-4 flex items-start space-x-4"
                            title="Please note that the pricing reflects automatically on the normal week day price."
                          >
                            <QuestionMarkCircleIcon
                              className="ml-1 mr-2 h-5 w-5 text-gray-400 hover:text-primary-200"
                              aria-hidden="true"
                            />
                          </span>
                        </dd>
                      </div>

                      {/* weekend price rate */}

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          <CurrencyDollarIcon
                            className="ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Weekend Rate
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Your weekend price for Fridays and Saturdays'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.rates[0].weekend}
                              onSave={(e) => {
                                setupdates({ ...updates, weekend: e.value });
                                // console.log(updates)
                              }}
                            />
                          </span>
                          <span className="ml-4 flex items-start space-x-4"></span>
                        </dd>
                      </div>

                      {/* discount % */}

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          <TagIcon
                            className="ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Discount Percentage %
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Discount rate, Ex : 10'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.discountPercent + " "}
                              onSave={(e) => {
                                setupdates({
                                  ...updates,
                                  discountPercent: e.value,
                                });
                                setdiscountPerc(e.value);
                              }}
                            />
                          </span>
                          <span className="ml-4 flex items-start ">
                            Calculated Discount :
                            <CurrencyDollarIcon
                              className="ml-1 mr-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {calculatedWeekRate} -{" "}
                            <CurrencyDollarIcon
                              className="ml-1 mr-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />{" "}
                            {calculatedWeekendRate}
                          </span>
                        </dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          <CalendarIcon
                            className="ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Discount Expiration Date
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='Select an expiration date for the timer'
                              className=" border-primary-100  rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              id='expireDate'
                              type="date"
                              defaultValue={
                                (Listing.discountExpire && moment(Listing.discountExpire).year() >1980)?
                                moment(
                                  Listing.discountExpire
                                ).toLocaleString() + " " : "undefined"
                              }
                              onSave={(e) => {
                                setupdates({
                                  ...updates,
                                  discountExpire: e.value,
                                });
                              }}
                            />
                          </span>
                          <span >
                                <ButtonClose onClick={()=>{updates.discountExpire = new Date(0); submitUpdates() }} className='w-8 h-8'/>
                          </span>
                        </dd>
                      </div>

                      {/* rate */}

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          <ClockIcon
                            className="ml-1 mr-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Rate{" "}
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow truncate">
                            <EditText
                              placeholder='The rate your charge with, Ex : Day'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.rate}
                              onSave={(e) => {
                                setupdates({ ...updates, rate: e.value });
                              }}
                            />
                          </span>
                          <span className="ml-4 flex items-start space-x-4"></span>
                        </dd>
                      </div>

                      <div className="py-4 mt-4 mb-8 sm:py-5 w-full items-center flex flex-col">
                         <dt className="text-lg z-0 mb-4 font-medium text-gray-500 flex">
                          <CurrencyDollarIcon
                            className="ml-1 mr-2 h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                          Payment Terms{" "}
                        </dt>
                        <dd className="mt-4 block text-sm w-full text-gray-900 sm:mt-0 ">
                          <TOSSection/>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className={currentTab === "Amenities" ? " " : "hidden"}>
              {amenitiesLoading && <BlockLoader/>}
              {!amenitiesLoading && <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-grow overflow-y-auto">
                  <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800 min-h-screen">
                    <div className="py-7">
                      <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Amenities
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          Adjust the amenities that your venue provides to
                          customers.
                        </p>
                      </div>

                      <div className="mt-6 relative mb-6 ">
                        {renderAmenities(fetchedAmenities, handleCheckbox)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>

            <div className={currentTab === "Media" ? " " : "hidden"}>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-grow overflow-y-auto">
                  <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800 min-h-screen">
                    <div className="py-5">
                      <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Media Photos
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          Media Gallery for the venue. You can upload and view
                          images/media for your venue. All the media you see are
                          publically shared so please be careful of what the
                          media might contain.
                        </p>
                      </div>
                     {
                      loggedUser.rank !== 'Admin' ?
                      (
                      <div className="mt-6 relative mb-6 ">
                      <button
                        type="button"
                        onClick={async() => {
                            const res = await axios.post(`${url}/request/create` , {id:currentListing.id ,senderID:loggedUser.id, type:"Media",date:moment()});
                            toast.success("Venue add media request sent");
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm"
                      >
                       Request to add/edit media
                      </button>
                      </div>
                      )
                      :
                      (<div className="mt-6 relative mb-6 ">
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                                                 <button
                          type="button"
                          className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                            />
                          </svg>
                          <span className="mt-2 block text-sm font-medium text-gray-900">
                            Add New Media
                          </span>
                        </button>

                            &nbsp;
                            <Button
                            className=" ml-2 mr-2 mt-5 mb-5 inline-flex items-center px-4 py-2 border border-red-300 shadow-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                             onClick={onImageRemoveAll}>Remove all images</Button>
                            <Button
                            className=" ml-2 mr-2 mt-5 mb-5 inline-flex items-center px-4 py-2 border border-green-300 shadow-sm font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                             onClick={uploadPhotos}>Upload all images</Button>
                            <Button
                            className=" ml-2 mr-2 mt-5 mb-5 inline-flex items-center px-4 py-2 border border-purple-300 shadow-sm font-medium rounded-md text-purple-700 bg-purple-200 hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm"
                             onClick={()=>setimgEditMode(!imgEditMode)}> {imgEditMode ? "Disable Edit Mode" : "Enable Edit Mode"}  </Button>

                            <div className='flex '>
                            {uploading && <BlockLoader/>}
                            </div>
                            <div className='flex flex-wrap'>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <ModalImage
                                    large = {image.data_url}
                                    small={image.data_url}
                                     className="w-auto  bg-gray-200 ml-2 mr-2  rounded-md lg:h-40  group-hover:opacity-75  "
                                  />
                                <div className="image-item__btn-wrapper w-full flex justify-center">
                                  {/* <button
                                  className="px-4 py-2 border border-gray-300 shadow-sm text-sm
                                  font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
                                   focus:ring-offset-gray-50 focus:ring-primary-500 rounded-md"
                                  onClick={() => onImageUpdate(index)}>Update</button> */}
                                  <button
                                  className=" ml-2 mr-2 mt-5 mb-5 inline-flex items-center px-4 py-2 border border-red-300 shadow-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"

                                   onClick={() => onImageRemove(index)}>Remove</button>
                                </div>
                              </div>
                            ))}
                          
                            </div>

                          
                          </div>
                        )}
                        </ImageUploading>
                      </div>)}
                         {imgEditMode && <div className="overflow-x-auto ">
                        <Container  autoScrollEnabled behaviour='move' orientation='horizontal' onDrop={e => dropImgs(e)}  >
                         {venuImages.map((image) => (
                          <Draggable className=' ' key={image}>
                           <div key={image} className="group w-48 relative mr-4 ml-4">
                               <ModalImage
                                 large = {image}
                                 small={image}
                                  className="w-full  bg-gray-200  rounded-md lg:h-40 aspect-h-1  group-hover:opacity-75  lg:aspect-none"
                               />
                             <div className="mt-4 flex justify-between">
                               <div>
                                 <h3 className="text-sm text-gray-700">

                                 </h3>
                                 {/* <p className="mt-1 text-sm text-gray-500">{"xyz"}</p> */}
                               </div>
                               {/* <p className="text-sm font-medium text-gray-900">{"xyz"}</p> */}
                             </div>
                           </div>
                           </Draggable>

                         ))}
                         </Container>

                       </div>   }  


                       {!imgEditMode && <div className='mt-8 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2  border-t-2 pt-6 border-gray-200 lg:grid-cols-4 xl:gap-x-8'>
                       {venuImages.map((image) => (
                           <div key={image} className="group w-48 relative mr-4 ml-4">
                               <ModalImage
                                 large = {image}
                                 small={image}
                                  className="w-full  bg-gray-200  rounded-md lg:h-40 aspect-h-1  group-hover:opacity-75  lg:aspect-none"
                               />
                             <div className="mt-4 flex justify-between">
                               <div>
                                 <h3 className="text-sm text-gray-700">

                                 </h3>
                                 {/* <p className="mt-1 text-sm text-gray-500">{"xyz"}</p> */}
                               </div>
                               {/* <p className="text-sm font-medium text-gray-900">{"xyz"}</p> */}
                             </div>
                           </div>

                         ))}
                       </div>}
      
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className={currentTab === "Packages" ? " " : "hidden"}>
              {packagesLoading && <BlockLoader/>}
              {!packagesLoading && <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-grow overflow-y-auto">
                  <div className="px-2 divide-y divide-neutral-200 dark:divide-neutral-800 min-h-screen">
                    <div className="py-7">
                      <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Packages
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          Add/Delete/Edit packages for booking.
                        </p>
                      </div>

                      <div className="mt-6 relative mb-6 ">
                        <div className="bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:p-6">
                            <div className="flex justify-between align-middle">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Add New Package
                              </h3>
                              <button
                                type="button"
                                sizeClass=""
                                onClick={() => {
                                  setdisableUpdate(true);
                                  setdisableCreate(false);
                                  prepareForNewPackage();
                                }}
                                className="shadow-sm inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                              >
                                New{" "}
                                <PlusCircleIcon className="h-4 w-4 ml-2 mr-2" />
                              </button>
                            </div>

                            <div className="mt-2  text-sm text-gray-500">
                              <p>
                                Follow the adding package form in order to find
                                your newly created packages here
                              </p>
                            </div>
                            <div className="mt-5 flex"></div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Current Packages
                          </h3>
                          <div className="mt-5">
                            {listingPackages.map((sPackage) => {
                              return (
                                <div className="rounded-md bg-gray-50 px-6 py-5 mt-3 sm:flex sm:items-start sm:justify-between">
                                  <div className="sm:flex sm:items-start">
                                    <div
                                      className="h-8 w-auto sm:flex-shrink-0 sm:h-6"
                                      viewBox="0 0 36 24"
                                      aria-hidden="true"
                                    >
                                      <NcImage
                                        className="w-8 h-8 "
                                        src={packagePNG}
                                      />
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-4">
                                      <div className="text-sm font-medium text-gray-900">
                                        {sPackage.name}
                                      </div>
                                      <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                        <div>{sPackage.price} EGP</div>
                                        <span
                                          className="hidden sm:mx-2 sm:inline"
                                          aria-hidden="true"
                                        >
                                          &middot;
                                        </span>
                                        <div className="mt-1 sm:mt-0">
                                          Last updated on{" "}
                                          {moment(sPackage.tDate).toISOString()}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                                    <Button
                                      type="button"
                                      sizeClass=""
                                      onClick={() => {
                                        setSelectedPackage(sPackage.id);
                                        setdisableCreate(true);
                                        setdisableUpdate(false);
                                        
                                      }}
                                      className=" ml-2 mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      type="button"
                                      sizeClass=""
                                      loading={packageDeleteionLoading}
                                      onClick={() => {
                                        deletePackage(sPackage.id);
                                      }}
                                      className=" ml-2 mr-2 inline-flex items-center px-4 py-2 border border-red-300 shadow-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      type="button"
                                      sizeClass=""
                                      loading={packageDeleteionLoading}
                                      onClick={() => {
                                        clonePackage(sPackage);
                                      }}
                                      className=" ml-2 mr-2 inline-flex items-center px-4 py-2 border
                                       border-purple-300 shadow-sm font-medium rounded-md text-purple-700
                                        bg-purple-50 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:purple-red-500 sm:text-sm"
                                    >
                                      Copy
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>

            <div className={currentTab === "Admin" ? "" : "hidden"}>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-grow overflow-y-auto">
                  <div className="px-2 divide-y divide-neutral-200 dark:divide-neutral-800 min-h-screen">
                    <div className="py-7">
                      <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Administration Area
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          Control the activity status of the venue. Please
                          proceed with caution.
                        </p>
                      </div>

                      <div className="bg-white shadow sm:rounded-lg mt-4">
                        <div className="px-4 py-5 sm:p-6">
                          <div className="sm:flex sm:items-start sm:justify-between">
                            <div>
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Manage Activity
                              </h3>
                              <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>
                                  You can activate or deactivate your venue. If
                                  your venue is inactive it will not appear in
                                  searches or on the website in general.
                                </p>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                              {Listing.active && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    deactivateVenue();
                                  }}
                                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange-300 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm"
                                >
                                  {loggedUser.rank === "Admin"?"Deactivate venue" :"Request to deactivate venue"}
                                </button>
                              )}
                              {!Listing.active && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    activateVenue();
                                  }}
                                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                                >
                                  {loggedUser.rank === "Admin"?"Activate venue" :"Request to activate venue"}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      
                      {loggedUser.rank=="Admin" && <div className="bg-white shadow sm:rounded-lg mt-4"> 
                        <div className="px-4 py-5 sm:p-6">
                          <div className="sm:flex sm:items-start sm:justify-between">
                            <div>
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Assign Listing To an Owner
                              </h3>
                              <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>
                                  You assign an already existing listing to an owner account, select the account then press the Assign button
                                  
                                </p>
                                <Select
                                className='w-2/3 mt-2  '
                                blurInputOnSelect
                        defaultValue={selectedOwner}
                        onChange={setselectedOwner}
                        options={owners}
                        isClearable = {true}
                        /> 
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                         
                           
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    assignListingToOwner();
                                  }}
                                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                                >
                                  Assign
                                </button>
                              
                            </div>
                          </div>
                        </div>
                      </div>}


                      {loggedUser.rank=="Admin" && <div className="bg-white shadow sm:rounded-lg mt-4"> 
                        <div className="px-4 py-5 sm:p-6">
                          <div className="sm:flex sm:items-start sm:justify-between">
                            <div>
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Manage Image Cache
                              </h3>
                              <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>
                                  You can cache / re-cache images using this command. Please do not use unless you're on the tech team.
                                  
                                </p>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                         
                           
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    cacheImages();
                                  }}
                                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                                >
                                  Cache Images
                                </button>
                              
                            </div>
                          </div>
                        </div>
                      </div>}

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={currentTab === "Addons" ? "" : "hidden"}>
              {addonsLoading && <BlockLoader/>}
             {!addonsLoading && <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-grow overflow-y-auto">
                  <div className="px-2 divide-y divide-neutral-200 dark:divide-neutral-800 min-h-screen">
                    <div className="py-7">
                      <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Addons
                        </h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                          Add/Delete/Edit addons that are use supplementary with
                          booking.
                        </p>
                      </div>

                      <div className="py-4 sm:py-5 mt-3 border-t-2 border-b-2 border-gray-200 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Default Addons Text
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <span className="flex-grow overflow-y-auto">
                            <EditTextarea
                              placeholder='The default addons that are inclusive by default in the very base rental price'
                              className=" border-primary-100 rounded-md border-0 focus-within:border-0 focus-within:bg-slate-50  "
                              inline
                              defaultValue={Listing.addonsText}
                              onSave={(e) => {
                                setupdates({ ...updates, addonsText: e.value });
                              }}
                            />
                          </span>
                        </dd>
                      </div>

                      <div className="mt-6 relative mb-6 ">
                        <div className="bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:p-6">
                            <div className="flex justify-between align-middle">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Add New Addon
                              </h3>
                              <button
                                type="button"
                                sizeClass=""
                                onClick={() => {
                                  setdisableAddonUpdate(true);
                                  setdisableAddonCreate(false);
                                  prepareForNewAddon();
                                }}
                                className="shadow-sm inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                              >
                                New{" "}
                                <PlusCircleIcon className="h-4 w-4 ml-2 mr-2" />
                              </button>
                            </div>

                            <div className="mt-2  text-sm text-gray-500">
                              <p>
                                Follow the form of adding an addon in order to
                                create a new one. Note that{" "}
                                <span className="font-bold"> Fixed Amount</span>{" "}
                                is a flag used to identify if this addon is
                                customizable or not. If you tick on it, it means
                                the addon is fixed and can't be customized.
                              </p>
                            </div>
                            <div className="mt-5 flex"></div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Current Addons
                          </h3>
                          <div className="mt-5">
                            {listingAddons.map((addon) => {
                              return (
                                <div className="rounded-md bg-gray-50 px-6 py-5 mt-3 sm:flex sm:items-start sm:justify-between">
                                  <div className="sm:flex sm:items-start">
                                    <div
                                      className="h-8 w-auto sm:flex-shrink-0 sm:h-6"
                                      viewBox="0 0 36 24"
                                      aria-hidden="true"
                                    >
                                      <NcImage
                                        className="w-8 h-8 "
                                        src={addonIcon}
                                      />
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-4">
                                      <div className="text-sm font-medium text-gray-900">
                                        {addon.name}
                                      </div>
                                      <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                        <div>{addon.price} EGP/Unit</div>
                                        <span
                                          className="hidden sm:mx-2 sm:inline"
                                          aria-hidden="true"
                                        >
                                          &middot;
                                        </span>
                                        <div className="mt-1 sm:mt-0">
                                          {addon.fixedAmount && (
                                            <span> Fixed Addon</span>
                                          )}
                                          {!addon.fixedAmount && (
                                            <span> Customizable Addon</span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                                    <Button
                                      type="button"
                                      sizeClass=""
                                      onClick={async () => {
                                        setselectedAddon(addon.id);
                                        setdisableAddonCreate(true);
                                        setdisableAddonUpdate(false);
                                       
                                      }}
                                      className=" ml-2 mr-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      type="button"
                                      sizeClass=""
                                      loading={packageDeleteionLoading}
                                      onClick={() => {
                                        deleteAddon(addon.id);
                                      }}
                                      className=" ml-2 mr-2 inline-flex items-center px-4 py-2 border border-red-300 shadow-sm font-medium rounded-md text-red-700 bg-red-50 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>



            <div className={currentTab === "Analytics" ? "" : "hidden"}>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-10 divide-y divide-gray-200 min-h-screen">
                  <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Analytics and graphs
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      This Tab shows the analytics and performance for each venue, note that
                      this information is independant of other venues or listings your account is tied to.
                    </p>
                  </div>
                  <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                    <div className='my-4'>
                    <RentalCarDatesRangeInput
                defaultDateValue={dateRangeValue}
                defaultFocus={
                  fieldFocused === "dropOffInput" ? null : fieldFocused
                }
                onFocusChange={(focus) => setFieldFocused(focus)}
                onChange={(data) => {
                  setDateRangeValue(data.stateDate);
                }}
              />
              </div>
                      {(analyticsLoading)?<BlockLoader/>:
                      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                               <EyeIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="text-sm font-medium text-gray-500 truncate">Views</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.views ?analytics.views.toLocaleString(): 0}</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>

                      <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                               <CurrencyDollarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="text-sm font-medium text-gray-500 truncate">Sales</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.sales ?analytics.sales.toLocaleString(): 0} EGP</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>
                      {(loggedUser.rank === 'Admin') &&
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                               <CurrencyDollarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="text-sm font-medium text-gray-500 truncate">Revenue</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.revenue ?analytics.revenue.toLocaleString(): 0} EGP</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>
                      }
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                            <CalendarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Bookings</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.bookings ?analytics.bookings.toLocaleString(): 0}</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>

                      <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                               <CheckCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Conversion Rate</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.conversionRate}%</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>

                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                               <UsersIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Unique Customers</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.uVisitors ?analytics.uVisitors.toLocaleString(): 0}</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>
                  
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                               <CursorClickIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> 
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">Book Now Clicks</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{analytics.bookNowClicks ?analytics.bookNowClicks.toLocaleString(): 0}</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a href={"##"} className="font-medium text-primary-700 hover:text-primary-900">
                              View all
                            </a>
                          </div>
                        </div> */}
                      </div>

                   </div>
                   
                   }
                
                    </dl>
                  </div>
                </div>
              </div>
            </div>





            <div className={currentTab === "Calendar" ? "" : "hidden"}>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-10 divide-y divide-gray-200 min-h-screen">
                  <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Calendar and availability
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                      This Tab shows the calendar and availability of your venue. You should update this tab regularly
                      as to mark the unavailable days for your venue. This will help reduce booking collisions.
                    </p>
                  </div>
                  <div className="mt-6">
                  <div className="mt-6 relative mb-6 ">
                        <div className="bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:p-6">
                            <div className="flex justify-between align-middle">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Add New External Booking
                              </h3>
                              <button
                                type="button"
                                sizeClass=""
                                onClick={() => {
                                  setexternalBookingModal(true)
                                }}
                                className="shadow-sm inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                              >
                                New{" "}
                                <PlusCircleIcon className="h-4 w-4 ml-2 mr-2" />
                              </button>
                            </div>

                            <div className="mt-2  text-sm text-gray-500">
                              <p>
                                Follow the adding external booking form in order to keep track of your external bookings
                                done outisde our website.
                                This helps you keep track of your analytics, revenue, sales, and provides an All-in-one solution 
                                to manage your venues.
                                
                              </p>
                            </div>
                            <div className="mt-5 flex"></div>
                          </div>
                        </div>
                      </div>

                    <dl className="divide-y divide-gray-200">
                    <div className='my-4 p-10  flex justify-center' >

                          <div key={currentListing.id}  className="nc-SetYourAvailabilityData visible">
                            <DayPickerSingleDateController

                              onDateChange={(e) => e && handleDateChange(moment(e).startOf('day'))}
                              focused={true}
                              onFocusChange={console.log}
                              date={null}
                              isDayHighlighted={(day) => dates.some((d) => moment(d).isSame(moment(day), "day"))}
                              isDayBlocked = {(day)=>bookedDates.some((d)=> moment(d).isSame(moment(day), "day"))}
                              keepOpenOnDateSelect
                              daySize={getDaySize()}
                              initialVisibleMonth={null}
                              hideKeyboardShortcutsPanel
                              
                            />
                         </div>

                      </div>

                
                    </dl>
                  </div>
                </div>
              </div>
            </div>


            




            {/* // packages modal window */}

            <Transition.Root show={PackagesModalOpen} >
              <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-hidden"
                onClose={(b)=>{setPackagesModalOpen(b); setSelectedPackage("")}}
              >
                <div className="absolute inset-0 z-20 overflow-hidden">
                  <Dialog.Overlay className="absolute inset-0" />

                  <div className="fixed inset-y-0 z-20 right-0 pl-10 max-w-full flex sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                    
                      { <div className="w-screen z-20 max-w-2xl">
                        <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="px-4 py-6 bg-gray-50 sm:px-6">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-lg font-medium text-gray-900">
                                    New Package
                                  </Dialog.Title>
                                  <p className="text-sm text-gray-500">
                                    Get started by filling in the information
                                    below to create your new Package.
                                  </p>
                                </div>
                                <div className="h-7 flex items-center">
                                  <button
                                    type="button"
                                    className="text-gray-400 hover:text-gray-500"
                                    onClick={() => {setPackagesModalOpen(false);setSelectedPackage("")}}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Divider container */}
                            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              {/* Project name */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Package name
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    defaultValue={packageName}
                                    type="text"
                                    onChange={(e) => {
                                      setpackageName(e.target.value);
                                    }}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* Package pricing */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Package Pricing
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    defaultValue={packagePrice}
                                    onChange={(e) => {
                                      setpackagePrice(e.target.value);
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* Project description */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Description Points
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <button
                                    type="button"
                                    sizeClass=""
                                    onClick={() => {
                                      setPackagesModalOpen(false);
                                     

                                      expandBulletPoints();
                                      
                                      setPackagesModalOpen(true);
                                    }}
                                    className="shadow-sm inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-200 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                                  >
                                    Add more{" "}
                                    <PlusCircleIcon className="h-4 w-4 ml-2 mr-2" />
                                  </button>
                                  <input
                                    onChange={(e) => {
                                      setmoreBulletPoints(e.target.value);
                                    }}
                                    type="text"
                                    className="ml-3 w-4/12 shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {packageBulletPoints &&
                                // SelectedPackage &&
                                packageBulletPoints.map((bulletPoint, i) => {
                                  return (
                                    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                      <div>
                                  
                                        <label
                                          htmlFor="project-name"
                                          className="flex justify-between text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                                <ButtonClose className='inline-flex' onClick={(e)=>{
                                                    e.preventDefault()
                                                      let packageBps = packageBulletPoints.filter((bp)=>{return true})

                                                      packageBps.splice(i,1);
                                                      console.log(packageBps)
                                                      setpackageBulletPoints(packageBps)
                                                      setupdatedPackageBulletPoints(packageBps)

          
               

                                          }}></ButtonClose>
                                           #{i+1}
                                 
                                    
                                        </label>
                              
                                      </div>
                                      <div className="sm:col-span-2">
                                        <input
                                          type="text"
                                          defaultValue={bulletPoint}
                                          key={bulletPoint}
                                          onChange={(e) => {
                                            handlePackageBulletPoints(
                                              e.target.value,
                                              i
                                            );
                                          }}
                                          className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                        />
                                                
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                            <div className="space-x-3 flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                onClick={() => {setPackagesModalOpen(false); setSelectedPackage("")}}
                              >
                                Cancel
                              </button>
                              <Button
                                type="button"
                                sizeClass=""
                                disabled={disableUpdate}
                                onClick={() => {
                                  updatePackage();
                                  setPackagesModalOpen(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Update
                              </Button>
                              <Button
                                type="button"
                                disabled={disableCreate}
                                sizeClass=""
                                onClick={() => {
                                  createPackage();
                                  setPackagesModalOpen(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Create New
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>}
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            {/* addons modal window */}
            <Transition.Root show={AddonsModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-hidden"
                onClose={(b)=>{setAddonsModal(b); setselectedAddon("")}}
              >
                <div className="absolute inset-0 z-20 overflow-hidden">
                  <Dialog.Overlay className="absolute inset-0" />

                  <div className="fixed inset-y-0 z-20 right-0 pl-10 max-w-full flex sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <div className="w-screen z-20 max-w-2xl">
                        <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="px-4 py-6 bg-gray-50 sm:px-6">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-lg font-medium text-gray-900">
                                    New Addon
                                  </Dialog.Title>
                                  <p className="text-sm text-gray-500">
                                    Get started by filling in the information
                                    below to create your new Addon.
                                  </p>
                                </div>
                                <div className="h-7 flex items-center">
                                  <button
                                    type="button"
                                    className="text-gray-400 hover:text-gray-500"
                                    onClick={() => setAddonsModal(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Divider container */}
                            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              {/* Project name */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon name
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    defaultValue={addonName}
                                    type="text"
                                    onChange={(e) => {
                                      setaddonName(e.target.value);
                                    }}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* addon pricing */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon Pricing
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    defaultValue={addonPrice}
                                    onChange={(e) => {
                                      setaddonPrice(e.target.value);
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* addon description */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon Description
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <textarea
                                    defaultValue={addonDesc}
                                    onChange={(e) => {
                                      setaddonDesc(e.target.value);
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                    rows={3}
                                  />
                                </div>
                              </div>

                              {/* addon notes */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Addon Notes
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <textarea
                                    defaultValue={addonNote}
                                    onChange={(e) => {
                                      setaddonNote(e.target.value);
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                    rows={3}
                                  />
                                </div>
                              </div>

                              {/* addon fixed */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Fixed Addon?
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    defaultChecked={addonFixed}
                                    onChange={(e) => {
                                      setaddonFixed(e.target.checked);
                                    }}
                                    type="checkbox"
                                    className="block text-primary-200 shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                            <div className="space-x-3 flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                onClick={() => setAddonsModal(false)}
                              >
                                Cancel
                              </button>
                              <Button
                                type="button"
                                sizeClass=""
                                disabled={disableAddonUpdate}
                                onClick={() => {
                                  updateAddon();
                                  setAddonsModal(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Update
                              </Button>
                              <Button
                                type="button"
                                disabled={disableAddonCreate}
                                sizeClass=""
                                onClick={() => {
                                  createAddon();
                                  setAddonsModal(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Create New
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>


            {/* external booking modal window */}
            <Transition.Root show={externalBookingModal} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-20 overflow-hidden"
                onClose={(b)=>{setexternalBookingModal(b);}}
              >
                <div className="absolute inset-0 z-20 overflow-hidden">
                  <Dialog.Overlay className="absolute inset-0" />

                  <div className="fixed inset-y-0 z-20 right-0 pl-10 max-w-full flex sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <div className="w-screen z-20 max-w-2xl">
                        <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                          <div className="flex-1">
                            {/* Header */}
                            <div className="px-4 py-6 bg-gray-50 sm:px-6">
                              <div className="flex items-start justify-between space-x-3">
                                <div className="space-y-1">
                                  <Dialog.Title className="text-lg font-medium text-gray-900">
                                    New External Booking
                                  </Dialog.Title>
                                  <p className="text-sm text-gray-500">
                                    Get started by filling in the information
                                    below to create your external booking.
                                  </p>
                                </div>
                                <div className="h-7 flex items-center">
                                  <button
                                    type="button"
                                    className="text-gray-400 hover:text-gray-500"
                                    onClick={() => setexternalBookingModal(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* Divider container */}
                            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              {/* Project name */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Booking name
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setextBookingName(e.target.value);
                                    }}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Booking Phone Number
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setextBookingNumber(e.target.value);
                                    }}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* addon pricing */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Booking price
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    onChange={(e) => {
                                      setextBookingPrice(e.target.value);
                                    }}
                                    type="number"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Booking Date
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    onChange={(e) => {
                                      setextBookingDate(e.target.value);
                                    }}
                                    type="date"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>

                              {/* addon description */}
                              <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Booking notes
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <textarea
                                    onChange={(e) => {
                                      setextBookingNotes(e.target.value);
                                    }}
                                    type="text"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                                    rows={3}
                                  />
                                </div>
                              </div>




                            </div>
                          </div>

                          {/* Action buttons */}
                          <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                            <div className="space-x-3 flex justify-end">
                              <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                onClick={() => setexternalBookingModal(false)}
                              >
                                Cancel
                              </button>
                              <Button
                                type="button"
                                disabled={disableAddonCreate}
                                sizeClass=""
                                onClick={() => {
                                  createExtBooking();
                                  setexternalBookingModal(false);
                                }}
                                className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                Create New
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>




          </article>
          </Animate>

        )}
      </div>
    );
}