/* This AlertWithError requires Tailwind CSS v2.0+ */
import { ExclamationIcon } from '@heroicons/react/solid'
import { XCircleIcon } from '@heroicons/react/solid'

export default function AlertWithError({desc , action , color}) {
  return (
    <div className={`bg-red-50 border-l-4 border-red-400 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className={`h-5 w-5 text-red-400`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={`text-sm text-red-700`}>
            {desc}.{' '}
            <a href='/contact' className={`font-medium underline text-red-700 hover:text-red-600`}>
              {action}.
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
