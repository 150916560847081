import React, { useEffect } from 'react'
import { ChatAltIcon, PaperClipIcon, TagIcon, UserCircleIcon } from '@heroicons/react/solid'
import StayCard from 'components/StayCard/StayCard'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import ListingsGrid from './ListingsGrid'
import moment from 'moment'
import useAuthStore from 'globals/authStore'
import axios from 'axios'
import { url } from '../../globals'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import Badge from 'shared/Badge/Badge'
import { Fragment,  } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import Offer from 'components/Offer'
import VLogo from "images/VLogo.PNG"

export default function LeadInfo({leadID, leadName, matchingListings=[], comment,leadDetails, listingType,
    leadPhone,shortListedListings,subListings,manualListings,BAUListings, leadHits , allLeads , setAllLeads, addToShortListed, hideOfferButton, listingID,}) {
    const loggedUser = useAuthStore(state=>state.user);
    const [offers, setoffers] = useState([])
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [selectedOffer, setselectedOffer] = useState(null)
    const [lead, setlead] = useState(null)
    const [listing, setlisting] = useState(null)
    const [adminListingID, setadminListingID] = useState(null)
    const [message, setMessage] = useState(null);

    useEffect(() => {
      setMessage('Welcome to Venu! Visit ' + `www.venu.ai/myrequests/${leadID}/matched-listings for the possible spaces matching your request. In order to see all your requests, please visit www.venu.ai/myrequests and use your phone number.
      We've shortlisted some listings for you that we think are most suitable, you can access them via the second link after entering your phone number under "pinned listings", you can also check your offers under "offers"`)
      
    }, [leadID]);

    const copyLink = (msg)=>{
      navigator.clipboard.writeText(msg)
      toast.success("Link copied to clipboard");
    }

    const sendWhatsapp = (whatsappMsg)=>{
      const win = window.open(`https://web.whatsapp.com/send/?phone=+2${leadPhone}&text=${whatsappMsg}&type=phone_number&app_absent=0`, "_blank");
      win.focus();
    }

    const goToMakeAnOffer = ()=>{
      history.push(`/cp/create-offer/${leadID}/${listingID}`)
      toast.info("You can build up an offer from your listing's packages and addons, or create new ones!")
    }

    const goToOfferAdmin= (listing)=>{
      const win = window.open(`/cp/create-offer/${leadID}/${listing.id}`, "_blank");
      win.focus();
      toast.info("You can build up an offer from your listing's packages and addons, or create new ones!")
    }

    const getOffers = ()=>{
      axios.post(`${url}/offers/getOffers` , {userID : loggedUser.id , listingID , leadID}).then((res)=>{
        setoffers(res.data);
      })
    }
    const getlisting = ()=>{
      let id = listingID
      if(loggedUser.rank =="Admin"){
        id = adminListingID
      }
      axios.post(`${url}/listing/read` , {id:id}).then((res)=>{
        if(Array.isArray(res.data)){
          setlisting(null)
        }
        else
        setlisting(res.data);
      })
    }

    const getLead = ()=>{
      axios.post(`${url}/leads/getLeadByID` , {id:leadID}).then((res)=>{
        setlead(res.data);
      })
    }

    useEffect(()=>{
      getOffers()
      getLead()
    },[])

    useEffect(()=>{
      if(listingID || adminListingID){
        getlisting()
      }
      console.log(adminListingID)
      console.log("selected offer" + selectedOffer)
      console.log("listing" + listing)
      console.log("lead" + lead)
    },[selectedOffer, adminListingID, listingID])


  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">

    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{leadName}</h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">{leadID}</p>
      {loggedUser.rank =="Admin" && <p className="mt-1 max-w-2xl text-sm text-gray-500">{leadPhone}</p>}
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Full name</dt>
          <dd className="mt-1 text-sm text-gray-900">{leadName}</dd>
        </div>
        {loggedUser.rank =="Admin" && <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
          <dd className="mt-1 text-sm text-gray-900">{leadPhone}</dd>
        </div>}
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Event Date</dt>
          <dd className="mt-1 text-sm text-gray-900">{moment(leadDetails.date).format(' DD - MM - YYYY dddd')} </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Event Type</dt>
          <dd className="mt-1 text-sm text-gray-900">{listingType.name}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Desired location</dt>
          <dd className="mt-1 text-sm text-gray-900">{leadDetails.subLocation} . {leadDetails.location}</dd>
        </div>
        {leadDetails && leadDetails.guests && <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Guests</dt>
          <dd className="mt-1 text-sm text-gray-900">{leadDetails.guests} Guests</dd>
        </div>}
        {leadDetails  && leadDetails.priceRangeHigh && <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Price Range</dt>
          <dd className="mt-1 text-sm text-gray-900">{leadDetails.priceRangeLow} - {leadDetails.priceRangeHigh} EGP</dd>
        </div>}
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Comment</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {comment}
          </dd>
        </div>

        {loggedUser.rank == "Admin" && <div className="sm:col-span-2">
          <dt className="text-lg font-medium text-gray-500">Hits timeline</dt>
          <dd className="mt-1 text-sm text-gray-900">
          <div className="flow-root">
      <ul role="list" className="-mb-8">
        {leadHits.map((leadHitsItem, leadHitsItemIdx) => (
          <li key={leadHitsItem.id}>
            <div className="relative pb-8">
              {leadHitsItemIdx !== leadHits.length - 1 ? (
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {leadHitsItem.type === 'Hit' ? (
                  <>
                    <div className="relative">
                      <img
                        className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                        src={VLogo}
                        alt=""
                      />

                      <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                        <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <span className="font-medium text-gray-900">
                            {leadHitsItem.user.name}
                          </span>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">Added a Hit {moment(leadHitsItem.tDate).format('dd DD - MM - YYYY')}
                        {' '} using <span className='text-gray-700 font-bold'>{leadHitsItem.channel}</span>. Targetted phone <span className='text-gray-700 font-bold'>{leadHitsItem.targetPhoneNumber}</span>   using <span className='text-gray-700 font-bold'>{leadHitsItem.srcPhoneNumber}</span> 
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{leadHitsItem.comment}</p>
                      </div>
                    </div>
                    
                    
                    <div>
                      <div className="relative px-1">
                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-0">
                      <div className="text-sm leading-8 text-gray-500">
                        <span className="mr-0.5">
                          <span className="font-medium text-gray-900">
                            {leadHitsItem.user.name}
                          </span>{' '}
                          Changed States
                        </span>{' '}
                        <span className="mr-0.5">
                          {
                            <Fragment key={leadHitsItem.id}>
                              <span
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                              >
                                <span className="absolute flex-shrink-0 flex items-center justify-center">
                                  <span
                                    className={ ' bg-rose-500 h-1.5 w-1.5 rounded-full'}
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3.5 font-medium text-gray-900">{leadHitsItem.statusFrom}</span>
                              </span>{' to '}
                              <span
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                              >
                                <span className="absolute flex-shrink-0 flex items-center justify-center">
                                  <span
                                    className={ ' bg-indigo-500 h-1.5 w-1.5 rounded-full'}
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3.5 font-medium text-gray-900">{leadHitsItem.statusTo}</span>
                              </span>{' '}
                            </Fragment>
                          }
                        </span>
                        <span className="whitespace-nowrap">{moment(leadHitsItem.tDate).format('dd DD - MM - YYYY')}</span>
                      </div>
                    </div>
                  
                  </>
                ) : leadHitsItem.type === 'Assignment' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1.5">
                      <div className="text-sm text-gray-500">
                        <span className="font-medium text-gray-900">
                          {leadHitsItem.assignedBy}
                        </span>{' '}
                        assigned{' '}
                        <span href={"#"} className="font-medium text-gray-900">
                          {leadHitsItem.user.name}
                        </span>{' '}
                        <span className="whitespace-nowrap">{moment(leadHitsItem.tDate).format('dd DD - MM - YYYY')}</span>
                      </div>
                    </div>
                  </>
                ) : leadHitsItem.type==="Self Assignment" ? 
                <>
                <div>
                  <div className="relative px-1">
                    <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                      <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                <div className="min-w-0 flex-1 py-1.5">
                  <div className="text-sm text-gray-500">
                    <span className="font-medium text-gray-900">
                      {leadHitsItem.user.name}
                    </span>{' '}
                    was self assigned{' '}
                    <span className="whitespace-nowrap">{moment(leadHitsItem.tDate).format('dd DD - MM - YYYY')}</span>
                  </div>
                </div>
              </>
                : leadHitsItem.type === 'Hit' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                          <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-0">
                      <div className="text-sm leading-8 text-gray-500">
                        <span className="mr-0.5">
                          <span className="font-medium text-gray-900">
                            {leadHitsItem.user.name}
                          </span>{' '}
                          Changed States
                        </span>{' '}
                        <span className="mr-0.5">
                          {
                            <Fragment key={leadHitsItem.id}>
                              <span
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                              >
                                <span className="absolute flex-shrink-0 flex items-center justify-center">
                                  <span
                                    className={ ' bg-rose-500 h-1.5 w-1.5 rounded-full'}
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3.5 font-medium text-gray-900">{leadHitsItem.statusFrom}</span>
                              </span>{' '}
                              <span
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                              >
                                <span className="absolute flex-shrink-0 flex items-center justify-center">
                                  <span
                                    className={ ' bg-indigo-500 h-1.5 w-1.5 rounded-full'}
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3.5 font-medium text-gray-900">{leadHitsItem.statusTo}</span>
                              </span>{' '}
                            </Fragment>
                          }
                        </span>
                        <span className="whitespace-nowrap">{moment(leadHitsItem.tDate).format('dd DD - MM - YYYY')}</span>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>

          </dd>
        </div>}

        {loggedUser.rank =="Admin" &&
        <>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Message
          <button onClick={()=>{copyLink(message)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Copy</button> | 
          <button onClick={()=>{sendWhatsapp(message)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Send Whatsapp</button>

          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {message}
          </dd>
        </div>

        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Matched listings Link
          <button onClick={()=>{copyLink(`www.venu.ai/myrequests/${leadID}/matched-listings`)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Copy</button> | 
          <button onClick={()=>{sendWhatsapp(`www.venu.ai/myrequests/${leadID}/matched-listings`)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Send Whatsapp</button>

          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {`www.venu.ai/myrequests/${leadID}/matched-listings`}
          </dd>
        </div>
        
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Pinned listings Link
          <button onClick={()=>{copyLink(`www.venu.ai/myrequests/${leadID}/pinned-listings`)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Copy</button> | 
          <button onClick={()=>{sendWhatsapp(`www.venu.ai/myrequests/${leadID}/pinned-listings`)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Send Whatsapp</button>

          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {`www.venu.ai/myrequests/${leadID}/pinned-listings`}
          </dd>
        </div>

        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Offers Link
          <button onClick={()=>{copyLink(`www.venu.ai/myrequests/${leadID}`)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Copy</button> | 
          <button onClick={()=>{sendWhatsapp(`www.venu.ai/myrequests/${leadID}`)}} className='mx-2 text-xs underline text-primary-400 hover:text-primary-700 cursor-pointer font-semibold"'>Send Whatsapp</button>

          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {`www.venu.ai/myrequests/${leadID}`}
          </dd>
        </div>


        </>
        }
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Attached Offers</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {
                offers.map((offer,i)=>{
                  return(
                    <li key={offer.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 flex-1 w-0 truncate">{offer.id} - listing :#{offer.listingID}</span>
                    </div>
                    <div className="ml-4 flex-shrink-0 md:flex">
                      <div  className="font-medium mr-1 ml-1 cursor-pointer text-primary-200 hover:text-primary-700">
                        <Badge name={offer.status} color={offer.status =="Pending"? "yellow" : (offer.status =="Accepted" ? "green" : "purple")}/>
                      </div>
                      <div onClick={()=>{setOpen(true);if(loggedUser.rank=="Admin"){setadminListingID(offer.listingID)} ;setselectedOffer(offer)}}  className="font-medium cursor-pointer text-primary-200 hover:text-primary-700">
                        View Offer
                      </div>
                    </div>
                  </li>
                  )
                })
              }


            </ul>
          </dd>
          {!hideOfferButton && 
          <div className='mt-3 flex justify-center'><ButtonPrimary clickInc={goToMakeAnOffer}>Make an offer</ButtonPrimary></div>}
        </div>
        {loggedUser.rank=="Admin" && !hideOfferButton &&  <> <ListingsGrid listings={matchingListings} title={"Matching Listings"} primaryAction={"Add to shortlist"}primaryOnClk={addToShortListed} />
        <ListingsGrid listings={shortListedListings} title={"Shortlisted Listings"} primaryOnClk={(e)=>{goToOfferAdmin(e)}} primaryAction={"Make an offer"}/>
        <ListingsGrid listings={BAUListings} title={"BAU Listings"} primaryOnClk={(e)=>{goToOfferAdmin(e)}} primaryAction={"Make an offer"}/>
        <ListingsGrid listings={subListings} title={"Subscribed Listings"} />
        <ListingsGrid listings={manualListings} title={"Manual Listings (Archiving Purposes)"} /> </>}
        

      </dl>
    </div>






  
    <Transition.Root c show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">View Offer {selectedOffer && selectedOffer.id}</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {selectedOffer && listing && lead && <Offer
                        checkoutPrice={selectedOffer.checkoutPrice}
                        cpSide={false}
                        leadID={leadID}
                        answers={selectedOffer.answers}
                        selectedAddons={selectedOffer.addons}
                        selectedPackage={selectedOffer.package}
                        paymentTerms={selectedOffer.paymentTerms}
                        listing={listing}
                        inOfferPage={false}
                        leadQuestions={lead.questions}

                        

                      />}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

  </div>
  )
}
