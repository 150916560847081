import { Transition,Dialog } from "@headlessui/react"
import { Fragment } from "react"
import SecondaySideBar from "containers/ControlPanelLayout/SecondarySideBar"
import { XIcon } from "@heroicons/react/outline"
import { Link, NavLink, useLocation } from 'react-router-dom'
import { marketingVerticalTabs } from 'globals/publicData'
import venuLogo from 'images/logos/venuLogo.svg'
import useAuthStore from "globals/authStore"
import { useHistory} from "react-router-dom"
import { LockClosedIcon } from "@heroicons/react/solid"
import { imagesAmazonS3URL, url } from '../../globals';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

import axios from "axios"
import { useEffect } from "react"
import useCPStore from "globals/controlPanelStore"

export default function AgencyMobileSideBar({sidebarOpen , setSidebarOpen, user})
{

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      const loggedUser = useAuthStore(state=>state.user)
      const history = useHistory()
      var location = useLocation();
      location = location.pathname
      const setShownavBar = useCPStore(state=>state.setShowNavBar)
      //console.log(loggedUser)

      useEffect(()=>{
        setShownavBar(false)
      },[])
      const logout = ()=>{
        localStorage.removeItem('CpUser5')
        history.push('/cp')
        window.location.reload()


      }
      
    return <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setSidebarOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </Transition.Child>
          <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div className="flex-shrink-0 flex items-center px-4">
              <img
                className=" w-auto"
                src={venuLogo}
                alt="Workflow"
              />
            </div>
            <nav className="mt-5" aria-label="Sidebar">
            <div className="px-2 space-y-1">
            {marketingVerticalTabs.map((item) => {
                      return (
                        <NavLink
                        key={item.name}
                        to={item.href}
                        onClick={()=>{axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Agency Sidebar Tabs',url:location, extraInfoType:'Tab Name', extraInfo:item.name }).then((res)=>{});; }}
                        activeClassName = '!bg-primary-500 !border-purple-600 !text-white'
                        d
                        className={classNames(
                          item.current
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-primary-700' : 'text-primary-700 ',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                       
                      </NavLink>
                      
                    
                   )
                    
                    })}
                </div>
                <WhatsAppWidget
                  phoneNo="201120155661"
                  position="left"
                  widgetWidth="300px"
                  widgetWidthMobile="260px"
                  autoOpen={false}
                  autoOpenTimer={5000}
                  messageBox={false}
                  messageBoxTxt="Hi"
                  iconSize="50"
                  iconColor="white"
                  iconBgColor="green"
                  headerIcon={user.imageUrl}
                  headerIconColor="white"
                  headerTxtColor="black"
                  headerBgColor="white"
                  headerTitle="Venu Support"
                  headerCaption="Online"
                  bodyBgColor="#e5e7eb"
                  chatPersonName="Venu Support"
                  chatMessage={<>Hi there 👋, Need help?</>}
                  footerBgColor="#e5e7eb"
                  btnBgColor="white"
                  btnTxtColor="black"
                  btnTxt="Start Chat"
		              />
                  <div className="flex-shrink-0 flex border-y border-gray-200 p-4 mt-10">
              <div className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div className='self-start'>
                    <img className="inline-block h-9 w-9 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3 flex flex-col">
                    <p className="text-sm font-medium text-gray-700">{loggedUser.name}</p>
                    <Link to={"/cp-agency/change-password"} className="text-xs font-medium text-gray-500 hover:text-gray-700">Change Password</Link>
                    <a onClick={(e)=>{logout()}} className="text-xs font-medium text-gray-500 hover:text-gray-700">Logout</a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          </div>

        </div>
      </Transition.Child>
      <div className="flex-shrink-0 w-14" aria-hidden="true">
      </div>
    </Dialog>
  </Transition.Root>
}