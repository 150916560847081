import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
//import PageHome from "../containers/PageHome/PageHome";
//import Page404 from "../containers/Page404/Page404";
//import ListingStayPage from "../containers/ListingStayPage/ListingStayPage";
//import ListingStayMapPage from "../containers/ListingStayPage/ListingStayMapPage";
//import ListingStayDetailPage from "../containers/ListingDetailPage/ListingStayDetailPage";
//import ListingExperiencesDetailPage from "../containers/ListingDetailPage/ListingExperiencesDetailPage";
//import ListingCarDetailPage from "../containers/ListingDetailPage/ListingCarDetailPage";
//import CheckOutPage from "../containers/CheckOutPage/CheckOutPage";
//import PayPage from "../containers/PayPage/PayPage";
//import AuthorPage from "../containers/AuthorPage/AuthorPage";
//import AccountPage from "../containers/AccountPage/AccountPage";
//import AccountPass from "../containers/AccountPage/AccountPass";
//import AccountSavelists from "../containers/AccountPage/AccountSavelists";
//import AccountBilling from "../containers/AccountPage/AccountBilling";
//import PageContact from "../containers/PageContact/PageContact";
//import PageAbout from "../containers/PageAbout/PageAbout";
//import PageSignUp from "../containers/PageSignUp/PageSignUp";
//import PageLogin from "../containers/PageLogin/PageLogin";
//import PageSubcription from "../containers/PageSubcription/PageSubcription";
//import BlogPage from "../containers/BlogPage/BlogPage";
//import BlogSingle from "../containers/BlogPage/BlogSingle";
// import PageAddListing1 from "../containers/PageAddListing1/PageAddListing1";
// import PageAddListing2 from "../containers/PageAddListing1/PageAddListing2";
// import PageAddListing3 from "../containers/PageAddListing1/PageAddListing3";
// import PageAddListing4 from "../containers/PageAddListing1/PageAddListing4";
// import PageAddListing5 from "../containers/PageAddListing1/PageAddListing5";
// import PageAddListing6 from "../containers/PageAddListing1/PageAddListing6";
// import PageAddListing7 from "../containers/PageAddListing1/PageAddListing7";
// import PageAddListing8 from "../containers/PageAddListing1/PageAddListing8";
// import PageAddListing9 from "../containers/PageAddListing1/PageAddListing9";
// import PageAddListing10 from "../containers/PageAddListing1/PageAddListing10";
//import PageHome2 from "../containers/PageHome/PageHome2";
import SiteHeader from "../containers/SiteHeader";
//import UserVenueList from "../containers/DashboardPage/UserVenueList";
//import ManageVenues from "../containers/DashboardPage/ManageVenues";
//import ManageReservations from "containers/DashboardPage/ManageReservations";
//import ListingStatistics from "containers/DashboardPage/ListingStatistics";
import globablauthentication  from "globals/auth";
import useAuthStore from "globals/authStore";
//import BookingSearch from "containers/BookingPages/BookingSearch";
//import BookingPage from "containers/BookingPages/BookingPage";
//import ManageReservationsBusiness from "containers/BusinessDashboard/BusinessManageReservations";
//import ListingStatisticsBusiness from "containers/BusinessDashboard/ListingStatistics";
//import ManageVenuesBusiness from "containers/BusinessDashboard/ManageVenues"
//import BookingInvoicePage from "containers/ListingDetailPage/BookingInvoicePage";
//import CpVenues from "containers/BusinessDashboard/CpVenues";
//import CpBookings from "../containers/BusinessDashboard/CpBookings";
//import CpRevenue from "../containers/BusinessDashboard/CpRevenue";
//import CpReviews from "../containers/BusinessDashboard/CpReviews";
//import CpHome from "containers/BusinessDashboard/CpHome";
//import CpPartialBookings from "containers/BusinessDashboard/CpPartialBookings";
//import CpBzTeamContacts from "containers/BusinessDashboard/CpBzTeamContacts";
//import CpPromoCodes from "containers/BusinessDashboard/CpPromoCodes";
//import PageFeatured from "containers/PageHome/PageFeatured";
//import PageFAQ from "containers/PageFAQ/PageFAQ";
//import CpLogin from "containers/BusinessDashboard/CpLogin";
import { parseJwt } from "globals/publicData";
//import CpBlog from "containers/BusinessDashboard/CpBlog";
//import BlogPageNew from "containers/BlogPage/BlogPageNew";
//import BlogPageForTag from "containers/BlogPage/BlogPageForTag";
//import BlogPageForAllPosts from "containers/BlogPage/BlogPageForAllPosts";
//import ListingBookingOne from "containers/ListingDetailPage/ListingBookingOne";
//import ListingBookingTwo from "containers/ListingDetailPage/ListingBookingTwo";
//import ListingBookingThree from "containers/ListingDetailPage/ListingBookingThree";
import ApHome from "containers/AdminPanel/ApHome";
import ApLogin from "containers/AdminPanel/ApLogin";
//import CpRequests from "containers/BusinessDashboard/CpRequests";
import {Ring} from 'react-awesome-spinners';
import { useEffect,useState } from "react";
import axios from "axios";
import { url } from "../globals";
//import CpChat from "containers/BusinessDashboard/CpChat";
//import VenueTypePage from "containers/VenueTypePages/VenueTypePage";
//import CpOTP from "containers/BusinessDashboard/CpOTP";
//import PageAISearch from "containers/PageAISearch/PageAISearch";
import useCPStore from "globals/controlPanelStore";

import CpChangePassword from "containers/BusinessDashboard/CpChangePassword";
import DelayedFallback from "components/DelayedFallback";
import CpLinks from "containers/BusinessDashboard/CpLinks";
import CpRegistarions from "containers/BusinessDashboard/CpRegistrations";
import PageEntity from "containers/Subdomain Entities/PageEntity";
import PageRestaurant from "containers/Subdomain Entities/PageRestaurant";
import SkasRestaurant from "containers/Subdomain Entities/SkasRestaurant";
import FooterNav from "components/FooterNav";
import Header3 from "components/Header/Header3";
import useWindowSize from "hooks/useWindowResize";
import TrackRequests from "containers/BookingPages/TrackRequests";
import RequestOffers from "containers/BookingPages/RequestOffers";
import CpQualifiedLeads from "containers/BusinessDashboard/CpQualifiedLeads";
import CpQualifiedLeadsOwners from "containers/BusinessDashboard/CpQualifiedLeadsOwners";
import RequestMatchedListings from "containers/BookingPages/RequestMatchedListings";
import RequestPinnedListings from "containers/BookingPages/RequestPinnedListings";
import CpCreateOffer from "containers/BusinessDashboard/CpCreateOffer";
import useAppStore from "globals/appStore";
import SubPage1 from "containers/Subdomain/SubPage1";
import SubPage2 from "containers/Subdomain/SubPage2";
import CpForgetPassword from "containers/BusinessDashboard/CpForgotPassword";
import CpForgotPassword from "containers/BusinessDashboard/CpForgotPassword";
import CpReports from "containers/BusinessDashboard/CpReports";


const PageHome = lazy(() => import('../containers/PageHome/PageHome'));
const Page404 = lazy(() => import('../containers/Page404/Page404'));
const ListingStayPage = lazy(() => import('../containers/ListingStayPage/ListingStayPage'));
const ListingStayMapPage = lazy(() => import('../containers/ListingStayPage/ListingStayMapPage'));
const ListingStayDetailPage = lazy(() => import('../containers/ListingDetailPage/ListingStayDetailPage'));
const ListingExperiencesDetailPage = lazy(() => import('../containers/ListingDetailPage/ListingExperiencesDetailPage'));
const ListingCarDetailPage = lazy(() => import('../containers/ListingDetailPage/ListingCarDetailPage'));
const CheckOutPage = lazy(() => import('../containers/CheckOutPage/CheckOutPage'));
const PayPage = lazy(() => import('../containers/PayPage/PayPage'));
const AuthorPage = lazy(() => import('../containers/AuthorPage/AuthorPage'));
const AccountPage = lazy(() => import('../containers/AccountPage/AccountPage'));
const AccountPass = lazy(() => import('../containers/AccountPage/AccountPass'));
const AccountSavelists = lazy(() => import('../containers/AccountPage/AccountSavelists'));
const PinnedSpaces = lazy(() => import('../containers/AccountPage/PinnedSpaces'));
const AccountBilling = lazy(() => import('../containers/AccountPage/AccountBilling'));
const PageContact = lazy(() => import('../containers/PageContact/PageContact'));
const PageAbout = lazy(() => import('../containers/PageAbout/PageAbout'));
const PageReviews = lazy(() => import('../containers/PageReviews/PageReviews'));
const PagePartners= lazy(() => import('../containers/PagePartners/PagePartners'));
const PageSignUp = lazy(() => import('../containers/PageSignUp/PageSignUp'));
const PageLogin = lazy(() => import('../containers/PageLogin/PageLogin'));
const PageSubcription = lazy(() => import('../containers/PageSubcription/PageSubcription'));
const BlogPage = lazy(() => import('../containers/BlogPage/BlogPage'));
const BlogSingle = lazy(() => import('../containers/BlogPage/BlogSingle'));
const PageAddListing0= lazy(() => import('../containers/PageAddListing1/PageAddListing0'));
const PageAddListing1= lazy(() => import('../containers/PageAddListing1/PageAddListing1'));
const PageAddListing2= lazy(() => import('../containers/PageAddListing1/PageAddListing2'));
const PageAddListing3= lazy(() => import('../containers/PageAddListing1/PageAddListing3'));
const PageAddListing4= lazy(() => import('../containers/PageAddListing1/PageAddListing4'));
const PageAddListing5= lazy(() => import('../containers/PageAddListing1/PageAddListing5'));
const PageAddListing6= lazy(() => import('../containers/PageAddListing1/PageAddListing6'));
const PageAddListing7= lazy(() => import('../containers/PageAddListing1/PageAddListing7'));
const PageAddListing8= lazy(() => import('../containers/PageAddListing1/PageAddListing8'));
const PageAddListing9= lazy(() => import('../containers/PageAddListing1/PageAddListing9'));
const PageAddListing10= lazy(() => import('../containers/PageAddListing1/PageAddListing10'));
const PageAddListingOTP= lazy(() => import('../containers/PageAddListing1/PageAddListingOTP'));
const PageHome2 = lazy(() => import('../containers/PageHome/PageHome2'));

const UserVenueList = lazy(() => import('../containers/DashboardPage/UserVenueList'));
const ManageVenues = lazy(() => import('../containers/DashboardPage/ManageVenues'));
const ManageReservations = lazy(() => import('containers/DashboardPage/ManageReservations'));
const ListingStatistics = lazy(() => import('containers/DashboardPage/ListingStatistics'));
const BookingSearch = lazy(() => import('containers/BookingPages/BookingSearch'));
const BookingPage = lazy(() => import('containers/BookingPages/BookingPage'));
const ManageReservationsBusiness = lazy(() => import('containers/BusinessDashboard/BusinessManageReservations'));
const ListingStatisticsBusiness = lazy(() => import('containers/BusinessDashboard/ListingStatistics'));
const BookingInvoicePage = lazy(() => import('containers/ListingDetailPage/BookingInvoicePage'));
const ManageVenuesBusiness = lazy(() => import('containers/BusinessDashboard/ManageVenues'));
const CpVenues = lazy(() => import('containers/BusinessDashboard/CpVenues'));
const CpBookings = lazy(() => import('containers/BusinessDashboard/CpBookings'));
const CpRevenue = lazy(() => import('containers/BusinessDashboard/CpRevenue'));
const CpReviews = lazy(() => import('containers/BusinessDashboard/CpReviews'));
const CpHome = lazy(() => import('containers/BusinessDashboard/CpHome'));
const CpPartialBookings = lazy(() => import('containers/BusinessDashboard/CpPartialBookings'));
const CpBzTeamContacts = lazy(() => import('containers/BusinessDashboard/CpBzTeamContacts'));
const CpPromoCodes = lazy(() => import('containers/BusinessDashboard/CpPromoCodes'));
const PageFeatured = lazy(() => import('containers/PageHome/PageFeatured'));
const PageFAQ = lazy(() => import('containers/PageFAQ/PageFAQ'));
const PageSecretSauce = lazy(() => import('containers/PageSecretSauce/PageSecretSauce'));
const PagePayment = lazy(() => import('containers/PagePayment/PagePayment'));
const PageLRForm = lazy(() => import('containers/PageLRForm/PageLRForm'));
const CpLogin = lazy(() => import('containers/BusinessDashboard/CpLogin'));
const CpBlog = lazy(() => import('containers/BusinessDashboard/CpBlog'));
const BlogPageNew = lazy(() => import('containers/BlogPage/BlogPageNew'));
const BlogPageForTag = lazy(() => import('containers/BlogPage/BlogPageForTag'));
const BlogPageForAllPosts = lazy(() => import('containers/BlogPage/BlogPageForAllPosts'));
const ListingBookingOne = lazy(() => import('containers/ListingDetailPage/ListingBookingOne'));
const ListingBookingTwo = lazy(() => import('containers/ListingDetailPage/ListingBookingTwo'));
const ListingBookingThree = lazy(() => import('containers/ListingDetailPage/ListingBookingThree'));
const CpRequests = lazy(() => import('containers/BusinessDashboard/CpRequests'));
const CpChat = lazy(() => import('containers/BusinessDashboard/CpChat'));
const CpRegistrations = lazy(() => import('containers/BusinessDashboard/CpRegistrations'));
const VenueTypePage = lazy(() => import('containers/VenueTypePages/VenueTypePage'));
const CpOTP = lazy(() => import('containers/BusinessDashboard/CpOTP'));
const CpHomeAffiliate= lazy(() => import('containers/BusinessDashboard/CpHomeAffiliate'));
const CpBookingsAffiliate= lazy(() => import('containers/BusinessDashboard/CpBookingsAffiliate'));
const CpUsersManager= lazy(() => import('containers/BusinessDashboard/CpUsersManager'));
const CpBookingsAgency= lazy(() => import('containers/BusinessDashboard/CpBookingsAgency'));
const CpHomeAgency= lazy(() => import('containers/BusinessDashboard/CpHomeAgency'));
const PageAISearch = lazy(() => import('containers/PageAISearch/PageAISearch'));
const SpacesPage = lazy(() => import('../containers/ListingDetailPage/SpacesPage'));
const Register = lazy(() => import('../containers/PageSignUp/Register'));
const PageCheckout = lazy(() => import('../containers/PageCheckOut/PageCheckout'));
const PageCheckout2 = lazy(() => import('../containers/PageCheckOut/PageCheckout2'));
const PageCheckout3 = lazy(() => import('../containers/PageCheckOut/PageCheckout3'));
const PageCheckout4 = lazy(() => import('../containers/PageCheckOut/PageCheckout4'));
const PageCheckout5 = lazy(() => import('../containers/PageCheckOut/PageCheckout5'));
const AffiliatesFAQ = lazy(() => import('../containers/AffiliatesFAQ/AffiliatesFAQ'));
const CpAskHost = lazy(() => import('containers/BusinessDashboard/CpAskHost'));
const HostPro = lazy(()=> import('../containers/HostProPage/HostPro'))
const CpSubdReq = lazy(()=> import('../containers/BusinessDashboard/CpSubdReq'))
const SubHookah = lazy(()=> import('../containers/Subdomain/SubHookah'))
const DDBranchPage = lazy(()=> import ("containers/PageSecretSauce/DDBranchPage"));


export const pages = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", exact: true,component: PageHome2 },
  
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  { path: "/listing-stay-details", component: SpacesPage },
  //
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  //
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/pinned", component: PinnedSpaces },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog/all", component: BlogPageForAllPosts },

  { path: "/blog", component: BlogPageNew },
  { path: "/blog-tag/:name", component: BlogPageForTag },
  { path: "/blog-draft", component: BlogPage },
  { path: "/blog-post/:id", component: BlogSingle },
  //
  { path: "/add-listing-0", component: PageAddListing0 },
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/add-listing-confirm", component: PageAddListingOTP },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/reviews", component: PageReviews},
  { path: "/partners", component: PagePartners},
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/FAQ", component: PageFAQ},
  { path: "/secretsauce", component: PageSecretSauce},
  { path: "/secretsauce/:branch", component: DDBranchPage},
  { path: "/payment/:id", component: PagePayment},
  { path: "/start", component: PageLRForm},
  { path: "/SmartSearch", component: PageAISearch},
  { path: "/forgot-password", component: CpForgotPassword},

  { path: "/bookinginfo", component: BookingSearch},
  { path: "/bookinginfo/:id", component: BookingSearch},
  { path: "/myrequests", component: TrackRequests},
  { path: "/myrequests/:id", component: RequestOffers},
  { path: "/myrequests/:id/matched-listings", component: RequestMatchedListings},
  { path: "/myrequests/:id/pinned-listings", component: RequestPinnedListings},
  { path: "/booking-step-1/:id", component: ListingBookingOne},
  { path: "/booking-step-2/:id", component: ListingBookingTwo},
  { path: "/booking-step-3/:id", component: ListingBookingThree},
  { path: "/booking", component: BookingPage},
  { path: "/managereservsbusiness", component: ManageReservationsBusiness},
  { path: "/statisticsbusiness", component: ListingStatisticsBusiness},
  { path: "/managevenuesbusiness", component: ManageVenuesBusiness},
  { path: "/Featured", component: PageFeatured},
  { path: "/invoice", component: BookingInvoicePage},
  { path: "/uservenuelist", component: UserVenueList, priv:true },
  { path: "/managevenues", component: ManageVenues ,priv:true },
  { path: "/managereservs", component: ManageReservations ,priv:true },
  { path: "/statistics", component: ListingStatistics,priv:true },

  { path: "/cp", exact:true, component: CpLogin },
  { path: "/cp-affiliate", exact:true, component: CpLogin },
  { path: "/cp-affiliate/home", exact:true, component: CpHomeAffiliate, priv:true, affiliate:true, cpType:"affiliate" },
  { path: "/cp-affiliate/bookings", exact:true, component: CpBookingsAffiliate, priv:true, affiliate:true, cpType:"affiliate" },
  { path: "/cp-affiliate/change-password", exact:true, component: CpChangePassword, priv:true,  affiliate:true, cpType:"affiliate"},
  { path: "/cp-agency/home", exact:true, component: CpHomeAgency , priv:true, agency:true, cpType:"agency" },
  { path: "/cp-agency/change-password",exact:true, component: CpChangePassword, priv:true, agency:true, cpType:"agency"},
  { path: "/cp-agency/bookings", exact:true, component: CpBookingsAgency , priv:true, agency:true, cpType:"agency" },
  { path: "/cp/otp", component: CpOTP, priv : false},
  { path: "/cp/venues", component: CpVenues, priv:true, cpType :"core"},
  { path: "/cp/home", component: CpHome, priv:true, cpType:"core"},
  { path: "/cp/bookings", component: CpBookings, priv:true, cpType:"core"},
  { path: "/cp/revenue", component: CpRevenue, priv:true, cpType:"core"},
  { path: "/cp/reviews", component: CpReviews, priv:true, cpType:"core"},
  { path: "/cp/links", component: CpLinks, priv:true, cpType:"core"},
  { path: "/cp/change-password", component: CpChangePassword, priv:true, cpType:"core"},
  { path: "/cp/partial-bookings", component: CpPartialBookings, priv:true, cpType:"core"},
  { path: "/cp/ask-host", component: CpAskHost, priv:true, cpType:"core"},
  { path: "/cp/bzTeamContacts", component: CpBzTeamContacts, priv:true, admin : true, cpType:"core"},
  { path: "/cp/promoCodes", component: CpPromoCodes, priv:true, admin : true, cpType:"core"},
  { path: "/cp/registrations", component: CpRegistrations, priv:true, admin : true, cpType:"core"},
  { path: "/cp/users-manager", component: CpUsersManager, priv:true, admin : true, cpType:"core"},
  { path: "/cp/blog", component: CpBlog, priv:true, admin : true, cpType:"core"},
  { path: "/cp/chat", component: CpChat, priv:true, admin : true, cpType:"core"},
  { path: "/cp/qualified-leads", component: CpQualifiedLeads, priv:true, admin : true, cpType:"core"},
  { path: "/cp/qualified-leads-owners", component: CpQualifiedLeadsOwners, priv:true,  cpType:"core"},
  { path: "/cp/create-offer/:leadID/:listingID", component: CpCreateOffer, priv:true,  cpType:"core"},
  { path: "/cp/requests",exact:true, component: CpRequests, priv:true,superAdmin:true, cpType:"core"},
  { path: "/cp/subdomainreq",exact:true, component: CpSubdReq, priv:true,admin:true, cpType:"core"},
  { path: "/cp/reports",exact:true, component: CpReports, priv:true,superAdmin:true, cpType:"core"},

  { path: "/:umbrella/:type/listing-stay", component: ListingStayPage },

  { path: "/spaces/:urlVenueType", component: VenueTypePage },
  { path: "/partners/register", exact: true, component: Register },
  { path: "/partner/:affiliate", exact: true, component: PageHome },
  { path: "/entity", exact: true, component: PageEntity },
  { path: "/restaurant", exact: true, component: PageRestaurant },
  { path: "/skas", exact: true, component: SkasRestaurant },
  { path: "/checkout/personal-info/:id", exact: true, component: PageCheckout },
  { path: "/checkout/date/:id", exact: true, component: PageCheckout2 },
  { path: "/checkout/packages-addons/:id", exact: true, component: PageCheckout3 },
  { path: "/checkout/payment-options/:id", exact: true, component: PageCheckout4 },
  { path: "/checkout/confirmation/:id", exact: true, component: PageCheckout5 },
  { path: "/AffiliatesFAQ", exact: true, component: AffiliatesFAQ },
  { path: "/VillaSafi", exact: true, component: SubPage2 },
  { path: "/hostdemo", exact: true, component: HostPro },
  { path: "/Hookah", exact: true, component: SubHookah },
  

];

const check = (param, type, superAdmin) => {
  let rank = parseJwt(localStorage.getItem('CpUser5')).user.rank;
  if(rank === "Admin" && !superAdmin){
    return true
  }
  else{
    if(rank === 'Affiliate' && param === rank && type === 'affiliate')
    {
      return true
    }
    if(rank === 'Agency' && param === rank && type === 'agency')
    {
      return true
    }
    if(rank === 'Owner' && param === rank && type === 'core')
    {
      return true
    }
    if(rank === "Blogger" && param === rank && type ==='core'){
      return true
    }
    else{
      return false
    }
    
  }
}



const Routes = () => {
  const authentication = useAuthStore(state => state.checkAuth)
  const setUser = useAuthStore(state=>state.setUser)
  const setFavs = useAppStore(state => state.setFavs)
  const showNavBar = useCPStore(state=>state.showNavBar)
  const windowSize = useWindowSize();
  console.log("rerendering routes" );

  if(authentication()){
    const accessToken = localStorage.getItem('CpUser5');
    const user = parseJwt(accessToken);
    setUser(user.user)

  }

  useEffect(async()=>{
    const response = await axios.post(`${url}/listing/getCacheMetaData`);
    localStorage.setItem("cacheMetaData",JSON.stringify(response.data))
    console.log(response.data)


  },[])
  
  useEffect(()=>{
    let phoneNumber = localStorage.getItem("phoneNumber")
    if(phoneNumber)
    {
      axios.post(`${url}/favourites/get` , {phoneNumber:phoneNumber}).then((res)=>
      {
        setFavs(res.data)
      })
    }

  },[])
  
  return (
    <BrowserRouter basename="/Venu" >
      <ScrollToTop />
      {showNavBar && <Header3 />}
      {/* <NewNavBar/> */}
      
      <Switch>
        <Suspense fallback={<DelayedFallback/>} >

        {pages.map(({ component, path, exact, priv, admin,superAdmin,affiliate, agency, cpType }) => {

          if(!priv){
            return(
              <Route
              key={path}
              component={component}
              exact={true}
              path={path}
            />
            
            )
          }
          else
          if(priv){
            if(authentication()){
              if(parseJwt(localStorage.getItem('CpUser5')).user.rank==="Blogger" && path === '/cp/blog'){
                console.log("got to blogger 1")
                return(
                <Route
                key={path}
                component={component}
                exact={true}
                path={path}
              />)  
                       }
              if(parseJwt(localStorage.getItem('CpUser5')).user.rank==="Blogger" && path !=='/cp/blog'){
                console.log("got to blogger 2")
                return <Route exact={true} component={CpLogin} path={path}></Route>                  

              }
              else
              if(admin){
                if(check("Admin")){
                  return(
                    <Route
                    key={path}
                    component={component}
                    exact={true}
                    path={path}
                  />
                  )
                }
                else{
                  return <Route path={'/cp'}></Route>
                }
  
              }

              else{
                //doesnt require admin
              
                if(affiliate)
                {
                  if(check("Affiliate",cpType)){
                    return(
                      <Route
                      key={path}
                      component={component}
                      exact={true}
                      path={path}
                    />
                    )
                  }
                  else{
                    return <Route exact={true} component={CpLogin} path={path}></Route>                  }
                }
                if(agency)
                {
                  if(check("Agency",cpType)){
                    return(
                      <Route
                      key={path}
                      component={component}
                      exact={true}
                      path={path}
                    />
                    )
                  }
                  else{
                    return <Route exact={true} component={CpLogin} path={path}></Route>                  }
                }
            
                if(superAdmin){
                  if(parseJwt(localStorage.getItem('CpUser5')).user.superAdmin || check("Owner",cpType,superAdmin)){
                    return(
                      <Route
                      key={path}
                      component={component}
                      exact={true}
                      path={path}
                    />)
                    
                  }
                  else{
                    return <Route
                    key={path}
                    component={BookingInvoicePage}
                    exact={true}
                    path={'/FAQ'}
                  />

                  }

                }
                else{
                  if(check("Owner",cpType)){
                  return(
                    <Route
                    key={path}
                    component={component}
                    exact={true}
                    path={path}
                  />)
                  }
                  else{
                    return(
                      <Route
                      key={path}
                      component={CpLogin}
                      exact={true}
                      path={path}
                    />)
                  }
                }

  
              }
            
            }
            else{
              console.log("defaulted to not authenticated")
              return <Route exact={true} component={CpLogin} path={path}></Route>
            //   key={path}
            //   component={CpLogin}
            //   exact={false}
            //   path={'/cp'}
            // />

            }

          }




        })}


        
        </Suspense>
        <Route component={Page404} />

        </Switch>
        
        {windowSize.width<1024 && showNavBar &&<FooterNav/>}
        {windowSize.width>=1024 && showNavBar &&<Footer/>}

    </BrowserRouter>
  );
};


// function PrivateRoute ({component: Component, authed, ...rest}) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => authed === true
//         ? <Component {...props} />
//         : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
//     />
//   )
// }
export default Routes;
