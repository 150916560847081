import React from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Animate from "react-smooth/lib/Animate";
import EntityBGSection from "./EntityBGSection";
import EntityCard from "./EntityCard";
import EntityCard2 from "./EntityCard2";
import EntityCardWithCTA from "./EntityCardWithCTA";
import useCPStore from "globals/controlPanelStore";
import { useEffect } from "react";
const PageEntity = ({ className = "" }) => {
    const setNavbar = useCPStore(state=>state.setShowNavBar);
    useEffect(()=>{
        setNavbar(false)
    },[])
  return (
    <div
      className={`nc-PageEntity overflow-hidden relative ${className}`}
      data-nc-id="PageEntity"
    >
      <Animate to="1" from="0" attributeName="opacity">
      <Helmet>
        <title>Entity || Entity Page</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <EntityBGSection imageLink={"https://pix10.agoda.net/hotelImages/11412581/0/6be3c34c11eb76a4b4b514dea00f53d1.jpg?ca=9&ce=1&s=1024x768"}/>
      <EntityCardWithCTA imageLink={"https://pix8.agoda.net/hotelImages/11412581/0/5f530eebc948282f153cfdfbdb770d17.jpg?ca=9&ce=1&s=1024x768"}/>
        <div className="grid grid-cols-2">
        <EntityCard imageLink={"https://pix8.agoda.net/hotelImages/849/84954/84954_16051609060042353236.jpg?ca=6&ce=1&s=375x"}/>
        <EntityCard imageLink={"https://pix8.agoda.net/hotelImages/11412581/0/f8739fd838c8f7dd5a811b32904665d5.jpg?ca=9&ce=1&s=1024x768"}/>

        </div>
      <EntityCard2 imageLink={"https://pix8.agoda.net/hotelImages/11412581/0/39c069ecaeccedbde100bfd141ef7c74.jpg?ca=9&ce=1&s=1024x768"}/>
      </Animate> 
    </div>
  );
};
export default PageEntity;
