import DataTable from 'react-data-table-component';
import { useState, useEffect} from 'react';
import { Fragment } from 'react'
import {  useRef } from 'react'
import { toast } from "react-toastify";
import { Menu, Transition, Dialog } from '@headlessui/react'
import axios from 'axios';
import { url } from '../../globals';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import moment from 'moment';
import BlockLoader from 'components/BlockLoader';
import { BanIcon, BellIcon, CheckIcon, DotsHorizontalIcon } from '@heroicons/react/outline';
import { BookmarkAltIcon, BookmarkIcon, CalendarIcon, DotsVerticalIcon, LinkIcon, PencilAltIcon, PencilIcon, RefreshIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import useAuthStore from 'globals/authStore';
import Button from 'shared/Button/Button';
import Checkbox from 'shared/Checkbox/Checkbox';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useLocation } from 'react-router-dom';

export default function RequestsDT() {
    const [loading, setLoading] = useState(true);
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement)
    const [requests, setRequests] = useState([]);
    const loggedUser = useAuthStore(state=>state.user)
    const [refresh, setRefresh] = useState(true);
    const [openDetails, setOpenDetails] = useState(false);
    const [details, setDetails] = useState(null);
    const [selectedListing, setSelectedListing] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [reqAtt, setReqAtt] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(null);
    const [checkboxes, setCheckboxes] = useState(["pending","rejected","approved","canceled"]);
    const [filtered, setFiltered] = useState(null);
    const [oldTypes, setOldTypes] = useState(null);
    const [newTypes, setNewTypes] = useState(null);
    const [rejectModal, setRejectModal] = useState(false);
    const [toBeRejected, setToBeRejected] = useState(null);
    const [rejectionMessage, setRejectionMessage] = useState(null);
    const [rejectionMessages, setRejectionMessages] = useState(null);
    var location = useLocation();
    location = location.pathname

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }
    const cancelButtonRef = useRef(null)

    useEffect(() => {
      axios.post(`${url}/request/read` ,{id : loggedUser.id}).then((res)=>{
        setLoading(true)
        setRequests(res.data.reverse());
        setLoading(false)
    })
    }, [refresh]);

    useEffect(() => {
      axios.post(`${url}/rejections/read`).then((res)=>{
        setRejectionMessages(res.data)
    })
    }, []);
    useEffect(() => {
      if(details){
      let detailsKeys = Object.keys(details);
      let temp = []
      for (let i = 0; i < detailsKeys.length; i++) {
        if(detailsKeys[i] !== "id"&&detailsKeys[i] !== "type"&&detailsKeys[i] !== "date"&& detailsKeys[i] !== "listingID"&&detailsKeys[i] !== "senderID" && detailsKeys[i] !== "__v" &&detailsKeys[i] !== "_id"&&detailsKeys[i] !== "approve" &&detailsKeys[i] !== "archive" && detailsKeys[i] !== "cancel" && detailsKeys[i] !== "receiverID" && detailsKeys[i] !== "rejMessage" && detailsKeys[i] !== "receiverID")
        {
          temp.push(detailsKeys[i])
        }
      }
      setReqAtt(temp)
      }
    }, [details]);

    useEffect(() => {
      if(details&&selectedListing){
        if(details['listingType']){
          axios.post(`${url}/listingType/typeNames`,{types : details['listingType']}).then((res)=>{         
            setNewTypes(res.data.slice(0,-1))
           })
          }
          if(selectedListing['listingType']){
            axios.post(`${url}/listingType/typeNames`,{types : selectedListing['listingType']}).then((res)=>{
             setOldTypes(res.data.slice(0,-1))
     
           })}
    }
    }, [details,selectedListing]);

    const viewDetails = (p) => {
     axios.post(`${url}/listing/allListing`,{id : p.listingID}).then((res)=>{
      setSelectedListing(res.data)
      setDetails(p)
     }).catch((err)=>{
      console.log(err);
    })
    axios.post(`${url}/user/read`,{id : p.senderID}).then((res)=>{
      setSelectedUser(res.data)
      setOpenDetails(true)
     }).catch((err)=>{
      console.log(err);
    })

    }
    const createbuttons = (p) =>{

        return (
        <div>
          <Popover className="relative ">
            {({ open, close }) => (
              <>
                <Popover.Button
                ref={setReferenceElement}
                  className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  {/* <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" /> */}
                  <DotsHorizontalIcon className="h-6 w-6 inline-flex mr-1
                  items-center p-1 border border-transparent border-primary-50 bg-primary-400 rounded-full shadow-sm
                    text-white  hover:bg-primary-500 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"/>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel ref={setPopperElement} 
                  className="absolute right-full z-10 w-screen max-w-[260px] px-4 -mt-3 mr-auto  sm:right-0 sm:px-0">
                    <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                        
                        {options.map((item, index) => (
                          ((p.approve !== "pending" || p.cancel === true) && item.notpending !== true ) || (item.admin === true && loggedUser.rank !=="Admin") || (item.userOnly === true && loggedUser.rank === "Admin") || (p.type !== "Media" && item.media ) || ((item.name==='Approve' || item.name==='Reject' || (item.name==='Notify' && loggedUser.rank ==='Owner')) &&  p.type=== "Media")||
                          (<a
                            onClick={() => {
                                item.onClick(p);
                                close()
                              }}
                            key={index}
                            className="flex cursor-pointer items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                              <item.icon aria-hidden="true" className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium ">{item.name}</p>
                            </div>
                          </a>)
                        ))}
                      </div>
            
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        );

    }
    const approveReq = async(p) => {
      const request = await axios.post(`${url}/request/approve` ,{id : p.id,receiverID:p.senderID,senderID:loggedUser.id,date:moment()})
      // console.log(request)
      if(p.type ==='Activation'){
        const res =  await axios.post(`${url}/listing/update` , {id :p.listingID, active : true});
      }
      if(p.type ==='Deactivation'){
        const res =  await axios.post(`${url}/listing/update` , {id :p.listingID, active : false});
      }
      if(p.type ==='Modification'){
        if(request){
        const res =  await axios.post(`${url}/listing/update` , {id :p.listingID, ...request.data});}
      }
      toast.success("Request approved")
      setRefresh((state)=>!state);
    }
    const rejectReq = (p) => {
      setRejectModal(true)
      setToBeRejected(p)
    }

    const handleReject = () => {
      console.log(rejectionMessage)
      axios.post(`${url}/request/reject` ,{id : toBeRejected.id,receiverID:toBeRejected.senderID,senderID:loggedUser.id,rejMessage:rejectionMessage,date:moment()}).then(toast.success("Request rejected"))
      setRefresh((state)=>!state);
      setRejectModal(false)
    }
    const handleConfirm = () => {
      axios.post(`${url}/request/delete` ,{id : toBeDeleted.id}).then(toast.success("Request deleted")); 
      setRefresh((state)=>!state);
      setOpenModal(false);
    }
    const notifySender = (p) => {
      axios.post(`${url}/notification/create` ,{senderID : loggedUser.id, receiverID:p.senderID,request:p,date:moment(),notify:true})
      axios.post(`${url}/request/notifyMedia` ,{id : p.id}).then(toast.success("Owner notified"));
      setRefresh((state)=>!state);
    }
    const deleteReq = (p) => {
      setOpenModal(true)
      setToBeDeleted(p)
  
    }
    const cancelReq = (p) => {
      axios.post(`${url}/request/cancel` ,{id : p.id}).then(toast.success("Request canceled"))
      setRefresh((state)=>!state);
    }
    const options = [
      {
        name: "Details",
        onClick: viewDetails,
        icon: DotsHorizontalIcon,
        notpending:true
      },
      {
        name: "Notify",
        onClick: notifySender,
        icon: BellIcon,
        media:true
      },

      {
        name: "Approve",
        onClick: approveReq,
        icon: CheckIcon,
        admin:true,
        
       

      },
      {
        name: "Reject",
        onClick: rejectReq,
        icon: BanIcon,
        admin:true,
      },
      {
        name: "Cancel",
        onClick: cancelReq,
        icon: XIcon,
        userOnly:true

      },
      {
        name: "Delete",
        onClick: deleteReq,
        icon: TrashIcon,
        notpending:true,
        admin:true,
    
      },
    ];
    const columns = [
        {
            name: 'Request ID',
            selector: row => row.id,
            sortable : true,
          
        
        },
        {
          name: 'Venue ID',
          selector: row =>  row.listingID,
          sortable : true,
         
      },
        {
          name: 'Owner ID',
          selector: row =>  row.senderID,
          sortable : true,
       
        },
        {
          name: 'Submission Date',
          selector: row =>  moment(row.date).toDate().toLocaleString(),
          sortable : true,
          grow : 2
      },
        {
            name: 'Type',
            selector: row =>  row.type,
            sortable : true,
            grow : 2
        },
        {
          name: 'Status',
          selector: row => {
            if(row.cancel)
            {
              return "canceled"
            }
            else {
              return row.approve
            }
          },
          sortable : true,
          grow : 2
        },
        {
            
            cell: (row) => {return createbuttons(row)},
            selector : row=>row.buttons,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            grow : 4
        },
    ];

 
    const renderFilterStatus = () => {
      return (
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`${checkboxes.length !== 4 ? "inline-flex items-center py-2 px-4  shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50  ml-2 rounded-md border-2 border-neutral-300 dark:border-neutral-700 focus:outline-none":"inline-flex items-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50  ml-2 rounded-md"}`}
              >
                <span>{`${checkboxes.length === 4 ? 'Status':"Filtering on" }`}</span>
                <i className="las la-angle-down ml-2"></i>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-72 max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                  <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                          <Checkbox
                            key = {1}
                            name={"pending"}
                            label={"pending"}
                            defaultChecked= {checkboxes.includes("pending")}
                            onChange = {function (e) {
                              filterStatus(e,"pending")
                            }}
                          />
                            <Checkbox
                            key = {2}
                            name={"approved"}
                            label={"approved"}
                            defaultChecked= {checkboxes.includes("approved")}
                            onChange = {function (e) {
                              filterStatus(e,"approved")
                            }}
                          />
                            <Checkbox
                            key = {3}
                            name={"rejected"}
                            label={"rejected"}
                            defaultChecked= {checkboxes.includes("rejected")}
                            onChange = {function (e) {
                              filterStatus(e,"rejected")
                            }}
                          />
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      );
    }
    const filterStatus = (e,item) => {
      axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Filter',details:'Requests',url:location,extraInfo:item }).then((res)=>{
      })
      if(e){
        if(checkboxes.length !== 0)
        {
          if(!checkboxes.includes(item))
          {
            let temp = checkboxes
            temp.push(item)
            if(item === 'canceled')
            {
              temp.push("pending")
            }
            setCheckboxes(temp)
          }
        }
        else {
          let temp = checkboxes
          temp.push(item)
          if(item === 'canceled')
          {
            temp.push("pending")
          }
          setCheckboxes(temp)
        }
      }
      else {
        if(checkboxes.length !== 0)
        {
          if(checkboxes.includes(item))
          {
            if(item === 'canceled')
            {
              let temp = checkboxes
              let index = temp.indexOf('pending')
              temp.splice(index, 1)
              setCheckboxes(temp)
            }
            let temp = checkboxes
            let index = temp.indexOf(item)
            temp.splice(index, 1)
            setCheckboxes(temp)
          }
        }
      }

     let temp = requests.filter((i)=>{if(checkboxes.includes(i.approve)){return i}})
     let filterTemp = temp.filter((i)=>{if((!checkboxes.includes("canceled") && i.cancel === false)||(checkboxes.includes("canceled"))){return i}})
     setFiltered(filterTemp)
    }
    return (
      <>
    
      {loading?<BlockLoader/>:
      <>
      {(<Transition.Root show={openDetails} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpenDetails}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
                  <PencilAltIcon className="h-10 w-10 text-primary-400" aria-hidden="true" />
                </div>
                {details &&<div className="mt-2 text-center sm:mt-2">
                  <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900 mb-5">
                    Request Details
                  </Dialog.Title>
                  <p className='text-sm font-medium capitalize'>
                   {selectedListing.name}
                   </p>
                  <p className='text-sm font-medium capitalize'>
                    {selectedUser && selectedUser.name}
                  </p>
                  {/* <p>
                    {selectedUser && selectedUser.phoneNumber}
                  </p> */}
                  <p className='mt-1 tex-sm'>
                    {details.approve === 'rejected' && (
                      <p>
                        Rejection reason was <span className='font-medium'>{details.rejMessage}</span>
                      </p>
                    )}
                  </p>
                  {reqAtt.length !== 0 && <div className="mt-3 ">
                     <div className="px-4 pt-2 pb-2 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Changes</h3>
      </div>
      <div className="mt-2 border border-neutral-200 dark:border-neutral-700  flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-2 flex justify-center space-x-5 min-w-full">
            <div className="flex flex-col">
            {reqAtt.map((a)=>{

            return (
              
              <div className='grid grid-col-2 grid-flow-col gap-24 justify-between items-center '>
                 <div className="flex flex-col w-44 m-1 ">
                <p className="mt-1.5 text-md font-medium capitalize">
                  {a}
                  </p>
                  <p className="text-sm text-neutral-400">From</p>
                  <p className="mt-1 text-sm font-medium text-red-600">
                    {a === "listingType" ? oldTypes : a=== 'isAds' ? selectedListing[a].toLocaleString(): a=== 'discountExpire' ? selectedListing[a].slice(0,10): a=== 'weekend'? selectedListing.rates[0].weekend:selectedListing[a]
                    }
                  </p>
                  </div>
                  <div className="flex flex-col w-44 m-1 overflow-clip ">
                  <p className="mt-1.5 text-md font-medium capitalize">
                  {a}
                  </p>
                  <p className="text-sm text-neutral-400">To</p>
                  <p className="mt-1 text-sm font-medium text-green-600 ">
                  {a === "listingType" ? newTypes: a==='isAds'? details[a].toLocaleString() : a=== 'discountExpire' ? details[a].slice(0,10): details[a]
                    }
                  </p>
                  </div>
                  </div>
                )})
                }
            </div>
            </div>
          </div>             
    </div>}
                </div>}
              </div>
              <div className="mt-3 w-full flex justify-end">
                <button
                  type="button"
                  className="mt-3 w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                  onClick={() => {setOpenDetails(false); setReqAtt([]); setDetails(null);}}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>)}
      <div className='w-full flex sm:justify-end items-center justify-start my-5 sm:pr-8 pl-4 gap-3'>
      <Checkbox
        key = {4}
        name={"canceled"}
        label={"Canceled"}
        defaultChecked= {checkboxes.includes("canceled")}
        onChange = {function (e) {
          filterStatus(e,"canceled")
        }}
      />
        {renderFilterStatus()}
        <Button
          className="inline-flex items-center py-2 px-4 border border-gray-300 shadow-sm text-sm
    font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
    focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 rounded-md"
          type={"button"}
          onClick={()=>{setRefresh((state)=>!state); setFiltered(null); setCheckboxes(["pending","rejected","approved"]); axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Request Refresh',url:location}).then((res)=>{
          })}}
          sizeClass=""
        >
          <RefreshIcon
            className=" mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"  
          />
          Refresh
        </Button>
        </div>
        <DataTable
            columns={columns}
            data={filtered ? filtered : requests}
            direction="auto"
            pagination
            persistTableHead
            responsive
            defaultSortAsc={false}
        />
        
        </>
        }
        {rejectModal && (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center p-5 border-b border-solid border-gray-300 rounded-t ">
                <div className='flex flex-col'>
                  <h3 className="text-lg font-medium mb-5">Choose your reason for rejection</h3>
                  
                  <label htmlFor="rejectionMessage" className="sr-only">
                    rejectionMessage
                  </label>
                  <select
                    id="rejectionMessage"
                    name="rejectionMessage"
                    className="focus:ring-primary-500 focus:border-primary-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    onChange={(e)=>{setRejectionMessage(e.target.value)}}
                  >
                    <option value={null}>{""}</option>
                    {
                      rejectionMessages.map((m)=>{
                        return(
                          <option key={m.id} value={m.message}>{m.message}</option>
                        )
                      })
                    }
                  </select>
                </div>
                </div>
                <div className="flex items-center justify-center p-3 mr-3">
                <Button
          className="inline-flex items-center py-2 px-4 border border-gray-300 shadow-sm text-sm
    font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
    focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 rounded-md"
          type={"button"}
          onClick={()=>{
            if(rejectionMessage){
            handleReject()}
            else{
              toast.warning("Please select a reason for rejection")
            } 
          }}
          sizeClass=""
        >
          Submit & Reject
        </Button>
        <Button
          className="inline-flex items-center py-2 px-4 border border-gray-300 shadow-sm text-sm
    font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
    focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 rounded-md"
          type={"button"}
          onClick={()=>{setRejectModal(false)}}
          sizeClass=""
        >
         Cancel
        </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
     {openModal && (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-md font-medium">Are you sure you want to delete this request ?</h3>
                </div>
                <div className="flex items-center justify-end p-3 mr-3">
                <Button
          className="inline-flex items-center py-2 px-4 border border-gray-300 shadow-sm text-sm
    font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
    focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 rounded-md"
          type={"button"}
          onClick={()=>{handleConfirm()}}
          sizeClass=""
        >

          Yes
        </Button>
                  <Button
          className="inline-flex items-center py-2 px-4 border border-gray-300 shadow-sm text-sm
    font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
    focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 rounded-md"
          type={"button"}
          onClick={()=>{setOpenModal(false)}}
          sizeClass=""
        >
          No
        </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
        </>
    );
};