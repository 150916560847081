import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import RegistrationDT from "./RegistrationDT";
import DynamicDT from "components/DataTablesComponents/DynamicDT";
import {data,columns} from "../../components/DataTablesComponents/data"
import ButtonClose from "shared/ButtonClose/ButtonClose";
import axios from "axios";
import { url } from "../../globals";
import useAuthStore from "globals/authStore";
import moment from "moment/moment";
import { EventTypeSwitcher } from "globals/publicData";
import LeadInfo from "./LeadInfo";
import { EyeIcon, MailIcon, PencilIcon, PhoneIcon, UserIcon, XIcon } from "@heroicons/react/outline";
import useCPStore from "globals/controlPanelStore";
import BlockLoader from "components/BlockLoader";
import useAppStore from "globals/appStore";
import { toast } from "react-toastify";
import Badge from "shared/Badge/Badge";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Select from 'react-select';
import { XCircleIcon } from "@heroicons/react/solid";
import ListBoxCustomized from "components/ListBoxCustomized";
import { EditText, EditTextarea } from "react-edit-text";
import { v4 as uuidv4 } from 'uuid';

const ManageQualifiedLeads = () => {
  const [showingInfo, setshowingInfo] = useState(false)
  const cpUser = useAuthStore(state=>state.user);
  const [fetchedLeads, setfetchedLeads] = useState([]);
  const selectedLead = useCPStore(state=>state.selectedLead);
  const setSelectedLead = useCPStore(state=>state.setSelectedLead);
  const [loading, setloading] = useState(false);
  const [matchingListings, setmatchingListings] = useState([])
  const [shortListedListings, setshortListedListings] = useState([])
  const [subListings, setsubListings] = useState([])
  const [BAUListings, setBAUListings] = useState([])
  const [manualListings, setmanualListings] = useState([])
  const [listingID, setlistingID] = useState(null)
  const [assignedTo, setassignedTo] = useState(null)
  const [showAssignmentModal, setshowAssignmentModal] = useState(false)
  let allTypes = useAppStore(state=>state.allTypes);
  const [admins, setadmins] = useState([])
  const [selectedAdmin, setselectedAdmin] = useState({})
  const [showHitModal, setshowHitModal] = useState(false)
  const [hitNewStatus, sethitNewStatus] = useState(null)
  const [hitCommChannel, sethitCommChannel] = useState(null)
  const [hitTargetPhone, sethitTargetPhone] = useState("")
  const [hitSrcPhone, sethitSrcPhone] = useState(null)
  const [hitComment, sethitComment] = useState("")
  const [fetchedHits, setfetchedHits] = useState([])
  const eventTypeSwitcher = (typeID)=>{
    for (let i = 0; i < allTypes.length; i++) {
     const type = allTypes[i];
     if(type.id == typeID){
       return {
         name : type.title,
         icon : type.icon
       }
     }
     
    }
    return{
      name : "Unsupported Event Type"
    }
 }

 const possibleCrmStatus = ["In Progress" , "Hot Case" , "Requires Followup", "Interested in v"
,'No Answer' , 'Out of Service' ,'Site Visiting', 'Post Site Visit', "Booked" , 'Paid']
const possibleCommunications = ['Phone Call' , 'Whatsapp' , 'SMS' , 'Face to Face']
const possiblePhoneNumbers = ['Main Business Number' , "Tech Number" , "Personal Number"]

  const cols = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      grow : 2
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      grow : 2
    },
    {
      name: "Phone",
      selector: "phoneNumber",
      sortable: true
    },
    {
      name: "Event Type",
      selector: "listingType",
      sortable: true,
      cell : row=>{
        return(<span> { eventTypeSwitcher(row.listingType).name }</span>)
      }
    },
    {
      name: "Creation Date",
      selector: "tDate",
      sortable: true,
      cell : row=>{
        return(<span> {moment(row.tDate).format('YYYY - MM - DD')}</span>)
      }
    },
    // {
    //   name: "Sub. Status",
    //   selector: "adminStatus",
    //   sortable: true,
    //   cell : row=>{
    //     return(<Badge color={row.adminStatus=="No action needed"? "green" : (row.adminStatus=="Requires BD" ? "red" : "purple")} className="text-xs truncate" name={row.adminStatus}>  </Badge>)
    //   }
    // },
    {
      name: "CRM Status",
      selector: "crmStatus",
      sortable: true,
      cell : row=>{
        return(<Badge color={"purple"} className="text-xs truncate" name={row.crmStatus? row.crmStatus : ""}>  </Badge>)
      }
    },
    {
      name: "Assigned To",
      selector: "admin",
      sortable: true,
      cell : row=>{
        return(<Badge color={"gray"} className="text-xs truncate" name={row.admin? row.admin.name :"" }>  </Badge>)
      }
    },
  ]

  const selectRow = (row)=>{
    setSelectedLead(row)
  }

  const viewDetails = ()=>{
    if(selectRow){
      setshowingInfo(true);
      console.log(selectedLead.matchingListings)

    }
  }
  const setSelections = (e)=>{
    if(e.selectedRows.length>0){
      setSelectedLead(e.selectedRows[0])
      setlistingID(e.selectedRows[0].listingID)
    }

  }


  const fetchListingsInLead = ()=>{
    setloading(true);
    axios.post(`${url}/listing/getListingsByIDs` , {ids : selectedLead.matchingListings}).then((res)=>{
      setmatchingListings(res.data);
      setloading(false);
    })
    axios.post(`${url}/listing/getListingsByIDs` , {ids : selectedLead.shortListed}).then((res)=>{
      setshortListedListings(res.data);
      console.log("short listed are ")
      console.log(res.data)
      setloading(false);
    })
    axios.post(`${url}/listing/getListingsByIDs` , {ids : selectedLead.BAUListings}).then((res)=>{
      setBAUListings(res.data);
      setloading(false);
    })
    axios.post(`${url}/listing/getListingsByIDs` , {ids : selectedLead.manualListings}).then((res)=>{
      setmanualListings(res.data);
      setloading(false);
    })
    axios.post(`${url}/listing/getListingsByIDs` , {ids : selectedLead.subListings}).then((res)=>{
      setsubListings(res.data);
      setloading(false);
    })
  }

  const addToShortListed = (listing)=>{
    axios.post(`${url}/leads/addToShortListed` , {id : selectedLead.id , listingID : listing.id, manual:true}).then((res)=>{
        toast.success('shortlisted successfully')
        setSelectedLead(res.data);
        let tempLeads =fetchedLeads;
        for (let i = 0; i < tempLeads.length; i++) {
            const lead = tempLeads[i];
            if(lead.id == res.data.id){
                tempLeads[i] = res.data.id
                break;
            }
            
        }
        setfetchedLeads(tempLeads)

        viewDetails()
    })
}

const sendSMS = ()=>{
  if(selectedLead){
    axios.post(`${url}/leads/sendSMS` , {leadID : selectedLead.id , phoneNumber : selectedLead.phoneNumber}).then((res)=>{
        console.log(res.data);
        toast.success('SMS Sent successfully')
    })
  }
}

const assignLead = ()=>{
  if(selectedLead){
    axios.post(`${url}/leads/assignLead` , {assigneeID : cpUser.id,
       tDate : moment(), leadID :selectedLead.id, userID : selectedAdmin.value}).then((res)=>{
        toast.success("Lead Assigned successfully");
        let tempLeads = fetchedLeads.filter((r)=>{return true});
        for (let i = 0; i < tempLeads.length; i++) {
          if(tempLeads[i].id == res.data.id){
            tempLeads[i] = res.data;
            setfetchedLeads(tempLeads)
            break;
          }
        }

       })
  }
}

const createHit = ()=>{
  if(selectedLead){
    let hit ={
      id : uuidv4(),
      leadID : selectedLead.id,
      user : {id : cpUser.id , name : cpUser.name , email : cpUser.email , tDate : moment()},
      type : "Hit",
      channel : hitCommChannel,
      targetPhoneNumber : hitTargetPhone,
      srcPhoneNumber : hitSrcPhone,
      reachDate : moment(),
      tDate : moment(),
      statusFrom : selectedLead.crmStatus,
      statusTo : hitNewStatus,
      comment : hitComment
    }
    axios.post(`${url}/crm/createCRMInstance` , hit).then((res)=>{
      toast.success('New hit created successfully')
      let tempLeads = fetchedLeads.filter((r)=>{return true});
      for (let i = 0; i < tempLeads.length; i++) {
        if(tempLeads[i].id == res.data.id){
          tempLeads[i] = res.data;
          setfetchedLeads(tempLeads)
          break;
        }
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

}


useEffect(()=>{
  if(selectedLead!=null){
    fetchListingsInLead()
    fetchHits()
    sethitTargetPhone(selectedLead.phoneNumber)
  }
},[selectedLead])




  const buttons =
  <div className="md:flex flex items-center">
  <button onClick={(e)=>{viewDetails()}} className="flex  rounded-md bg-primary-100 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
    Details
    <PencilIcon className="w-5 h-5"/>
  </button>
  {<button onClick={(e)=>{setshowHitModal(true)}} className="flex whitespace-nowrap rounded-md bg-blue-400 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
    Create Hit
    <PhoneIcon className="w-5 h-5"/>
  </button>}
  {cpUser.superAdmin ==true && <button onClick={(e)=>{sendSMS()}} className="flex whitespace-nowrap rounded-md bg-green-400 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
    Send SMS 
    <MailIcon className="w-5 h-5"/>
  </button>}
  {cpUser.superAdmin ==true && <button onClick={(e)=>{setshowAssignmentModal(true)}} className="flex whitespace-nowrap rounded-md bg-orange-400 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
    Assign Lead
    <UserIcon className="w-5 h-5"/>
  </button>}



</div>

  const fetchLeads = ()=>{
    setloading(true)
    axios.post(`${url}/leads/getLeadsCP` , {userID : cpUser.id}).then((res)=>{
      console.log(res);
      let sortedData = res.data.sort((a,b) => moment(a.tDate).isAfter(moment(b.tDate))); // b - a for reverse sort
      setfetchedLeads(sortedData);
      setloading(false)
      
    })
  }

  const fetchAdmins = ()=>{
    axios.post(`${url}/user/fetchAdmins`).then((res)=>{
      const adminsData = res.data.map((owner)=>{
        return {label :owner.id + "_" + owner.email , value : owner.id}
      })
      setadmins(adminsData);
    }).catch((err)=>{
      console.log(err);
      toast.error(err.message)
    })
  }


  const fetchHits = ()=>{
    if(selectedLead){
      axios.post(`${url}/crm/getAllCRMInstances`, {leadID : selectedLead.id}).then((res)=>{
        setfetchedHits(res.data)
        console.log(res.data)
      })
    }
  }
  useEffect(()=>{
    fetchLeads();
    fetchAdmins()
  },[])


    //The action buttons:-

  return (
    <div>
        <div className="space-y-6 space-x-6 sm:space-y-8">
          {/* HEADING */}
          <div className="">
            <br />
            <div className="">
                <DynamicDT
                  className={showingInfo && " hidden "}
                  title="Qualified Leads"
                  actionButtons={buttons}
                  defaultSortColumn={5}
                  onRowSelect={(e)=>{e && setSelections(e)}}
                data={fetchedLeads} columns={cols} />
                {showingInfo &&  <div className="flex justify-end w-full text-primary-100 hover:text-primary-700 transition-colors">
                  <span className=" text-sm underline mt-auto mb-auto cursor-pointer" onClick={()=>{setshowingInfo(false);setloading(false)}}> Close View </span>
                <ButtonClose onClick={(e)=>{setshowingInfo(false); setloading(false)}}  />

                </div>}
                {
                  showingInfo && !loading &&  <LeadInfo
                  leadID={selectedLead.id}
                  leadName={selectedLead.name}
                  leadPhone = {selectedLead.phoneNumber}
                  leadDetails = {selectedLead.details && selectedLead.details}
                  comment={selectedLead.details? selectedLead.details.comment : ""}
                  listingType={eventTypeSwitcher(selectedLead.listingType)}
                  matchingListings={matchingListings}
                  shortListedListings={shortListedListings}
                  BAUListings={BAUListings}
                  subListings={subListings}
                  manualListings={manualListings}
                  addToShortListed={addToShortListed}
                  listingID={listingID}
                  hideOfferButtons
                  leadHits={fetchedHits}

                   />
                }
                {loading && <BlockLoader/>}

            </div>
          </div>
        </div>

        (<Transition.Root show={showAssignmentModal} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setshowAssignmentModal}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block min-h-[500px] align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="bg-white flex flex-col justify-center items-center mt-3 mb-8 ">
                    <p className='text-lg font-medium'>Assign Lead To</p>
                    <Select
                        className='w-2/3 mt-2 mb-5 z-50 '
                        blurInputOnSelect
                        defaultValue={selectedAdmin}
                        onChange={setselectedAdmin}
                        options={admins}
                        isClearable = {true}
                        /> 
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                      onClick={() => { assignLead(); }}
                    >
                      Assign Lead
                    </button>
                  </div>
                  <div className="mt-3 w-full flex justify-end">
                    <button
                      type="button"
                      className="mt-3 w-fit inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                      onClick={() => { setshowAssignmentModal(false);}}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>)



        <Transition.Root show={showHitModal} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-hidden" onClose={setshowHitModal}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">New Hit</Dialog.Title>
                          <p className="text-sm text-gray-500">
                            Get started by filling in the information below to create your new Hit.
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => setshowHitModal(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      {/* Project name */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Change State To
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <ListBoxCustomized listToMap={possibleCrmStatus}
                          selectedValue={hitNewStatus}
                          changeHandler={sethitNewStatus}

                           />
                        </div>
                      </div>
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Communication Channel
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <ListBoxCustomized selectedValue={hitCommChannel}
                           listToMap={possibleCommunications}
                           changeHandler={sethitCommChannel}
                            />
                        </div>
                      </div>

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Target Phone Number (if changed from lead number)
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <EditText
                          className="border text-sm border-gray-300 rounded-md"
                          placeholder="If a different phone number is used (Optional)"
                          defaultValue={selectedLead? selectedLead.phoneNumber : ""}
                          onSave={(e)=>{sethitTargetPhone(e.value)}}
                          />
                        </div>
                      </div>

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Your Phone Number 
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <ListBoxCustomized selectedValue={hitSrcPhone} changeHandler={sethitSrcPhone} listToMap={possiblePhoneNumbers}
                          />
                        </div>
                      </div>

                      {/* Project description */}
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="project-description"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Comments
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <EditTextarea
                            id="project-description"
                            name="project-description"
                            rows={3}
                            onSave={(e)=>{sethitComment(e.value)}}
                            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                            placeholder="All your needed comments and insights about this hit"
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    <div className="space-x-3 flex justify-end">
                      <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setshowHitModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={(e)=>{createHit(); setshowHitModal(false)}}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>
  );
};
export default ManageQualifiedLeads;
