import { SearchIcon, FilterIcon, PlusIcon, RefreshIcon } from "@heroicons/react/solid";
import VenuesStackedList from "components/VenuesStackedList";
import { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../globals/index";
import BlockLoader from "components/BlockLoader";
import { PlusCircleIcon } from "@heroicons/react/outline";
import Button from "shared/Button/Button";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XIcon } from "@heroicons/react/solid";
import { useSnackbar } from 'snackfy';
import { toast } from "react-toastify";
import { toastOptions } from "globals/publicData";
import SmallLoader from "components/SmallLoader";
import useAuthStore from "globals/authStore";
import { refreshUser } from "globals/publicData";
import { useLocation } from 'react-router-dom';

export default function SecondaySideBar({ setSecondBarOpen }) {
  const [venues, setvenues] = useState([]);
  const [loading, setloading] = useState(false);
  const [filterText, setFilterText] = useState("");
  var location = useLocation();
  location = location.pathname
  const loggedUser = useAuthStore(state=>state.user)
  const [filteredVenues, setfilteredVenues] = useState([]);
  const [openAddModal, setopenAddModal] = useState(false);
  const [locationNames, setlocationNames] = useState([]);
  const [venueName, setvenueName] = useState("");
  const [venueTitle, setvenueTitle] = useState("");
  const [venuePhoneNumber, setvenuePhoneNumber] = useState("");
  const [venueDesc, setvenueDesc] = useState("");
  const [venuePrice, setvenuePrice] = useState("");
  const [venueWeekend, setvenueWeekend] = useState("");
  const [venueDiscount, setvenueDiscount] = useState(0);
  const [venueRate, setvenueRate] = useState("");
  const [createdVenue, setcreatedVenue] = useState("");
  const {enqueueSnackbar} = useSnackbar()
  const [listingTypes, setlistingTypes] = useState([]);
  const [selectedListingTypes, setselectedListingTypes] = useState([]);
  const [runningDiagnostics, setrunningDiagnostics] = useState(false);
  const user = useAuthStore(state=>state.user);
  const setUser = useAuthStore(state=>state.setUser)

  const people = [
    { id: 1, name: 'Annette Black' },
    { id: 2, name: 'Cody Fisher' },
    { id: 3, name: 'Courtney Henry' },
    { id: 4, name: 'Kathryn Murphy' },
    { id: 5, name: 'Theresa Webb' },
  ]
  let snackbarOptions = {
    message : "Listing Updated Successfully",
    options : {
      customStyle :{ info : {'background-color' : "#957bb8"},},
       
        variant : 'info'
    },
  }

  const loadVenues = async ()=>{
    setloading(true);

    const res = await axios.post(`${url}/listing/read`, {user : user});
    // setListings(res.data)
    //before reading the listings, I need to update the user data from our database



    let listings = res.data.filter(async (listing, i) => {
      return true;
    });

    listings = listings.sort((a, b) => (a.id > b.id) ? 1 : -1)


    setvenues(listings);
    console.log(listings);
    setloading(false);
    console.log(venues);
  }

  useEffect(()=>loadVenues(), []);
  useEffect(()=>loadVenues(), [createdVenue]);

  useEffect(() => {
    const filteredItems = venues.filter((venue) => {
      return (venue.id + "_" + venue.name)
        .toLowerCase()
        .includes(filterText.toLowerCase());
    });
    setfilteredVenues(filteredItems);
  }, [venues, filterText]);







  const getVenueTypes = async ()=>{
    const res = await axios.post(`${url}/listingType/read`);
    setlistingTypes(res.data);


  }

  const handleListingTypeCheckbox = (e , type)=>{
    console.log(e.target.checked);
    let arrayOfTypeNames = [];

    if(e.target.checked){
      if(selectedListingTypes.includes(type.id)){
        //do nothing
      }
      else{
        arrayOfTypeNames.push(...selectedListingTypes);
        arrayOfTypeNames.push(type.id)
        setselectedListingTypes(arrayOfTypeNames);
      }
    }
    else{
      if(selectedListingTypes.includes(type.id)){
        
        arrayOfTypeNames = selectedListingTypes.filter((typename)=>{
          return typename !== type.id
        })

        setselectedListingTypes(arrayOfTypeNames);
      }
    }

    console.log(selectedListingTypes);

    
  }

  useEffect(()=>{
    getVenueTypes();
  } , [])




  const createVenue = async () => {
    let vObj = {};
    vObj.name = venueName;
    vObj.phoneNumber = venuePhoneNumber;
    vObj.title = venueTitle;
    vObj.desc = venueDesc;
    vObj.price = venuePrice;
    vObj.discountPercent = venueDiscount;
    vObj.rate = venueRate;
    vObj.listingType = selectedListingTypes;
    vObj.rates = [{"week":venuePrice , "weekend" : venueWeekend}];
    vObj.user = user

    console.log(vObj);

      const res = await axios.post(`${url}/listing/create`, vObj);
      
        setcreatedVenue(res.data);
        enqueueSnackbar({...snackbarOptions, message:"Venue Created with ID " + res.data.id })

        setvenueDesc("");
        setvenueDiscount(0);
        setvenueName("");
        setvenuePhoneNumber("");
        setvenuePrice("");
        setvenueRate("");
        setselectedListingTypes([]);

  
  
      
   


  };

  const runDiagnostics = ()=>{

    setrunningDiagnostics(true)
     axios.post(`${url}/listing/missingFromFilter`).then((res)=>{
      const venuesToBeChecked = res.data;
      const actualVenues = venues.filter((venue)=>{
        return(venuesToBeChecked.includes(venue.id))
      })
      setvenues(actualVenues)
      toast.success("fetched venues that did not appear on the website. After you're done editing, please refresh the page.")

      setrunningDiagnostics(false)

     })

  }

  return (
    <div>
      <div className="px-6 pt-6 pb-4">
        <h2 className="text-lg font-medium text-gray-900">Directory</h2>
        <p className="mt-1 text-sm text-gray-600">
          Search directory of {venues.length} Venues
        </p>
        <form className="mt-6 flex space-x-4" action="#">
          <div className="flex-1 min-w-0">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search"
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <button
            type="button"
            className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <FilterIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="sr-only">Search</span>
          </button> */}
          {user.rank=="Admin" && <button
            type="button"
            className="inline-flex justify-center px-3.5 py-2 
            border border-gray-300 shadow-sm text-sm font-medium
             rounded-md text-gray-700 bg-white hover:bg-gray-50
              focus:outline-none focus:ring-2 focus:ring-offset-2 
              focus:ring-primary-500"
              onClick={(e)=>{runDiagnostics()}}
              
          >
              {!runningDiagnostics && <RefreshIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}        
              {runningDiagnostics &&<SmallLoader />}
            <span className="sr-only">Refresh</span>
          </button>}
          <button
            type="button"
            onClick={() => {setopenAddModal(true);
              axios.post(`${url}/activity/update` ,{user : loggedUser.id,action:'Click',details:'Add New Venu',url:location }).then((res)=>{})}}
            className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="sr-only">New</span>
          </button>
        </form>
      </div>
      {/* Directory list */}
      <nav className="flex-1 min-h-0  max-h-[85vh]  shadow-md  overflow-y-scroll" aria-label="Directory">
        {!loading && (
          <VenuesStackedList
            setSecondBarOpen={setSecondBarOpen}
            filteredVenues={filteredVenues}
          />
        )}
        {loading && <BlockLoader />}
      </nav>

      <Transition.Root show={openAddModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-hidden"
          onClose={setopenAddModal}
        >
          <div className="absolute inset-0 z-20 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 z-20 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen z-20 max-w-2xl">
                  <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="px-4 py-6 bg-gray-50 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              New Venue
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Get started by filling in the basic information
                              below to create your new Venue. Once the venue is
                              created, you can go into the control panel and add
                              more refined details to it.
                            </p>
                          </div>
                          <div className="h-7 flex items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setopenAddModal(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        {/* venue name */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                              Venue name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              onChange={(e) => {setvenueName(e.target.value)}}
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* venue title */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Venue title
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => {setvenueTitle(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* venue desc */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Venue Description
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              onChange={(e) => {setvenueDesc(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                              rows={3}
                            />
                          </div>
                        </div>

                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Owner's Phone Number
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              onChange={(e) => {setvenuePhoneNumber(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        {/* venue price */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Price
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                            placeholder="Ex: 18000"
                              onChange={(e) => {setvenuePrice(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>




                        {/* venue weekend Rate */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Weekend Price
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                            placeholder="Ex: 18000"
                              onChange={(e) => {setvenueWeekend(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        {/* venue discount Rate */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Discount Rate
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                            placeholder="Ex: 2.5  The value inputted is calculated as a percentage."
                              onChange={(e) => {setvenueDiscount(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>


                        {/* venue Rate */}
                        <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Rate /
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                            placeholder="Ex: day"
                              onChange={(e) => {setvenueRate(e.target.value)}}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>


                        <fieldset className="px-8">
                          <legend className="text-lg font-medium text-gray-900">Venue Type(s)</legend>
                          <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                            {listingTypes.map((type, typeIndex) => (
                              <div key={typeIndex} className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                  <label htmlFor={`type-${type.id}`} className="font-medium text-gray-700 select-none">
                                    {type.name}
                                  </label>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                  <input
                                    id={`type-${type.id}`}
                                    name={`type-${type.id}`}
                                    type="checkbox"
                                    onChange={(e)=>{handleListingTypeCheckbox(e, type)}}
                                    className="focus:ring-primary-500 h-4 w-4 text-primary-700 border-gray-300 rounded"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          </fieldset>


                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                      <div className="space-x-3 flex justify-end">
                        <button
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          onClick={() => setopenAddModal(false)}
                        >
                          Cancel
                        </button>
                        <Button
                          type="button"
                          sizeClass=""
                          onClick={() => {
                            createVenue();
                            setopenAddModal(false);
                          }}
                          className="inline-flex justify-center disabled:bg-slate-200 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                          Create
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>





    </div>
  );
}
