import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import RegistrationDT from "./RegistrationDT";
import DynamicDT from "components/DataTablesComponents/DynamicDT";
import {data,columns} from "../../components/DataTablesComponents/data"
import ButtonClose from "shared/ButtonClose/ButtonClose";
import axios from "axios";
import { url } from "../../globals";
import useAuthStore from "globals/authStore";
import moment from "moment/moment";
import { EventTypeSwitcher } from "globals/publicData";
import LeadInfo from "./LeadInfo";
import { EyeIcon, PencilIcon } from "@heroicons/react/outline";
import useCPStore from "globals/controlPanelStore";
import BlockLoader from "components/BlockLoader";
import useAppStore from "globals/appStore";
import { toast } from "react-toastify";
import Badge from "shared/Badge/Badge";
const ManageQualifiedLeadsOwners = () => {
  const [showingInfo, setshowingInfo] = useState(false)
  const cpUser = useAuthStore(state=>state.user);
  const [fetchedLeads, setfetchedLeads] = useState([]);
  const selectedLead = useCPStore(state=>state.selectedLead);
  const setSelectedLead = useCPStore(state=>state.setSelectedLead);
  const [loading, setloading] = useState(false);
  let allTypes = useAppStore(state=>state.allTypes);
  const [listingID, setlistingID] = useState(null)

  const eventTypeSwitcher = (typeID)=>{
    for (let i = 0; i < allTypes.length; i++) {
     const type = allTypes[i];
     if(type.id == typeID){
       return {
         name : type.title,
         icon : type.icon
       }
     }
     
    }
 }

  const cols = [
    {
      name: "ID",
      selector: "leadID",
      sortable: true,
      grow : 2
    },
    {
      name: "Name",
      selector :"lead.name",
      sortable: true,
      grow : 2,

    },
    // {
    //   name: "Phone",
    //   selector: "lead.phoneNumber",
    //   sortable: true
    // },
    {
      name: "Event Type",
      selector: "lead.listingType",
      sortable: true,
      cell : row=>{
        return(<span> { eventTypeSwitcher(row.lead.listingType).name }</span>)
      }
    },
    {
      name: "Assignment Date",
      selector: "tDate",
      sortable: true,
      cell : row=>{
        return(<span> {moment(row.tDate).format('YYYY - MM - DD')}</span>)
      }
    },
    {
        name: "listing #",
        selector: "listingID",
        sortable: true
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell : row=>{
          return(<Badge color={row.status=="Successful"? "green" : (row.status=="Missed" ? "red" : "purple")} className="text-xs truncate" name={row.status}>  </Badge>)
        }
      },
  ]

  const selectRow = (row)=>{
    setSelectedLead(row)
  }

  const viewDetails = ()=>{
    if(selectRow){
      setshowingInfo(true);

    }
  }

  const setSelections = (e)=>{
    setSelectedLead(e.selectedRows[0].lead)
     setlistingID(e.selectedRows[0].listingID)
  }








  const buttons =
  <div className="flex">
  <button onClick={(e)=>{viewDetails()}} className="flex rounded-md bg-primary-100 shadow-md my-1 mx-1 border-2 px-1 py-1 text-sm text-white  border-gray-100">
    Details
    <PencilIcon className="w-5 h-5"/>
  </button>

</div>

  const fetchLeads = ()=>{
    setloading(true)
    axios.post(`${url}/leads/getLeadsForCPOwners` , {userID : cpUser.id}).then((res)=>{
      console.log(res);
      let sortedData = res.data.sort((a,b) => moment(a.tDate).isAfter(moment(b.tDate))); // b - a for reverse sort
      setfetchedLeads(sortedData);
      setloading(false)
      
    })
  }
  useEffect(()=>{
    fetchLeads();
  },[])


    //The action buttons:-

  return (
    <div>
        <div className="space-y-6 space-x-6 sm:space-y-8">
          {/* HEADING */}
          <div className="">
            <br />
            <div className="">
                <DynamicDT
                  className={showingInfo && " hidden"}
                  title="Qualified Leads"
                  actionButtons={buttons}
                  defaultSortColumn={4}

                  onRowSelect={(e)=>{e.selectedRows[0] && setSelections(e)}}
                data={fetchedLeads} columns={cols} />
                {showingInfo &&  <div className="flex justify-end w-full text-primary-100 hover:text-primary-700 transition-colors">
                  <span className=" text-sm underline mt-auto mb-auto cursor-pointer" onClick={()=>{setshowingInfo(false);setloading(false)}}> Close View </span>
                <ButtonClose onClick={(e)=>{setshowingInfo(false); setloading(false)}}  />

                </div>}
                {
                  showingInfo && !loading &&  <LeadInfo
                  leadID={selectedLead.id}
                  leadName={selectedLead.name}
                  leadPhone = {selectedLead.phoneNumber}
                  leadDetails = {selectedLead.details && selectedLead.details}
                  comment={selectedLead.details? selectedLead.details.comment : ""}
                  listingType={eventTypeSwitcher(selectedLead.listingType)}
                  listingID={listingID}

                   />
                }
                {loading && <BlockLoader/>}

            </div>
          </div>
        </div>
    </div>
  );
};
export default ManageQualifiedLeadsOwners;
