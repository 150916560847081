import { useState, useEffect } from "react";

const useChangeIcon = (iconURL)=>{
    useEffect(()=>{
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = iconURL;
        return true;
        
    },[])
}

export default useChangeIcon