import React, { useEffect } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import GallerySliderWLink from "components/GallerySlider/GalerySliderWLink";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import { url } from "../../globals";
import Animate from "react-smooth/lib/Animate";
import { animationSteps } from "globals/publicData";
import packagePNG from '../../images/packageIcon.png'
import addonIcon from "../../images/addonIcon.png"
import BlockLoader from "components/BlockLoader";
import { BookmarkAltIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { BadgeCheckIcon, BookmarkIcon, CheckIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import Joyride from 'react-joyride';
import useAppStore from "globals/appStore";

// const DEMO_DATA = DEMO_STAY_LISTINGS[0];
const StayCard = ({
  size = "default",
  className = "",
  data,
  leadID,
  ratioClass,
}) => {
  const {
    gallerImgs,
    address,
    addons,
    packages,
    location,
    subLocation,
    title,
    capacity,
    discountPercent,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    rate,
    addonsText,
    gallery
  } = data;

  const steps= [
    {
      target: '.nc-StayCard',
      content: 'You have to pin your favourite Venues in order to get offers from the owners regarding your request.',
    },
    {
      target: '.bookMark',
      content: 'In order to pin a venue, press on the icon. This sends a notification to the owner to get back to you.',
    },

  ]
  const [finalGallery, setfinalGallery] = useState([])
  const [shortlisted, setShortlisted] = useState(false);
  const [like, setLike] = useState(null);
  const favs = useAppStore(state => state.favs)
  const setPhoneNumberModal = useAppStore(state => state.setPhoneNumberModal)

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const checkText = () => {
    if(addonsText && (addonsText.toLowerCase().includes("space only") || addonsText.toLowerCase().includes("only space") ) )
    {
      return true
    }
    else {
      return false
    }
  }
  const likeButton = () => {
    let phoneNumber = localStorage.getItem("phoneNumber")
    let browserUser = localStorage.getItem("browserUser")
    if(!browserUser){
      browserUser = uuidv4() + makeid(4);
      localStorage.setItem("browserUser" , browserUser); 
    }
    if(phoneNumber)
    {
      if(!like)
      {
        axios.post(`${url}/favourites/add` , {phoneNumber:phoneNumber,browserUser:browserUser,listingID:id}).then((res)=>
        {
        }).catch((err)=>{
          console.log(err)
        })
      }
      if(like){
        axios.post(`${url}/favourites/remove` , {phoneNumber:phoneNumber,listingID:id}).then((res)=>
        {
        }).catch((err)=>{
          console.log(err)
        })
      }
 
    }
  }
  useEffect(async () => {
    // const metaData = JSON.parse(localStorage.getItem("cacheMetaData"));

    // let currTime = moment(Date.now())
    // let metaDataTime = moment(metaData.tDate).add(12, 'hours');
    // //console.log("metaData time")
    // //console.log(metaData.tDate)
    // let shouldFetchFromS3 = false;
    // if (metaDataTime.isBefore(currTime)) {
    //   //should cache again. So display the photos directly from S3
    //   shouldFetchFromS3 = true
    //   console.log("shouldFetchFromS3")

    // }

    // if (gallerImgs.length <= 0 || shouldFetchFromS3) {
    //   axios.get(`https://076irtsz81.execute-api.eu-west-3.amazonaws.com/dev/backend/presigned?request_type=download`
    //     , { headers: { venues: id + '_' } }).then((res2) => {
    //       setfinalGallery(res2.data)
    //     })

    // }
    // else {
    //   setfinalGallery(gallerImgs);
    // }

    setfinalGallery(gallery)



  }, [])

  const addtoLead = () => {
    axios.post(`${url}/leads/addToShortListed`, { id: leadID,listingID:id}).then((res) => {
      setShortlisted(true);
    })

  }
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
    <GallerySlider
          uniqueID={`stay-v-${id + moment() + makeid(5)}`}
          ratioClass={ratioClass}
          galleryImgs={finalGallery.filter((_, i) => { return i < 5 })}
          id={id}
        />
        {leadID ? (!shortlisted && <BookmarkIcon id="bookMark"  onClick={()=>{addtoLead(); toast.success("Listing successfully pinned! You will recieve offers from the owner shortly.")}} className="absolute bookMark right-3 top-3 w-7 h-7 flex items-center justify-center rounded-full cursor-pointer text-white bg-black bg-opacity-30 hover:bg-opacity-50"/>) :<BtnLikeIcon isLiked={like} setLike={setLike} openModal={setPhoneNumberModal} onClick={likeButton} className="absolute right-3 top-3" />}
        {discountPercent !== 0 && <SaleOffBadge desc={`${discountPercent}%`} className="absolute left-3 top-3" />}
      </div>
    );
  };
  useEffect(() => {
    for (let index = 0; index < favs.length; index++) {
      if(favs[index] === id)
      {
        setLike(true);
      }
    }
  }, [favs]);
  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4 block " : "p-3 space-y-2 block "}>
        <div className="space-y-2 flex-col flex  ">
          <div className="mb-auto space-y-2 md:min-h-[150px]">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {capacity} Guests
          </span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="line-clamp-1">{location} . {subLocation}</span>
          </div>
          {packages !== 0 && <div className="flex  justify-between">
            
              <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                {size === "default" && (
                  <svg className="w-5 fill-current" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="M224,177.3V78.7a8.1,8.1,0,0,0-4.1-7l-88-49.5a7.8,7.8,0,0,0-7.8,0l-88,49.5a8.1,8.1,0,0,0-4.1,7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,7.8,0l88-49.5A8.1,8.1,0,0,0,224,177.3Z" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline fill="none" points="177 152.5 177 100.5 80 47" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline fill="none" points="222.9 74.6 128.9 128 33.1 74.6" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="128.9" x2="128" y1="128" y2="234.8" /></svg>)}
                <span className="">{packages} Packages </span>
              </div>
            
         
          </div>}

          {addons !== 0 && <div className="flex  justify-between">
       
              <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                {size === "default" && (
                  <svg className="w-5 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M 16 4 L 15.625 4.15625 L 9.625 6.5625 L 9 6.8125 L 9 13.53125 L 3.59375 15.875 L 3 16.15625 L 3 24.21875 L 3.5 24.53125 L 9.5 27.875 L 9.96875 28.125 L 10.4375 27.90625 L 16 25.125 L 21.5625 27.90625 L 22.03125 28.125 L 22.5 27.875 L 28.5 24.53125 L 29 24.21875 L 29 16.15625 L 28.40625 15.875 L 23 13.53125 L 23 6.8125 L 22.375 6.5625 L 16.375 4.15625 Z M 16 6.1875 L 19.28125 7.46875 L 16 8.75 L 12.71875 7.46875 Z M 11 8.9375 L 15 10.46875 L 15 15.34375 L 11 13.5625 Z M 21 8.9375 L 21 13.5625 L 17 15.34375 L 17 10.46875 Z M 10 15.3125 L 13.625 16.90625 L 10 18.6875 L 6.375 16.875 Z M 22 15.3125 L 25.625 16.875 L 22 18.6875 L 18.375 16.90625 L 19.5 16.40625 Z M 5 18.40625 L 9 20.40625 L 9 25.3125 L 5 23.0625 Z M 27 18.40625 L 27 23.0625 L 23 25.3125 L 23 20.40625 Z M 15 18.46875 L 15 23.375 L 11 25.375 L 11 20.40625 Z M 17 18.46875 L 21 20.40625 L 21 25.375 L 17 23.375 Z" /></svg>
                )}
                <span className="">{addons} Addons </span>
              </div>
            
         
          </div>}
          <div className="flex  justify-between">
        {(addons === 0 && packages === 0 && checkText()) &&

              <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                {size === "default" && (
<svg xmlns="http://www.w3.org/2000/svg" className="w-5 fill-current" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
</svg>
                )}
                <span className=""> Space Only </span>
              </div>
            }
         
          </div>
          </div>


        
        <div className="mt-auto">
        <div className="w-14 mb-2 border-b  border-neutral-100 dark:border-neutral-800"></div>

        <div className="flex   justify-between items-center">
          {discountPercent === 0 &&
            <span className="text-base font-semibold">
              {price}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  /{rate}
                </span>
              )}
            </span>
          }
          {discountPercent !== 0 &&

            <div>
              <strike>
                <span className="text-base font-semibold">
                  {price}
                  {` `}
                  {size === "default" && (
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                      /{rate}
                    </span>
                  )}
                </span>
              </strike>

              <span className="text-base font-semibold ml-1">
                {price - parseInt(price * discountPercent / 100)}
                {` `}
                {size === "default" && (
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                    /{rate}
                  </span>
                )}
              </span>
            </div>



          }

          {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )}
        </div>
        </div>
      
        </div>

      </div>
    );
  };

  return (
    <Animate steps={animationSteps}>


      <div
        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
        data-nc-id="StayCard"
      >
                      {leadID && <Joyride
                      scrollOffset={300}
                      steps={steps}
          
        />}
        {renderSliderGallery()}
        <Link target={"_blank"} to={`/listing-stay-detail?id=${id}`}>
          {renderContent()}
        </Link>
      </div>
    </Animate>

  );
};
export default StayCard;
