import { ChevronLeftIcon } from '@heroicons/react/outline'
import React from 'react'
import {
    BellIcon,
    BookmarkAltIcon,
    CashIcon,
    CogIcon,
    FireIcon,
    HomeIcon,
    InboxIcon,
    KeyIcon,
    MenuIcon,
    PhotographIcon,
    SearchCircleIcon,
    UserIcon,
    ViewGridAddIcon,
    XIcon,
  } from '@heroicons/react/outline'
import { useState } from 'react'
export default function ReportsSidebar({children}) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
      const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const subNavigation = [
        {
          name: 'Bookings Report',
          description: 'Monthly and daily reporting for our current booking status',
          href: '#',
          icon: CashIcon,
          current: true,
        },
        // {
        //   name: 'Notifications',
        //   description: 'Enim, nullam mi vel et libero urna lectus enim. Et sed in maecenas tellus.',
        //   href: '#',
        //   icon: BellIcon,
        //   current: false,
        // },
        // {
        //   name: 'Security',
        //   description: 'Semper accumsan massa vel volutpat massa. Non turpis ut nulla aliquet turpis.',
        //   href: '#',
        //   icon: KeyIcon,
        //   current: false,
        // },
        // {
        //   name: 'Appearance',
        //   description: 'Magna nulla id sed ornare ipsum eget. Massa eget porttitor suscipit consequat.',
        //   href: '#',
        //   icon: PhotographIcon,
        //   current: false,
        // },
        // {
        //   name: 'Billing',
        //   description: 'Orci aliquam arcu egestas turpis cursus. Lectus faucibus netus dui auctor mauris.',
        //   href: '#',
        //   icon: CashIcon,
        //   current: false,
        // },
        // {
        //   name: 'Integrations',
        //   description: 'Nisi, elit volutpat odio urna quis arcu faucibus dui. Mauris adipiscing pellentesque.',
        //   href: '#',
        //   icon: ViewGridAddIcon,
        //   current: false,
        // },
        // {
        //   name: 'Additional Resources',
        //   description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
        //   href: '#',
        //   icon: SearchCircleIcon,
        //   current: false,
        // },
      ]
  return (
    <div className="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
    {/* Breadcrumb */}
    <nav aria-label="Breadcrumb" className="bg-white border-b border-slate-200 xl:hidden">
      <div className="max-w-3xl mx-auto py-3 px-4 flex items-start sm:px-6 lg:px-8">
        <a
          href="#"
          className="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-slate-900"
        >
          <ChevronLeftIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
          <span>Settings</span>
        </a>
      </div>
    </nav>

    <div className="flex-1 flex xl:overflow-hidden">
      {/* Secondary sidebar */}
      <nav
        aria-label="Sections"
        className="hidden flex-shrink-0 w-96 bg-white border-r border-slate-200 xl:flex xl:flex-col"
      >
        <div className="flex-shrink-0 h-16 px-6 border-b border-slate-200 flex items-center">
          <p className="text-lg font-medium text-slate-900">Settings</p>
        </div>
        <div className="flex-1 min-h-0 overflow-y-auto">
          {subNavigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current ? 'bg-blue-50 bg-opacity-50' : 'hover:bg-blue-50 hover:bg-opacity-50',
                'flex p-6 border-b border-slate-200'
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-slate-400" aria-hidden="true" />
              <div className="ml-3 text-sm">
                <p className="font-medium text-slate-900">{item.name}</p>
                <p className="mt-1 text-slate-500">{item.description}</p>
              </div>
            </a>
          ))}
        </div>
      </nav>

      {/* Main content */}
      <div className="flex-1 bg-slate-50 xl:overflow-y-auto">
        <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-2">

          {children}
        </div>
      </div>
    </div>

  </div>
  )
}
