const EntityBGSection = ({imageLink})=>{
    return(
<header>
    <div className="w-full bg-center bg-cover h-[32rem]" style={{"background-image": `url(${imageLink})`,}}>
        <div className="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
            <div className="text-center">
                <h1 className="text-2xl font-semibold text-white uppercase lg:text-3xl">Welcome to  <span className="text-gray-400 ">Skas</span></h1>
                {/* <button className="w-full px-4 py-2 mt-4 text-sm font-medium text-white uppercase transition-colors duration-300 transform bg-primary-200 rounded-md lg:w-auto hover:bg-blue-700 focus:outline-none focus:bg-primary-200">Take a tour</button> */}
            </div>
        </div>
    </div>
</header>
    )
}

export default EntityBGSection