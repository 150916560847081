import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import BzTeamContactsDT from "./BzTeamContactsDT";
const ManageBzTeamContacts = () => {
  return (
    <div>
        <div className="space-y-6 space-x-6 sm:space-y-8">
          {/* HEADING */}
          <div className="">
            <br />
            <div className="dt-wrapper">
                <BzTeamContactsDT/>
            </div>
          </div>
        </div>
    </div>
  );
};
export default ManageBzTeamContacts;
