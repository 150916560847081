import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
export default function SubFeaturedListing({brandPrimaryBG, brandSecondaryBG , CTAText , listing}) {
  return (
    <section aria-labelledby="cause-heading">
    <div className="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={listing? listing.gallery[0] : "https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg"}
          alt=""
          className="w-full h-full object-center object-cover"
        />
      </div>
      <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-50" />
      <div className="relative max-w-3xl mx-auto flex flex-col items-center text-center">
      <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInRight">

        <h2 id="cause-heading" className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          {listing ? listing.title  : ""}
        </h2>
        </AnimationOnScroll>

        <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInLeft">

        <p className="mt-3 text-xl text-white">
          {listing? listing.desc : ""}
        </p>
        <p className="mt-3 text-2xl text-white">
          {listing? listing.price + " EGP/ " + listing.rate : ""}
        </p>
        </AnimationOnScroll>


        <AnimationOnScroll  initiallyVisible={false} animateOnce={false} animatePreScroll={true} duration={1}  animateIn="animate__fadeInDown">

        <a
          href={`https://www.venu.ai/listing-stay-detail?id=${listing.id}`} target={"_blank"}
          className={`mt-8 w-full block ${brandPrimaryBG} border border-transparent rounded-md py-3 px-8 text-base font-medium text-gray-900 hover:${brandSecondaryBG} sm:w-auto`}
        >
          {CTAText? CTAText : "Call to action"}
        </a>
        </AnimationOnScroll>

      </div>
    </div>
  </section>
    )
}
