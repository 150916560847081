/* eslint-disable jsx-a11y/anchor-is-valid */
import DataTable from 'react-data-table-component';
import { useState, useEffect, useMemo } from 'react';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

import { BanIcon, CheckIcon, DotsHorizontalIcon, LinkIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { url } from '../../globals';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import {

} from "@heroicons/react/outline";

import TeamContactsTableItems from 'components/TableItems/TeamContactsTableItems';
import Input from 'shared/Input/Input';
import moment from 'moment';
import { useSnackbar } from 'snackfy';
import { TrashIcon, UserAddIcon } from '@heroicons/react/solid';
import { toast } from "react-toastify"
import { toastOptions } from 'globals/publicData';
import BlockLoader from 'components/BlockLoader';
import SortRevs from 'components/SortByDropDown/SortRevs';
import Pagination from 'components/Pagination/Pagination';

import avatarIcon from "../../images/icons/avatar.svg";
import bookingIcon from "../../images/icons/booking.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import venueIcon from "../../images/icons/venue.svg";
import eventIcon from "../../images/icons/ballon.svg";
import commentIcon from "../../images/icons/comment.svg";
import moneyIcon from "../../images/icons/money.svg";
import ballonIcon from "../../images/icons/ballon.svg";
import gpsIcon from "../../images/icons/gps.svg";
import contactIDIcon from "../../images/icons/contactID.svg";
import useAuthStore from 'globals/authStore';
import { v4 as uuidv4 } from 'uuid';


export default function BzTeamContactsDT({ open, setOpen, setcurrentReservation }) {
  const [allReservations, setallReservations] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const loggedUser = useAuthStore(state=>state.user)

  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [types, setTypes] = useState()
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const sortArray = (type, array) => {
    const typess = {
      Approval: 'approved',
      Date: 'tDate',
      Name: 'name',
      ContactID: 'id',
      PhoneNumber: 'phoneNumber',
      HighestPrice: 'priceRangeHigh',
      LowestPrice: 'priceRangeLow',
      EventType: 'listingType',
    };
    let sorted
    const sortProperty = typess[type];
    if (sortProperty === 'name') {
      sorted = [...array].sort((a, b) => a.name.localeCompare(b.name));
      return (sorted);
    }
    if (sortProperty === 'listingType') {
      sorted = [...array].sort((a, b) => a.listingType.localeCompare(b.listingType));
      return (sorted);
    }
    else if (sortProperty === 'id') {
      sorted = [...array].sort((a, b) => a[sortProperty] - b[sortProperty]);
      return (sorted);
    }
    else if (sortProperty === 'priceRangeLow') {
      sorted = [...array].sort((a, b) => a[sortProperty] - b[sortProperty]);
      return (sorted);
    }
    else if (sortProperty === 'priceRangeHigh') {
      sorted = [...array].sort((a, b) => a[sortProperty] - b[sortProperty]);
      return (sorted);
    }
    else if(sortProperty === 'tDate'){
      sorted = [...array].sort(function (a, b) { return new Date(a.tDate) - new Date(b.tDate) });
      return (sorted);
    }
    else{
      sorted = array.reverse()
      return (sorted);
    }



  };

  const filteredItems = sortArray(types,allReservations.filter(
    item => item.id && (item.id + "").toLowerCase().includes(filterText.toLowerCase()),
  ));

  const typesChangeHandler = (selectedType) => {
    setTypes(selectedType);
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Input placeHolder={"Search for ID"} className={"md:w-6/12 shadow-[0px_1px_4px] shadow-primary-50"} onChange={e =>{ setCurrentPage(1); setFilterText(e.target.value)}} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  const getAllBzContacts = async () => {
    const res = await axios.post(`${url}/contact/readBzContacts`)
    console.log(res);
    console.log(res.data);
    setallReservations(res.data)
    enqueueSnackbar({
      message: "Fetched latest database update "
    })
    setLoading(false);


  }
  useEffect(getAllBzContacts, [])



  const openVenueLink = (p) => {
    console.log(p.id)
    let url = "/listing-stay-detail?id=" + p.venuID
    window.open(url, '_blank')
  }
  const deleteBzContact = async (p) => {
    console.log(p.id)
    axios
      .post(`${url}/contact/deleteBzContact`, {

        id: p.id,
      })
      .then((res3) => {
        toast.success("Deleted successfully", toastOptions)

        getAllBzContacts()
      });

  }





  const options = [




    {
      name: "Delete Message",
      onClick: deleteBzContact,
      icon: TrashIcon,
    },

  ];

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredItems.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const createbuttons = (p) => {

    return (
      <div className=" ">
        <Popover className="relative ">
          {({ open, close }) => (
            <>
              <Popover.Button
                ref={setReferenceElement}
                className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                {/* <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" /> */}
                <DotsHorizontalIcon className="h-6 w-6 inline-flex mr-1
               items-center p-1 border border-transparent border-primary-50 bg-primary-400 rounded-full shadow-sm
                text-white  hover:bg-primary-500 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-primary-700"/>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel ref={setPopperElement}
                  className="absolute right-full  z-50 w-screen max-w-[260px] px-4 -mt-3 mr-auto  sm:right-0 sm:px-0">
                  <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                      {options.map((item, index) => (
                        <a
                          onClick={() => {
                            item.onClick(p);
                            close()
                          }}
                          key={index}
                          className="flex cursor-pointer items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <item.icon aria-hidden="true" className="w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">{item.name}</p>
                          </div>
                        </a>
                      ))}
                    </div>

                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );

  }

  const sortingOptions = [
    {
      name: "Contact ID"

    },
    {
      name: "Name"

    },
    {
      name: "Date"

    },
    {
      name: "Highest Price"

    },
    {
      name: "Lowest Price"

    },
    {
      name: "Event Type"

    },

  ]

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      grow: 2
    },
    {
      name: 'Name',
      selector: row => row.name,
      icon: avatarIcon,
      sortable: true,
      grow: 2,
      wrap: true



    },
    {
      name: 'Date',
      selector: row => moment(row.tDate).toDate().toLocaleString(),
      icon: calendarIcon,
      sortable: true,
      grow: 2
    },
    {
      name: 'Highest & Lowest Price',
      selector: row => row.priceRangeLow,
      icon: moneyIcon,
      sortable: true

    },
    {
      name: 'Event Type',
      selector: row => row.listingType,
      icon: ballonIcon,
      sortable: true,
      grow: 2,
      wrap: true


    },
    {
      name: 'Visited',
      selector: row => row.listings.toString(),
      icon: gpsIcon,
      sortable: true,
      grow: 3,
      wrap: true


    },
    {
      name: 'Comment',
      selector: row => row.message,
      icon: commentIcon,
      sortable: true,
      grow: 4,
      wrap: true

    },
    {

      cell: (row) => { return createbuttons(row) },
      selector: row => row.buttons,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      grow: 4
    },

  ];

  return (
    <>

<div className='md:w-full p-3 w-full grid grid-cols-1 gap-2 md:flex justify-center md:justify-around'>
          {subHeaderComponentMemo}

          <SortRevs
          options={sortingOptions}
          Dropdown={typesChangeHandler}

        />
        </div>
      {loading ? <BlockLoader /> :

        <TeamContactsTableItems
          data={currentPosts}
          columns={columns}
          setcurrentReservation={setcurrentReservation}
          getAllBzContacts={getAllBzContacts}

        />




        // <DataTable
        //     columns={columns}
        //     data={filteredItems}
        //     direction="auto"
        //     pagination
        //     persistTableHead
        //     responsive
        //     subHeaderAlign="right"
        //     subHeader
        //     subHeaderComponent={subHeaderComponentMemo}




        // />
        

      }
            <Pagination
        postsPerPage={postsPerPage}
        totalPosts={filteredItems.length}
        paginate={paginate}
        selected={currentPage}
      />
    </>
  );
};