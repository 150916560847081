import React, { useEffect, useRef, useState } from 'react'
import LoadingBar , {continuousStart} from 'react-top-loading-bar'
export default function LineLoader({done}) {
    const ref = useRef(null)
    const [progress, setProgress] = useState(0)


    useEffect(()=>{
        if(ref.current && !done){
            ref.current.continuousStart()
            

        }
        else{
        if(ref.current && done){
            ref.current.complete()
        }
        }
    },[ref])
  return (
    <div>
      <LoadingBar
        ref={ref}
        color='#f11946'

      />
    </div>

  )
}
