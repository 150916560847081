import React from 'react'

export default function PaymentTerms({paymentTerms = [] , checkoutPrice=0}) {
  return (
    <>
    <div className='text-base text-gray-900'> Payment Terms</div>
    
    { paymentTerms && paymentTerms.length>0 &&
    paymentTerms.map((pt , i)=>{
        return(
            <div className="flex justify-between text-sm font-medium text-gray-500 space-y-6 border-t border-gray-200 pt-6">
            <div>
              <dt className="font-medium text-gray-900">{pt.name}</dt>
              <dd className="mt-2">
                <address className="not-italic">
                  <span className="block">{pt.value} {pt.type=="Flat"? " EGP" : "%"}</span>
                  <span className="block">{pt.when} the event day </span>
                  {pt.when!=="On" && <span className="block">By {pt.days} Days </span>}
                </address>
              </dd>
            </div>
            <dd className="text-gray-900">{pt.type=="Flat"? pt.value : parseInt(pt.value)/100 * parseInt(checkoutPrice)} EGP </dd>
            </div>
        )
    })
}
</>  )
}
