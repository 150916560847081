import React from 'react'
import { EditTextarea } from 'react-edit-text'
import ButtonClose from 'shared/ButtonClose/ButtonClose'
import PaymentTerms from './PaymentTerms'

export default function Offer({leadID , listing, selectedPackage , selectedAddons , checkoutPrice, cpSide,
setselectedPackage=()=>{} , removeAddon=()=>{}, paymentTerms= [], 
normalPrice, readonly=false, leadQuestions, answers = [] , setanswers = ()=>{}, createOffer = ()=>{}, inOfferPage = true} ) {
let extraClass
  const answerQuestion = (question,answer)=>{
    let answerObject = {
      question : question.question,
      answer : answer
    }
    let tempArr = answers.filter((r)=>{return true});
    let found = false;
    for (let i = 0; i < tempArr.length; i++) {
        if(tempArr[i].question == question.question){
          tempArr[i] = answerObject
          found = true
        }
    }
    if(!found){
      tempArr.push(answerObject)
    }
    setanswers(tempArr);
  }
  if(inOfferPage){
     extraClass= "lg:grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-24"
  }
  return (
    <div className={`max-w-2xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:py-32 ${extraClass}`}>
    <div className="lg:col-start-2">
      <h1 className="text-sm font-medium text-indigo-600">Current Invoice</h1>
      <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
        Thanks for your interest
      </p>
      <p className="mt-2 text-base text-gray-500">
        We have put together a customized offer for your request, Please take a moment to look at the offer and the payment in total.
      </p>

      <dl className="mt-16 text-sm font-medium">
        <dt className="text-gray-900">Tracking number</dt>
        <dd className="mt-2 text-indigo-600">{leadID}</dd>
      </dl>

      <ul
        role="list"
        className="mt-6 text-sm font-medium text-gray-500 border-t border-gray-200 divide-y divide-gray-200"
      >
        
          <li key={8} className="flex py-6 space-x-6">
            <img
              src={listing.gallery[0]}
              alt={"Listing"}
              className="flex-none w-24 h-24 bg-gray-100 rounded-md object-center object-cover"
            />
            <div className="flex-auto space-y-1">
              <h3 className="text-gray-900">
                <span> {listing.title}</span>
              </h3>
              <p className="line-clamp-1 w-full">{listing.desc}</p>
              <p className=" text-gray-500 sm:block sm:mt-2">{listing &&listing.addonsText}</p>
              <p>{listing.subLocation} . {listing.location}</p>
            </div>
            <p className="flex-none font-medium text-gray-900">{normalPrice} EGP</p>
          </li>

          {selectedPackage!=="default" &&  <li key={9} className="flex py-6 ml-5 space-x-6">
            <ButtonClose
              className="flex-none rounded-md object-center object-cover"
              onClick={()=>{setselectedPackage("default")}}
            />
            <div className="flex-auto space-y-1">
              <h3 className="text-gray-900">
                <span> {selectedPackage.name}</span>
              </h3>
              <div className='grid md:grid-cols-3 gap-x-4 grid-cols-2'>

                    {selectedPackage.desc.map((descPoint , i)=>{
                        return(
                            <p className="mt-1 w-full text-xs" key={i}>{descPoint}</p>

                        )
                    })}

              </div>
            </div>
            <p className="flex-none font-medium text-gray-900">{selectedPackage.price} EGP </p>
          </li>}



          {selectedAddons && selectedAddons.length>0 &&
            selectedAddons.map((addon,i)=>{
                console.log(addon)
                return(
                    <li key={i} className="flex py-6 ml-5 space-x-6">
                    <ButtonClose
                      className="flex-none rounded-md object-center object-cover"
                      onClick={()=>{removeAddon(addon)}}
                    />
                    <div className="flex-auto space-y-1">
                      <h3 className="text-gray-900">
                        <span> {addon.name}</span>
                      </h3>
                      <div className='grid md:grid-cols-3 gap-x-4 grid-cols-2'>
        
                                    <p className="mt-1 w-full text-xs" key={i}>{addon.desc}</p>
                                    <p className="mt-1 w-full text-xs" key={i}>{addon.notes}</p>
        
                           
        
                      </div>
                    </div>
                    {!addon.fixedAmount && <p className="flex-none font-medium text-gray-900"> {addon.price} x {addon.amount}= {addon.calculatedPrice} EGP </p>}
                    {addon.fixedAmount && <p className="flex-none font-medium text-gray-900">{addon.price} EGP </p>}
                  </li>
                )
            })
}
     
      </ul>

      <dl className="text-sm font-medium text-gray-500 space-y-6 border-t border-gray-200 pt-6">


        <div className="flex items-center justify-between border-b pb-6 border-gray-200 text-gray-900 ">
          <dt className="text-base">Total</dt>
          <dd className="text-base">{checkoutPrice} EGP</dd>
        </div>


          <PaymentTerms paymentTerms={paymentTerms} checkoutPrice={checkoutPrice}/>


      </dl>


      <>
      <div className='w-full flex justify-center mt-5 '>
      <span className='text-2xl text-gray-800 tracking-tight font-bold mb-5'> Questions</span>
      </div>

      { leadQuestions && leadQuestions.map((question,i)=>{
        return(
          <div key={i + question.id} className='w-full flex mt-1 mb-1 justify-between'>
          <span className='text-md w-1/2 ml-1 text-gray-600 tracking-normal font-bold'> {question.question}</span>
          <span className='w-1/2 '><EditTextarea defaultValue={answers[i]? answers[i].answer : null} onSave={(e)=>{answerQuestion(question , e.value)}}  readonly={!cpSide} placeholder='Your answer to the question goes here...' /></span>
      </div>
        )
      })
}
      </>



      {cpSide && <div className="mt-16 border-t border-gray-200 py-6 text-right">
        <button onClick={(e)=>{createOffer && createOffer()}}  className="text-sm bg-primary-200 font-medium text-white px-3 py-1 rounded-md shadow-sm hover:bg-primary-700">
          Create Offer<span aria-hidden="true"> &rarr;</span>
        </button>
      </div>}
    </div>
  </div>
  )
}
